const app = {
  title: 'Guay Sales',
  version: 'Version',
  changeLanguage: 'Français',
  reloading: 'The app is reloading...',
};

const auth = {
  logout: 'Logout',
  redirectToConnectionPage: 'You will be redirected to the connection page.',
  unauthorizedLogin: 'Unauthorized Login',
  unauthorizedLoginMessage: 'You cannot access this application with this email address',
};

const logoutModal = {
  title: 'Confirmation',
  confirmationText: 'Are you sure you want to log out?',
  confirmButton: 'Disconnect',
};

const sidebar = {
  billingCodeRules: 'Billing Code Rules',
  clients: 'Clients',
  configuration: 'Configuration',
  crm: 'CRM',
  dashboard: 'Dashboard',
  workPlannings: 'Work Plannings',
  opportunities: 'Opportunities',
  quotes: 'Quotes',
  representatives: 'Representatives',
  sales: 'Sales',
  serviceCalls: 'Service Calls',
  worksites: 'Worksites',
};

const forceRefresh = {
  refresh: 'Refresh',
  version: 'Your application version is out of date, please refresh to get the latest version',
};

export const layout = {
  app,
  auth,
  logoutModal,
  sidebar,
  forceRefresh,
  notification: {
    title: 'Notifications',
    markAllRead: 'Mark All Read',
    empty: 'No notifications',
    modificationRequestedNotification: 'Modification request for service call #{{ friendlyId }}',
    quoteChangesRequestedNotification: 'The quote #{{ friendlyId }} requires a review',
    quoteApprovedNotification: 'The quote #{{ friendlyId }} is now approved and awaiting for a client response',
    quoteApprovalRequestedNotification: 'The quote #{{ friendlyId }} requires approval',
  },
};
