import { Navigate, Route } from 'react-router-dom';
import { BillingCodeRuleDetailsPage } from './billingCode/BillingCodeRuleDetailsPage';
import { TabBillingCodeRules } from './billingCode/TabBillingCodeRules';
import { EmptyLayout } from '../layout/Layouts';
import { TabAccessoryRules } from './accessory/TabAccessoryRules';
import { AccessoryRuleDetailsPage } from './accessory/AccessoryRuleDetailsPage';

export const billingCodeRulesRoutes = (
  <Route path='billing-code-rules' lazy={() => import('./BillingCodeRulesPage')}>
    <Route
      index
      element={
        <EmptyLayout>
          <Navigate to='cost-line-rules' replace />
        </EmptyLayout>
      }
    />
    <Route path='cost-line-rules' element={<TabBillingCodeRules />}></Route>
    <Route path='cost-line-rules/:id' element={<BillingCodeRuleDetailsPage />} />
    <Route path='accessory-rules' element={<TabAccessoryRules />}></Route>
    <Route path='accessory-rules/:id' element={<AccessoryRuleDetailsPage />} />
  </Route>
);
