import { ChangeEvent, ForwardedRef, forwardRef, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { DataID, Disposable, fetchQuery, useLazyLoadQuery, useMutation, useRelayEnvironment } from 'react-relay';
import { ListLayout, SidebarContentProps } from '../layout/Layouts';
import { defaultLogger, Logger } from '../common/utils/logging';
import { _throw } from '../common/utils/_throw';
import { useCancellableSubscription } from '../common/hooks/useCancellableSubscription';
import { namedPageTitle, usePageTitle } from '../common/hooks/usePageTitle';
import { LoadingButton } from '../common/components/LoadingButton';
import { CraneChartList, CraneChartListHandle, CraneChartListProps } from './CraneChartList';
import { ConfigurationTabs } from './ConfigurationTabs';
import graphql from 'babel-plugin-relay/macro';
import { TabCraneChartsQuery } from './__generated__/TabCraneChartsQuery.graphql';
import { TabCraneChartsErrorLogQuery } from './__generated__/TabCraneChartsErrorLogQuery.graphql';
import {
  StartCraneChartBlobUploadInput,
  TabCraneChartsStartBlobUploadMutation,
} from './__generated__/TabCraneChartsStartBlobUploadMutation.graphql';
import {
  CompleteCraneChartBlobUploadInput,
  TabCraneChartsCompleteBlobUploadMutation,
} from './__generated__/TabCraneChartsCompleteBlobUploadMutation.graphql';
import { RequireAdmin, UnauthorizedFallback } from '../auth/Authorization';
import { useOutletContext } from 'react-router-dom';
import { ConfigurationPageQuery$data } from './__generated__/ConfigurationPageQuery.graphql';
import { NavigationMenu } from '../layout/SidebarDrawer';
import { FileImport } from '../common/utils/fileUpload';
import { ErrorBanner, ErrorStateProvider, useErrorBanner } from '../common/components/ErrorBanner';

interface UploadStateBase {
  /**
   * Make every prop of UploadState accessible via an indexer which is required
   * to use it as a translation option.
   */
  [key: string]: unknown;
}

interface IdleUploadState extends UploadStateBase {
  stage: 'idle';
}

interface UploadingUploadState extends UploadStateBase {
  stage: 'uploading';
}

interface SuccessUploadState extends UploadStateBase {
  stage: 'success';
  count: number;
}

interface ErrorUploadState extends UploadStateBase {
  stage: 'error';
  error: unknown;
}

type UploadState = IdleUploadState | UploadingUploadState | SuccessUploadState | ErrorUploadState;

const logger = new Logger(defaultLogger, 'BlobUpload', () => new Date().toISOString());
function useCraneChartBlobUpload(onProgress: () => void) {
  const { t } = useTranslation('configuration');
  const { reportHandledError } = useErrorBanner();

  const [startBlobUpload] = useMutation<TabCraneChartsStartBlobUploadMutation>(graphql`
    mutation TabCraneChartsStartBlobUploadMutation($inputs: [StartCraneChartBlobUploadInput!]!) {
      startCraneChartBlobUpload(inputs: $inputs) {
        craneChartBlobSasUris {
          craneChartBlobMetadata {
            id
            name
            byteCount
            mimeType
            storageContainer
            status
          }
          sasUri
        }
        errors {
          __typename
          ... on SalesApiValidationError {
            ...ErrorBannerValidationErrorFragment
          }
        }
      }
    }
  `);
  const startBlobUploadSubscription = useRef<Disposable>();

  const [completeBlobUpload] = useMutation<TabCraneChartsCompleteBlobUploadMutation>(graphql`
    mutation TabCraneChartsCompleteBlobUploadMutation($inputs: [CompleteCraneChartBlobUploadInput!]!) {
      completeCraneChartBlobUpload(inputs: $inputs) {
        craneChartBlobMetadatas {
          id
          name
          byteCount
          mimeType
          storageContainer
          status
        }
        errors {
          __typename
        }
      }
    }
  `);
  const completeBlobUploadSubscription = useRef<Disposable>();

  const [startedUploads, setStartedUploads] = useState<FileImport[]>([]);
  const [completedUploads, setCompletedUploads] = useState<FileImport[]>([]);
  const [uploadState, setUploadState] = useState<UploadState>({ stage: 'idle' });

  const resetState = useCallback(() => {
    setUploadState((prev) => {
      if (prev.stage === 'success' || prev.stage === 'error') {
        return { stage: 'idle' };
      }
      return prev;
    });
  }, []);

  const handleImportClick = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (uploadState.stage === 'uploading') {
        return;
      }

      const fileList = e.target.files;
      if (!fileList?.length) {
        return;
      }

      setUploadState({ stage: 'uploading' });

      const files = Array.from(fileList);
      const inputs = files.map(
        (file): StartCraneChartBlobUploadInput => ({
          name: file.name,
          byteCount: file.size,
        }),
      );
      startBlobUploadSubscription.current = startBlobUpload({
        variables: { inputs },
        onCompleted(response, errors) {
          startBlobUploadSubscription.current = undefined;
          if (errors) {
            for (const error of errors) {
              captureException(error);
            }
            logger.error(errors);
            setUploadState({ stage: 'error', error: errors[0] });
          } else if (response?.startCraneChartBlobUpload?.errors?.length) {
            reportHandledError(response.startCraneChartBlobUpload.errors, () => t('error.errorDuringUpload'));

            // TODO: This shows another banner, does it affect the status of the upload ?
            // Most of the time the errors here did not even trigger an upload (e.g validation failure)
            // setUploadState({ stage: 'error', error: response.startCraneChartBlobUpload.errors[0] });
          } else {
            setStartedUploads(
              response.startCraneChartBlobUpload.craneChartBlobSasUris?.map((bsu) => ({
                file: files.find((f) => f.name === bsu.craneChartBlobMetadata.name) ?? _throw(new Error(t('craneCharts.unexpectedError'))),
                blobMetadataId: bsu.craneChartBlobMetadata.id,
                mimeType: bsu.craneChartBlobMetadata.mimeType,
                sasUri: bsu.sasUri,
              })) ?? [],
            );
          }
        },
      });
      e.target.value = '';
    },
    [uploadState.stage, startBlobUpload, reportHandledError, t],
  );

  useEffect(() => {
    if (!startedUploads.length || startBlobUploadSubscription.current) {
      return;
    }

    onProgress();
    Promise.all(
      startedUploads.map(async (item): Promise<FileImport> => {
        const response = await fetch(item.sasUri, {
          method: 'put',
          headers: {
            'Content-Type': item.mimeType,
            'x-ms-date': new Date().toUTCString(),
            'x-ms-blob-type': 'BlockBlob',
          },
          body: item.file,
        });
        if (!response.ok) {
          throw new Error(response.statusText, {
            cause: {
              status: response.status,
              statusText: response.statusText,
              body: await response.text().then(
                (v) => v,
                (e) => `${e}`,
              ),
            },
          });
        }
        return item;
      }),
    ).then(
      (uploadedFiles) => {
        setCompletedUploads(uploadedFiles);
      },
      (errors: unknown) => {
        for (const error of errors as unknown[]) {
          captureException(error);
        }
        logger.error(errors);
        setUploadState({ stage: 'error', error: (errors as unknown[])[0] });
        setStartedUploads([]);
      },
    );
  }, [onProgress, startedUploads]);

  useEffect(() => {
    if (!completedUploads.length || completeBlobUploadSubscription.current) {
      return;
    }

    completeBlobUploadSubscription.current = completeBlobUpload({
      variables: {
        inputs: completedUploads.map((file): CompleteCraneChartBlobUploadInput => ({ id: file.blobMetadataId })),
      },
      onCompleted(response, errors) {
        completeBlobUploadSubscription.current = undefined;
        if (errors) {
          for (const error of errors as unknown[]) {
            captureException(error);
          }
          logger.error(errors);
          setUploadState({ stage: 'error', error: (errors as unknown[])[0] });
        } else {
          setUploadState({ stage: 'success', count: completedUploads.length });
        }
        setStartedUploads([]);
        setCompletedUploads([]);
        onProgress();
      },
      onError(error) {
        completeBlobUploadSubscription.current = undefined;
        if (error) {
          captureException(error);
          logger.error(error);
          setUploadState({ stage: 'error', error: error });
        }
        onProgress();
        setStartedUploads([]);
        setCompletedUploads([]);
      },
    });
  }, [onProgress, completeBlobUpload, completedUploads]);

  return {
    uploadState,
    handleImportClick,
    resetState,
  };
}

export function TabCraneCharts() {
  const { t } = useTranslation('configuration');
  const $data = useOutletContext<ConfigurationPageQuery$data>();

  usePageTitle(namedPageTitle('sidebar.configuration'));

  const listRef = useRef<CraneChartListHandle | null>(null);

  const { uploadState, handleImportClick, resetState } = useCraneChartBlobUpload(() => listRef.current?.refresh());
  const isUploading = uploadState.stage === 'uploading';
  const importSuccess = uploadState.stage === 'success';
  const showDialog = importSuccess || uploadState.stage === 'error';
  const severity = importSuccess ? 'success' : 'error';

  const environment = useRelayEnvironment();
  const [, setSubscription] = useCancellableSubscription();

  const handleItemClick = useCallback(
    (id: DataID) => {
      setSubscription(
        fetchQuery<TabCraneChartsErrorLogQuery>(
          environment,
          graphql`
            query TabCraneChartsErrorLogQuery($id: ID!) {
              node(id: $id) {
                ... on CraneChartBlobMetadata {
                  status
                  storageErrorFileName
                  errorLogUrl
                }
              }
            }
          `,
          { id: id },
          { fetchPolicy: 'network-only' },
        ).subscribe({
          next: (data) => {
            if (data.node && data.node.status === 'error' && data.node.errorLogUrl) {
              const link = document.createElement('a');
              link.href = data.node.errorLogUrl;
              link.download = data.node.storageErrorFileName || '';
              document.body.appendChild(link);
              link.click();
              link.parentNode?.removeChild(link);
            }
          },
        }),
      );
    },
    [setSubscription, environment],
  );

  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);

  return (
    <RequireAdmin
      $key={$data}
      fallback={
        <ListLayout heading={t('configuration')} sidebarProvider={sidebar} $key={$data}>
          <UnauthorizedFallback />
        </ListLayout>
      }>
      <ErrorStateProvider>
        <ListLayout
          heading={t('configuration')}
          sidebarProvider={sidebar}
          $key={$data}
          actions={
            <LoadingButton isLoading={isUploading} component='label'>
              {t('button.import', { ns: 'common' })}
              <input data-testid='upload.input' type='file' accept='.xlsx' multiple hidden onChange={handleImportClick} />
            </LoadingButton>
          }>
          <ErrorBanner />
          <ConfigurationTabs tab='crane-charts'></ConfigurationTabs>
          {showDialog && (
            <Alert data-testid='upload.alert' severity={severity} onClose={() => resetState()}>
              {uploadState.stage === 'success' && t('craneCharts.importSuccessMessage', uploadState)}
              {uploadState.stage === 'error' && t('craneCharts.importErrorMessage')}
            </Alert>
          )}
          <Suspense fallback={<ContentSkeleton />}>
            <CraneChartListContent
              ref={listRef}
              onItemClick={handleItemClick}
              // HACK: There is a bug in WebStorm with properties attached to Omit types that sometimes causes those
              //  props to be detected as an attribute instead, which cannot use the boolean shorthand of props.
              dummy={true}
            />
          </Suspense>
        </ListLayout>
      </ErrorStateProvider>
    </RequireAdmin>
  );
}

const tabCraneChartsQuery = graphql`
  query TabCraneChartsQuery($first: Int) {
    ...CraneChartListFragment @arguments(first: $first)
  }
`;
const CraneChartListContent = forwardRef(function CraneChartListContent(
  props: Omit<CraneChartListProps, 'fragmentKey'> & {
    // HACK: There appear to be a compiler bug in typescript that causes the JSX produced by this component to completely
    //  skip the Omitted properties unless more properties are also added. Without this dummy prop, usages will show is
    //  still required.
    dummy: boolean;
  },
  ref: ForwardedRef<CraneChartListHandle>,
) {
  const craneCharts = useLazyLoadQuery<TabCraneChartsQuery>(tabCraneChartsQuery, {}, { fetchPolicy: 'store-and-network' });

  return <CraneChartList ref={ref} {...props} fragmentKey={craneCharts} />;
});

function ContentSkeleton() {
  return <Skeleton variant='rounded' height='30rem' />;
}
