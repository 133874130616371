export const billingCodeRule = {
  dialog: {
    deleteTitle: 'Supprimer la règle',
    deleteMessage: 'Voulez-vous vraiment supprimer cette règle\u00a0?',
    confirmation: 'Ok',
  },
  newTitle: 'Nouvelle règle',
  editTitle: 'Modifier la règle',
  error: {
    errorDuringDeleteAccessory: 'La mutation de suppression de la règle d’accessoire a échoué avec une réponse nulle',
    errorDuringDeleteBillingCode: 'La mutation de suppression de la règle de code de facturation a échoué avec une réponse nulle',
    errorDuringSaveAccessory: 'La mutation de sauvegarde n’a pas produit de règle d’accessoire valide',
    errorDuringSaveBillingCode: 'La mutation de sauvegarde n’a pas produit de règle de code de facturation valide',
    errorDuringDelete: 'Une erreur s’est produite pendant la supression de la règle',
  },
  placeholder: {
    kindListEmpty: 'Tous les types',
  },
  fields: {
    label: {
      accessories: 'Accesoires',
      billed: 'Facturé',
      billingCode: 'Nom du code de facturation',
      billingCodes: 'Codes',
      billingCodesAutomatic: 'Automatiques',
      billingCodesVisible: 'Visibles',
      capacities: 'Capacités',
      clients: 'Clients',
      conditions: 'Conditions',
      description: 'Description',
      equipmentKinds: 'Genres',
      labor: 'Main d’oeuvre',
      information: 'Information',
      kinds: 'Types',
      natureOfWorkSubCategory: 'Catégories de nature du travail',
      natureOfWorks: 'Natures de travail',
      specialSituation: 'Situations particulières',
    },
    specialProjects: 'Projets spéciaux',
  },
  section: {
    billingCodeRules: 'Règles de lignes de coût',
    accessoryRules: 'Règles d’accessoires',
  },
  title: 'Règles de code de facturation',
};
