import { Box, SxProps } from '@mui/material';
import { ReactNode, useMemo } from 'react';

export const eventNames = [
  'onAbort',
  'onAnimationEnd',
  'onAnimationIteration',
  'onAnimationStart',
  'onAuxClick',
  'onBeforeInput',
  'onBlur',
  'onCanPlay',
  'onCanPlayThrough',
  'onChange',
  'onClick',
  'onCompositionEnd',
  'onCompositionStart',
  'onCompositionUpdate',
  'onContextMenu',
  'onCopy',
  'onCut',
  'onDoubleClick',
  'onDrag',
  'onDragEnd',
  'onDragEnter',
  'onDragExit',
  'onDragLeave',
  'onDragOver',
  'onDragStart',
  'onDrop',
  'onDurationChange',
  'onEmptied',
  'onEncrypted',
  'onEnded',
  'onError',
  'onFocus',
  'onGotPointerCapture',
  'onInput',
  'onInvalid',
  'onKeyDown',
  'onKeyUp',
  'onLoad',
  'onLoadStart',
  'onLoadedData',
  'onLoadedMetadata',
  'onLostPointerCapture',
  'onMouseDown',
  'onMouseEnter',
  'onMouseLeave',
  'onMouseMove',
  'onMouseOut',
  'onMouseOver',
  'onMouseUp',
  'onPaste',
  'onPause',
  'onPlay',
  'onPlaying',
  'onPointerCancel',
  'onPointerDown',
  'onPointerEnter',
  'onPointerLeave',
  'onPointerMove',
  'onPointerOut',
  'onPointerOver',
  'onPointerUp',
  'onProgress',
  'onRateChange',
  'onReset',
  'onScroll',
  'onSeeked',
  'onSeeking',
  'onSelect',
  'onStalled',
  'onSubmit',
  'onSuspend',
  'onTimeUpdate',
  'onTouchCancel',
  'onTouchEnd',
  'onTouchMove',
  'onTouchStart',
  'onTransitionEnd',
  'onVolumeChange',
  'onWaiting',
  'onWheel',
] as const;

export type EventName = (typeof eventNames)[number];

export type CaptureEvent<T extends EventName> = `${T}Capture`;
export type CapturedEventName = CaptureEvent<EventName>;

/**
 * Stops event propagation upward of the boundary.
 *
 * Stops propagation of all events ({@link eventNames}) by default
 * @param ignore List of events to always propagate through the boundary
 * @param capture List of events to capture
 * @param sx
 * @param children
 * @constructor
 */
export function EventBoundary({
  ignore,
  capture,
  sx,
  children,
}: {
  ignore?: readonly EventName[];
  capture?: readonly EventName[];
  sx?: SxProps;
  children: ReactNode;
}) {
  const appliedEvents = useMemo(
    () =>
      Object.fromEntries(
        (eventNames.filter((n) => (ignore ? !ignore.includes(n) : true)) as readonly (EventName | CapturedEventName)[])
          .concat(capture?.map((n) => `${n}Capture` as CapturedEventName) ?? [])
          .map((n) => [n, (e: Event) => e.stopPropagation()]),
      ),
    [capture, ignore],
  );

  return (
    <Box className='EventBoundary' role='presentation' sx={sx} {...appliedEvents}>
      {children}
    </Box>
  );
}
