import { commitLocalUpdate, useRelayEnvironment } from 'react-relay';
import { useCallback } from 'react';

/**
 * Returns a function to be used in the ErrorFallback that allows to retry the failed operation
 *
 * Note: For now it simply refreshes the page, eventually we need to implement this:
 * https://relay.dev/docs/v16.0.0/guided-tour/rendering/error-states/#when-using-uselazyloadquery
 */
export function useRetryCallback() {
  const environment = useRelayEnvironment();

  return useCallback(
    (resetError: () => void) => () => {
      commitLocalUpdate(environment, (store) => store.invalidateStore());
      resetError();

      // TODO: Implement this https://relay.dev/docs/v16.0.0/guided-tour/rendering/error-states/#when-using-uselazyloadquery
      // When we have time to do so.
      window.location.reload();
    },
    [environment],
  );
}
