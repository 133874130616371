import { IconButton, Snackbar as MuiSnackbar } from '@mui/material';
import { SnackbarCloseReason } from '@mui/material/Snackbar/useSnackbar.types';
import CloseIcon from '@mui/icons-material/Close';

export function Snackbar({ open, message, onClose: handleOnClose }: { open: boolean; message: string; onClose: () => void }) {
  const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    handleOnClose();
  };

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={5000}
      open={open}
      onClose={handleClose}
      message={message}
      action={
        <IconButton aria-label='close' color='inherit' sx={{ p: '0.25rem' }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }
    />
  );
}
