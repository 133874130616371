/**
 * @generated SignedSource<<97b27ede82b5a6ee7136e6ae390f15de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SalesQuestionKind = "accidentScene" | "bargeWork" | "clamShellBucketOrGrapple" | "conventionalCrawlerCranesWithTravel" | "conventionalCrawlerCranesWithoutTravel" | "craneOrObstacleProximity" | "events" | "excavationProximityLessThan10Feet" | "excavationProximityMoreThan10Feet" | "hazardousTerrain" | "jettyBridgeViaductDamSlabOrParkingDeck" | "luffingJibOrExtensionInstallation" | "movingCraneOrEquipmentWithPulling" | "movingCraneOrEquipmentWithoutPulling" | "peopleLifting" | "powerlineProximity" | "tandemLiftingClientCrane" | "tandemLiftingGuayCrane" | "unloadingOnQuay" | "valueOf1MillionOrMore" | "videoOrPhotoShooting" | "windTurbines" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuestionsBaseFields_ExcavationLessThan10FeetFragment$data = {
  readonly salesQuestions: ReadonlyArray<{
    readonly kind: SalesQuestionKind;
    readonly " $fragmentSpreads": FragmentRefs<"QuestionsBaseFields_AngleFragment" | "QuestionsBaseFields_BerlinWallFragment" | "QuestionsBaseFields_DistanceFragment" | "QuestionsBaseFields_TilledSoilFragment">;
  }>;
  readonly " $fragmentType": "QuestionsBaseFields_ExcavationLessThan10FeetFragment";
};
export type QuestionsBaseFields_ExcavationLessThan10FeetFragment$key = {
  readonly " $data"?: QuestionsBaseFields_ExcavationLessThan10FeetFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionsBaseFields_ExcavationLessThan10FeetFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionsBaseFields_ExcavationLessThan10FeetFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "salesQuestions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "QuestionsBaseFields_AngleFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "QuestionsBaseFields_BerlinWallFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "QuestionsBaseFields_DistanceFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "QuestionsBaseFields_TilledSoilFragment"
            }
          ],
          "type": "SalesQuestionForExcavationProximityLessThan10Feet",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QuestionsInternalBase",
  "abstractKey": null
};

(node as any).hash = "19fb836f45b98205397c220612a631bc";

export default node;
