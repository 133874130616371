/**
 * @generated SignedSource<<4b6e7a0e50dc56210460889aaeb470bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationMenu_MarkNotificationAsReadMutation$variables = {
  deleteFrom: ReadonlyArray<string>;
  id: string;
};
export type NotificationMenu_MarkNotificationAsReadMutation$data = {
  readonly markNotificationRead: {
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
    }> | null | undefined;
    readonly notificationEdge: {
      readonly cursor: string;
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"NotificationRowFragment">;
      };
    } | null | undefined;
  };
};
export type NotificationMenu_MarkNotificationAsReadMutation = {
  response: NotificationMenu_MarkNotificationAsReadMutation$data;
  variables: NotificationMenu_MarkNotificationAsReadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleteFrom"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRead",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friendlyId",
  "storageKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permalink",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        }
      ],
      "type": "Notification",
      "abstractKey": "__isNotification"
    }
  ],
  "type": "PermalinkNotificationBase",
  "abstractKey": "__isPermalinkNotificationBase"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationMenu_MarkNotificationAsReadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "MarkNotificationReadPayload",
        "kind": "LinkedField",
        "name": "markNotificationRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationEdge",
            "kind": "LinkedField",
            "name": "notificationEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "NotificationRowFragment"
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NotificationMenu_MarkNotificationAsReadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "MarkNotificationReadPayload",
        "kind": "LinkedField",
        "name": "markNotificationRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationEdge",
            "kind": "LinkedField",
            "name": "notificationEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "deleteFrom"
                      }
                    ]
                  },
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isNotification"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "comment",
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "type": "ServiceCallModificationRequestedNotification",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "clientName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DateTimeInTimeZone",
                        "kind": "LinkedField",
                        "name": "arrivalDate",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rawValue",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "equipmentKindAbbreviation",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "craneCapacity",
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "type": "QuoteStatusChangeNotification",
                    "abstractKey": "__isQuoteStatusChangeNotification"
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e05d4052fd8dbcf96c94dee187079794",
    "id": null,
    "metadata": {},
    "name": "NotificationMenu_MarkNotificationAsReadMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationMenu_MarkNotificationAsReadMutation(\n  $id: ID!\n) {\n  markNotificationRead(input: {id: $id}) {\n    notificationEdge {\n      node {\n        __typename\n        id\n        ...NotificationRowFragment\n      }\n      cursor\n    }\n    errors {\n      __typename\n    }\n  }\n}\n\nfragment NotificationRowFragment on Notification {\n  __isNotification: __typename\n  __typename\n  ...NotificationRow_ModificationRequestedFragment\n  ...NotificationRow_QuoteStatusNotificationFragment\n}\n\nfragment NotificationRow_BaseFragment on Notification {\n  __isNotification: __typename\n  id\n  isRead\n  createdAt\n}\n\nfragment NotificationRow_ModificationRequestedFragment on ServiceCallModificationRequestedNotification {\n  isRead\n  friendlyId\n  comment\n  ...NotificationRow_PermalinkFragment\n}\n\nfragment NotificationRow_PermalinkFragment on PermalinkNotificationBase {\n  __isPermalinkNotificationBase: __typename\n  permalink\n  isRead\n  ...NotificationRow_BaseFragment\n}\n\nfragment NotificationRow_QuoteStatusNotificationFragment on QuoteStatusChangeNotification {\n  __isQuoteStatusChangeNotification: __typename\n  isRead\n  friendlyId\n  clientName\n  arrivalDate {\n    rawValue\n  }\n  equipmentKindAbbreviation\n  craneCapacity\n  ...NotificationRow_PermalinkFragment\n}\n"
  }
};
})();

(node as any).hash = "41323f3d64adb3097b9475c397115464";

export default node;
