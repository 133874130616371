/**
 * @generated SignedSource<<c83201e8ef90b1f780685e2c345fc310>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RateStrategyCategory = "additionalCrane" | "boomCounterweightTransport" | "flatbedTransport" | "mainCrane" | "%future added value";
export type SaveRateStrategyInput = {
  categories: ReadonlyArray<RateStrategyCategory>;
  labelEn: string;
  labelFr: string;
};
export type RateStrategySaveButtonMutation$variables = {
  id?: string | null | undefined;
  saveRateStrategy: SaveRateStrategyInput;
};
export type RateStrategySaveButtonMutation$data = {
  readonly saveRateStrategy: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "SalesApiValidationError";
      readonly " $fragmentSpreads": FragmentRefs<"ErrorBannerValidationErrorFragment">;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    }> | null | undefined;
    readonly rateStrategyLookup: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"RateStrategyDetailsPageFragment">;
    } | null | undefined;
  };
};
export type RateStrategySaveButtonMutation = {
  response: RateStrategySaveButtonMutation$data;
  variables: RateStrategySaveButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "saveRateStrategy"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "saveRateStrategy",
    "variableName": "saveRateStrategy"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RateStrategySaveButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveRateStrategyPayload",
        "kind": "LinkedField",
        "name": "saveRateStrategy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RateStrategyLookup",
            "kind": "LinkedField",
            "name": "rateStrategyLookup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RateStrategyDetailsPageFragment"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ErrorBannerValidationErrorFragment"
                  }
                ],
                "type": "SalesApiValidationError",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RateStrategySaveButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveRateStrategyPayload",
        "kind": "LinkedField",
        "name": "saveRateStrategy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RateStrategyLookup",
            "kind": "LinkedField",
            "name": "rateStrategyLookup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "categories",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesValidationError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "propertyName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "index",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "comparisonValue",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "comparisonPropertyName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "actualValue",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "SalesApiValidationError",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b3d70a8b1f2065d8bf3c80bf420e0b18",
    "id": null,
    "metadata": {},
    "name": "RateStrategySaveButtonMutation",
    "operationKind": "mutation",
    "text": "mutation RateStrategySaveButtonMutation(\n  $id: ID\n  $saveRateStrategy: SaveRateStrategyInput!\n) {\n  saveRateStrategy(id: $id, saveRateStrategy: $saveRateStrategy) {\n    rateStrategyLookup {\n      id\n      ...RateStrategyDetailsPageFragment\n    }\n    errors {\n      __typename\n      ... on SalesApiValidationError {\n        ...ErrorBannerValidationErrorFragment\n      }\n    }\n  }\n}\n\nfragment ErrorBannerValidationErrorFragment on SalesApiValidationError {\n  errors {\n    propertyName\n    code\n    index\n    comparisonValue\n    comparisonPropertyName\n    actualValue\n  }\n}\n\nfragment RateStrategyDetailsPageFragment on RateStrategyLookup {\n  id\n  ...RateStrategyFieldsEnglishLabelFragment\n  ...RateStrategyFieldsFrenchLabelFragment\n  ...RateStrategyFields_useFieldRateStrategyCategoriesFragment\n}\n\nfragment RateStrategyFieldsEnglishLabelFragment on RateStrategyLookup {\n  description\n}\n\nfragment RateStrategyFieldsFrenchLabelFragment on RateStrategyLookup {\n  description\n}\n\nfragment RateStrategyFields_useFieldRateStrategyCategoriesFragment on RateStrategyLookup {\n  categories\n}\n"
  }
};
})();

(node as any).hash = "86caebea4a5f46daf647d337293247e7";

export default node;
