/**
 * @generated SignedSource<<7ce888d8643eed210cb57f677686f3f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeRuleDetailsPage_ConditionsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleFields_BaseQuestions" | "BillingCodeRuleFields_CapacitiesFragment" | "BillingCodeRuleFields_ClientsFragment" | "BillingCodeRuleFields_EquipmentKindsFragment" | "BillingCodeRuleFields_NatureOfWorkSubCategoriesFragment" | "BillingCodeRuleFields_NatureOfWorksFragment" | "BillingCodeRuleFields_SaleKindsFragment">;
  readonly " $fragmentType": "BillingCodeRuleDetailsPage_ConditionsFragment";
};
export type BillingCodeRuleDetailsPage_ConditionsFragment$key = {
  readonly " $data"?: BillingCodeRuleDetailsPage_ConditionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleDetailsPage_ConditionsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleDetailsPage_ConditionsFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFields_SaleKindsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFields_CapacitiesFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFields_EquipmentKindsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFields_ClientsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFields_NatureOfWorksFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFields_NatureOfWorkSubCategoriesFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFields_BaseQuestions"
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};

(node as any).hash = "94eae6006635f07f1e611799a81b8687";

export default node;
