import { ForwardedRef, forwardRef, MouseEvent, useCallback, useImperativeHandle, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  ToggleButton,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CopyStrategy } from '../__enums__/CopyStrategy';
import { useTranslation } from 'react-i18next';
import { JobStageImageLinked } from './JobStageImageLinked';
import { JobStageImageUnlinked } from './JobStageImageUnlinked';
import { LoadingButton } from '@mui/lab';
import { useOperations } from '../AppSharedState';

export const COPY_OPERATION_KEY = 'copyButtonMutation';

const strategyButtonSx = {
  borderRadius: '10px',
  '&.Mui-selected': {
    border: `1.5px solid black`,
  },
};

export type SaleCopyFormValues = {
  options: {
    keepAccessories: boolean;
    keepAddenda: boolean;
    keepAttachments: boolean;
  };
  strategy: CopyStrategy;
};

export type SaleCopyDialogRef = {
  readonly open: (onConfirm?: (search: string) => void) => void;
  readonly close: () => void;
};

export const SaleCopyDialog = forwardRef(function SaleCopyDialog(
  {
    hasAccessories = true,
    hasAddenda = true,
    hasAttachments = true,
  }: {
    hasAccessories?: boolean;
    hasAddenda?: boolean;
    hasAttachments?: boolean;
  },
  ref: ForwardedRef<SaleCopyDialogRef>,
) {
  const { t } = useTranslation('jobs');
  const theme = useTheme();
  const { hasOperationInFlightForKey } = useOperations(COPY_OPERATION_KEY);

  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState<((search: string) => void) | undefined>();

  const [strategy, setStrategy] = useState<SaleCopyFormValues['strategy']>('linked');
  const [keepAccessories, setKeepAccessories] = useState<SaleCopyFormValues['options']['keepAccessories']>(false);
  const [keepAddenda, setKeepAddenda] = useState<SaleCopyFormValues['options']['keepAddenda']>(false);
  const [keepAttachments, setKeepAttachments] = useState<SaleCopyFormValues['options']['keepAttachments']>(false);

  const handleStrategyChange = (_: MouseEvent<HTMLElement>, value: CopyStrategy) => setStrategy(value);

  const resetValues = useCallback(() => {
    setStrategy('linked');
    setKeepAccessories(false);
    setKeepAddenda(false);
    setKeepAttachments(false);
  }, []);
  const handleConfirm = useCallback(() => {
    const params = new URLSearchParams({
      strategy: strategy,
      keepAttachments: keepAttachments.toString(),
      keepAddenda: keepAddenda.toString(),
      keepAccessories: keepAccessories.toString(),
    });

    onConfirm?.(params.toString());
  }, [keepAccessories, keepAddenda, keepAttachments, onConfirm, strategy]);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
    resetValues();
  }, [resetValues]);

  useImperativeHandle(
    ref,
    () => ({
      open: (confirmFn) => {
        setIsOpen(true);
        setOnConfirm(() => confirmFn);
      },
      close: () => {
        setIsOpen(false);
        resetValues();
      },
    }),
    [resetValues],
  );

  return (
    <Dialog open={isOpen} onClose={handleCancel} maxWidth='md'>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <IconButton color='inherit' data-testid='closeButton' onClick={handleCancel} sx={{ ml: -1.5 }}>
          <CloseIcon />
        </IconButton>
        {t('dialog.copy.title')}
      </DialogTitle>
      <DialogContent sx={{ gap: '0.5rem', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', gap: '1rem', [theme.breakpoints.down('md')]: { flexDirection: 'column' } }}>
          <ToggleButton selected={strategy === 'linked'} onChange={handleStrategyChange} value='linked' sx={strategyButtonSx}>
            <DialogImageButton image={<JobStageImageLinked />} title={t('dialog.copy.sameProject')} />
          </ToggleButton>
          <ToggleButton selected={strategy === 'unlinked'} onChange={handleStrategyChange} value='unlinked' sx={strategyButtonSx}>
            <DialogImageButton image={<JobStageImageUnlinked />} title={t('dialog.copy.newProject')} />
          </ToggleButton>
        </Box>
        <Typography
          sx={{
            pt: '0.5rem',
            pb: '1rem',
            backgroundColor: theme.palette.background.paper,
          }}>
          {t('dialog.copy.body')}
        </Typography>
        <FormGroup>
          {hasAccessories && (
            <FormControlLabel
              control={<Switch checked={keepAccessories} value={keepAccessories} onChange={(_, checked) => setKeepAccessories(checked)} />}
              label={t('dialog.copy.accessories')}
            />
          )}
          {hasAddenda && (
            <FormControlLabel
              control={<Switch checked={keepAddenda} value={keepAddenda} />}
              onChange={(_, checked) => setKeepAddenda(checked)}
              label={t('dialog.copy.addenda')}
            />
          )}
          {hasAttachments && (
            <FormControlLabel
              control={<Switch checked={keepAttachments} value={keepAttachments} />}
              onChange={(_, checked) => setKeepAttachments(checked)}
              label={t('dialog.copy.attachments')}
            />
          )}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={hasOperationInFlightForKey} color='primary' onClick={handleConfirm}>
          {t('dialog.copy.confirmButton')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});

function DialogImageButton({ image, title }: { image: JSX.Element; title: string }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}>
      {image}
      <Typography>{title}</Typography>
    </Box>
  );
}
