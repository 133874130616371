/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const accessoryLineKinds = ["automatic", "manual"] as const;
export type AccessoryLineKind = typeof accessoryLineKinds[number];

export function isAccessoryLineKind(value: string): value is AccessoryLineKind {
  return accessoryLineKinds.includes(value)
}

export function asAccessoryLineKind(value: string): AccessoryLineKind | null {
  return isAccessoryLineKind(value) ? value : null;
}

export function castAccessoryLineKind(value: string): AccessoryLineKind {
  if(!isAccessoryLineKind(value)) {
    throw new Error(`Invalid Enum value for type "AccessoryLineKind": "${value}"`);
  }
  
  return value;
}
