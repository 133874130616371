import { createSharedStateContext, createSharedStateKey } from '../common/utils/sharedState';
import { AssignedClient_Label_BaseFragment$key } from './__generated__/AssignedClient_Label_BaseFragment.graphql';
import { AssignedClient_Label_OverridableFragment$key } from './__generated__/AssignedClient_Label_OverridableFragment.graphql';
import { AssignedClient_Overrides_BaseFragment$key } from './__generated__/AssignedClient_Overrides_BaseFragment.graphql';
import { AssignedClient_Overrides_DenormalizableFragment$key } from './__generated__/AssignedClient_Overrides_DenormalizableFragment.graphql';
import { AssignedWorksite_Overrides_DenormalizableFragment$key } from './__generated__/AssignedWorksite_Overrides_DenormalizableFragment.graphql';
import { AssignedWorksite_Overrides_BaseFragment$key } from './__generated__/AssignedWorksite_Overrides_BaseFragment.graphql';
import { AssignedWorksite_Label_BaseFragment$key } from './__generated__/AssignedWorksite_Label_BaseFragment.graphql';
import { CraneConfiguration_MatchingConfigurationsFragment$key } from './__generated__/CraneConfiguration_MatchingConfigurationsFragment.graphql';
import { AssignedNatureOfWork_Fields_UpdatedNatureOfWorkFragment$key } from './__generated__/AssignedNatureOfWork_Fields_UpdatedNatureOfWorkFragment.graphql';
import { AssignedNatureOfWork_useSetSubFieldsQueryFragment$key } from './__generated__/AssignedNatureOfWork_useSetSubFieldsQueryFragment.graphql';
import { ClientFormSectionCompact_ClientFragment$key } from './formSections/__generated__/ClientFormSectionCompact_ClientFragment.graphql';
import { CraneConfiguration_useUpdateConfigurationQueryFragment$key } from './__generated__/CraneConfiguration_useUpdateConfigurationQueryFragment.graphql';
import { useAutomaticAddenda_useUpdateAutomaticAddendaFragment$key } from './__generated__/useAutomaticAddenda_useUpdateAutomaticAddendaFragment.graphql';

export const jobSharedStateContext = createSharedStateContext();

type UpdatedAssignedClient$keys = {
  baseLabel$key: AssignedClient_Label_BaseFragment$key;
  staticLabel$key: AssignedClient_Label_OverridableFragment$key;
  baseOverrides$key: AssignedClient_Overrides_BaseFragment$key;
  denormalizableOverrides$key: AssignedClient_Overrides_DenormalizableFragment$key;
  formSectionCompact$key: ClientFormSectionCompact_ClientFragment$key;
} | null;
export const updatedAssignedClient$keysSharedStateKey = createSharedStateKey<UpdatedAssignedClient$keys>(() => null);

type UpdatedAssignedWorksite$keys = {
  baseLabel$key: AssignedWorksite_Label_BaseFragment$key;
  baseOverrides$key: AssignedWorksite_Overrides_BaseFragment$key;
  denormalizableOverrides$key: AssignedWorksite_Overrides_DenormalizableFragment$key;
} | null;
export const updatedAssignedWorksite$keysSharedStateKey = createSharedStateKey<UpdatedAssignedWorksite$keys>(() => null);

type UpdatedNatureOfWork$keys = {
  updated$key: AssignedNatureOfWork_Fields_UpdatedNatureOfWorkFragment$key;
  useSetSubField$key: AssignedNatureOfWork_useSetSubFieldsQueryFragment$key;
} | null;
export const updatedNatureOfWork$keysSharedStateKey = createSharedStateKey<UpdatedNatureOfWork$keys>(() => null);

type UpdatedMatchingConfigurations$keys = {
  updated$key: CraneConfiguration_MatchingConfigurationsFragment$key;
  useUpdateConfiguration$key: CraneConfiguration_useUpdateConfigurationQueryFragment$key;
} | null;
export const updatedMatchingConfigurations$keysSharedStateKey = createSharedStateKey<UpdatedMatchingConfigurations$keys>(() => null);

export const updatedAutomaticAddenda$keysSharedStateKey = createSharedStateKey<
  useAutomaticAddenda_useUpdateAutomaticAddendaFragment$key | null | undefined
>(() => null);
