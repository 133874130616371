import { NumberParser } from './numberUtils';
import { TFunction } from 'i18next';
import { _never } from './_never';

export enum PriceFormat {
  /** Number format optimized for reading (includes group separators). */
  DISPLAY = 'display',
  /** Number format optimized for editing (does not include group separators). */
  EDIT = 'edit',
}

/** The locale used for API serialization of {@link Price} objects. */
const PRICE_API_LOCALE = 'en-US';

/** An exported symbol used to expose a tests-only method for clearing the price cache. */
export const CLEAR_PRICE_CACHE_UNSAFE_FOR_TESTS_ONLY = Symbol();

/** A cache of {@link Price} objects used to guarantee that two {@link Price} objects wrapping the same value will
 * always have the same identity (i.e. `new Price(n) === new Price(n)` is `true` for any number `n`).
 *
 * This is intended to provide {@link Price} with value semantics in order to help with change detection in React. */
const priceCache = new Map<number, WeakRef<Price>>();

/** A {@link FinalizationRegistry} for {@link Price} objects, used to purge dangling {@link priceCache} entries in order
 * to save some memory. */
const priceRegistry = new FinalizationRegistry<number>((number) => {
  // Although the spec doesn't guarantee when FinalizationRegistry callbacks are run, it does guarantee that all
  // WeakRefs pointing to a given object will be cleared before FinalizationRegistry callbacks for this object are run.
  // So if the call to WeakRef's deref() method below returns undefined, we know for sure that:
  // - the object that the WeakRef points to isn't reachable anymore
  // - no new object took its place in the Map, or if one did, it's also unreachable
  // Therefore it is then safe to delete the Map entry entirely.
  // Note that FinalizationRegistry callbacks aren't guaranteed to run at all. In our case, if callbacks aren't run,
  // dangling entries might be left in the Map. However, since the Map only stores WeakRefs, it won't impact
  // functionality apart from a slightly increased memory usage, which shouldn't be a problem in typical use-cases.
  // As a last measure, we periodically purge the cache (see setInterval() call below) to try to ensure it never grows
  // uncontrollably.
  // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/FinalizationRegistry
  const maybeRef = priceCache.get(number);
  if (maybeRef && !maybeRef.deref()) {
    priceCache.delete(number);
  }
});

setInterval(
  () => {
    // Go through the cache and purge dangling entries (cleared WeakRefs) in case the FinalizationRegistry's callbacks
    // don't run for some reason, which is allowed by the spec.
    // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/FinalizationRegistry
    // Note that for performance reasons, the cache isn't copied beforehand, so the following code actually modifies the
    // Map while iterating it, which can behave unexpectedly with some datastructures, but is actually safe to do with
    // Map objects.
    // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Iteration_protocols#concurrent_modifications_when_iterating
    for (const [key, ref] of priceCache) {
      if (!ref.deref()) {
        priceCache.delete(key);
      }
    }
  },
  5 * 60 * 1000,
);

/**
 * A type used to wrap, parse, serialize, and format amounts of money (prices).
 *
 * This class doesn't provide access to the underlying amount typed as a number to discourage arithmetic on prices, as
 * floating point arithmetic is inherently lossy with floating point numbers (that JS uses) and money is a subject that
 * tends to make people pretty intolerant of arithmetic errors (go figure).
 *
 * To simplify interoperability with React's change detection, this class provides value object semantics:
 * `new Price(n1) === new Price(n2)` is guaranteed to always evaluate to the same value as `n1 === n2` (i.e. `true` if
 * `n1 === n2` and `false` if `n1 !== n2`).
 *
 * `Price` objects are frozen: trying to set any properties on them will result in a `TypeError` exception being thrown.
 */
export class Price {
  /** The largest price that the backend's graphql API can accept. */
  static readonly MAX_API_PRICE = new Price(99_999_999_999.99);
  /** The smallest price that the backend's graphql API can accept. */
  static readonly MIN_API_PRICE = new Price(-99_999_999_999.99);

  /** A tests-only method for clearing the price cache. DO NOT USE THIS IN PRODUCTION CODE, IT WILL EAT YOUR FACE! */
  static [CLEAR_PRICE_CACHE_UNSAFE_FOR_TESTS_ONLY]() {
    priceCache.clear();
  }

  readonly #value: number;

  /** Fuzzy parse a price formatted by the backend's graphql API.
   * Returns `null` if the provided argument is either `null` or `undefined`.
   * Returns a `Price` object if parsing succeeds, `null` otherwise. */
  static fromApi(value: string | null | undefined): Price | null {
    if (value == null) return null;
    const asNum = new NumberParser(PRICE_API_LOCALE).parseFuzzy(value);
    return asNum == null ? null : new Price(asNum);
  }

  /** Fuzzy parse a price formatted according to the provided locale's standard number format.
   * The price is rounded to two decimals, and clamped to `[Price.MIN_API_PRICE, Price.MAX_API_PRICE]`.
   * Returns a `Price` object if parsing succeeds, `null` otherwise.
   * @see Price.MIN_API_PRICE
   * @see Price.MAX_API_PRICE
   */
  static fromUi(value: string, localeOrTFunction: string | TFunction): Price | null {
    const locale = typeof localeOrTFunction === 'string' ? localeOrTFunction : localeOrTFunction('locale', { ns: 'common' });
    const asNum = new NumberParser(locale).parseFuzzy(value);
    return asNum == null ? null : new Price(+asNum.toFixed(2)).clamp(Price.MIN_API_PRICE, Price.MAX_API_PRICE);
  }

  /** Returns the smallest of the provided `Price` objects, or `null` if none were provided. */
  static min(): null;
  static min(price: Price, ...prices: Price[]): Price;
  static min(...prices: Price[]): Price | null;
  static min(...prices: Price[]): Price | null {
    return prices.length ? new Price(Math.min(...prices.map((p) => p.#value))) : null;
  }

  /** Returns the largest of the provided `Price` objects, or `null` if none were provided. */
  static max(): null;
  static max(price: Price, ...prices: Price[]): Price;
  static max(...prices: Price[]): Price | null;
  static max(...prices: Price[]): Price | null {
    return prices.length ? new Price(Math.max(...prices.map((p) => p.#value))) : null;
  }

  /** Create a `Price` object from either a raw `number` or another `Price` instance.
   * Throws `RangeError` if the provided argument is either `Infinity`, `-Infinity` or `NaN`.
   * If another `Price` instance already exists wrapping the same number, it is returned instead of a new `Price` object
   * being created (allowing for value object semantics).
   * Despite `0` and `-0` being distinct values in JS, `Price` normalizes `-0` to `0` to avoid issues (i.e.
   * `new Price(-0) === new Price(0)`, see comment in class constructor for more information). */
  constructor(value: number | Price) {
    // In IEEE 754 floating point, which JS uses, 0 are -0 are two distinct values. However, they are still considered
    // equal in some circumstances. For example, `0 === -0` is always true in JS. Map objects (which priceCache is based
    // on) also consider 0 and -0 equal (i.e. `new Map([[0, '']]).has(-0) === true`) since they use same-value-zero
    // equality (see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map#key_equality).
    // The Intl.NumberFormat class, however, considers them different, as it will format -0 with a minus sign.
    // This means that 0 and -0 are the only two values which, without special handling, could cause collisions in
    // priceCache, and exhibit non-deterministic behavior as a result (i.e. `[new Price(-0), new Price(0)][1].toJSON()`
    // can return `-0.00` despite the fact that the `toJSON()` method was actually called on `new Price(0)`, but it
    // could also return `0.00` if the cache already contained an entry for 0 beforehand, which is... less than ideal).
    // As a workaround, we chose to always normalize -0 to 0: this eliminates the non-deterministic behavior, and
    // actually makes more sense from a price handling perspective.
    this.#value = value instanceof Price ? value.#value : value || 0; // has to be before all return statements to make TS happy

    if (value instanceof Price) return value;

    // Reject -Infinity, +Infinity, and NaN
    if (!Number.isFinite(value)) throw new RangeError(`A Price object can only wrap finite values, not ${value}`);

    // If the cache already contains a Price object for the provided value, return it
    const existing = priceCache.get(value)?.deref();
    if (existing) return existing;

    // Otherwise, freeze this object to prevent modification, store it in the cache, and register it for post-GC cleanup
    Object.freeze(this);
    priceCache.set(value, new WeakRef(this));
    priceRegistry.register(this, value);
  }

  /** Returns the smallest `Price` amongst this instance and the provided `Price` arguments. */
  min(...prices: Price[]): Price {
    return Price.min(this, ...prices);
  }

  /** Returns the largest `Price` amongst this instance and the provided `Price` arguments. */
  max(...prices: Price[]): Price {
    return Price.max(this, ...prices);
  }

  /** Returns a `Price` object that is no smaller than `min` and no larger than `max`.
   * `min` and `max` can both be either `null` or `undefined`.
   * Throws a `RangeError` if `min` wraps a price that is larger than `max`. */
  clamp(min?: Price | null, max?: Price | null): Price {
    if (min && max && min.#value > max.#value) throw new RangeError('Price.clamp(): min must be less than or equal to max');
    const tmp = min ? this.max(min) : this;
    return max ? tmp.min(max) : tmp;
  }

  /**
   * Format a `Price` object in a format suitable for being displayed in a UI and following the provided locale's
   * standard number format.
   *
   * The locale can either be specified as a string, or as a `t()` translation function from the `i18next` module,
   * provided a translation exists for the `locale` key in the `common` namespace and its value is the locale to use for
   * the current configured language.
   *
   * @see PriceFormat
   */
  format(localeOrTFunction: string | TFunction, format: PriceFormat = PriceFormat.DISPLAY): string {
    const locale = typeof localeOrTFunction === 'string' ? localeOrTFunction : localeOrTFunction('locale', { ns: 'common' });

    switch (format) {
      case PriceFormat.DISPLAY:
        return new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 20 }).format(this.#value);
      case PriceFormat.EDIT:
        return new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 20, useGrouping: false }).format(
          this.#value,
        );
      default:
        _never(
          format,
          () => new RangeError(`Price.format(): format should be one of ${Object.values(PriceFormat).join(', ')}, not ${format}.`),
        );
    }
  }

  /** Serialize a `Price` object in the format expected by the backend's graphql API. */
  toJSON() {
    return new Intl.NumberFormat(PRICE_API_LOCALE, { minimumFractionDigits: 2, maximumFractionDigits: 20, useGrouping: false }).format(
      this.#value,
    );
  }

  /** Just a nice string representation in case a `Price` object ends up "stringified" in an exception, etc. */
  toString() {
    return `Price(${this.#value})`;
  }
}
