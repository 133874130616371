import { PaletteColor, SvgIconProps, SxProps, Theme, Tooltip, TooltipProps, useTheme } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';
import { SystemCssProperties } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { SalesRateResult } from '../../common/types/externalResult';
import { useMemo } from 'react';

export function IssueIndicatorSalesQuantity({
  quantity,
  salesRateResult,
  isFixedQuantity,
}: {
  quantity: number | null;
  salesRateResult: SalesRateResult | null | undefined;
  isFixedQuantity?: boolean;
}) {
  const { t } = useTranslation('jobs');
  const theme = useTheme();
  const quantityTooLow = useMemo(() => {
    return (
      salesRateResult?.canEditRate &&
      salesRateResult?.value?.minimumQuantity != null &&
      salesRateResult?.value?.minimumQuantity > 0 &&
      quantity != null &&
      quantity < salesRateResult.value.minimumQuantity
    );
  }, [quantity, salesRateResult?.canEditRate, salesRateResult?.value?.minimumQuantity]);

  return (
    quantityTooLow &&
    !isFixedQuantity && (
      <IssueIndicator
        description={t('salesRate.minimumQuantity', { quantity: salesRateResult?.value?.minimumQuantity })}
        color={theme.palette.warning.main}
      />
    )
  );
}

export function IssueIndicatorSalesRate({ salesRateResult }: { salesRateResult: SalesRateResult | null | undefined }) {
  const theme = useTheme();
  const { t } = useTranslation('jobs');
  const issue: { description: string; color: PaletteColor } | null = useMemo(() => {
    return salesRateResult?.error
      ? { description: `${salesRateResult.error.code} - ${salesRateResult.error.description}`, color: theme.palette.error }
      : salesRateResult?.value === null
        ? { description: t('salesRate.missingPrice'), color: theme.palette.info }
        : null;
  }, [salesRateResult?.error, salesRateResult?.value, t, theme.palette.error, theme.palette.info]);

  return issue && <IssueIndicator description={issue.description} color={issue?.color.main} />;
}

function IssueIndicator({
  description,
  color,
  componentProps,
}: {
  description: string;
  color: SystemCssProperties<Theme>['color'];
  componentProps?: {
    root?: Partial<Omit<TooltipProps, 'title'>>;
    icon?: Partial<SvgIconProps>;
  };
}) {
  return (
    <Tooltip title={description} placement='top' {...componentProps?.root}>
      <WarningIcon
        {...componentProps?.icon}
        sx={([{ color: color }, componentProps?.icon?.sx ?? null] satisfies SxProps<Theme>[]).flat()}
      />
    </Tooltip>
  );
}
