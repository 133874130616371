/**
 * @generated SignedSource<<36acacc74b67a0f239c60994a70a4616>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryRuleDetailsPage_ConditionsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleFields_BaseQuestions" | "AccessoryRuleFields_CapacitiesFragment" | "AccessoryRuleFields_ClientsFragment" | "AccessoryRuleFields_EquipmentKindsFragment" | "AccessoryRuleFields_NatureOfWorkFragment" | "AccessoryRuleFields_NatureOfWorkSubCategoriesFragment" | "AccessoryRuleFields_SaleKindsFragment">;
  readonly " $fragmentType": "AccessoryRuleDetailsPage_ConditionsFragment";
};
export type AccessoryRuleDetailsPage_ConditionsFragment$key = {
  readonly " $data"?: AccessoryRuleDetailsPage_ConditionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleDetailsPage_ConditionsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryRuleDetailsPage_ConditionsFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_SaleKindsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_CapacitiesFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_EquipmentKindsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_ClientsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_NatureOfWorkFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_NatureOfWorkSubCategoriesFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_BaseQuestions"
    }
  ],
  "type": "AccessoryRule",
  "abstractKey": null
};

(node as any).hash = "9131df444de394279965fd15652ce9ee";

export default node;
