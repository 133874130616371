/**
 * @generated SignedSource<<e31eb1b7a94bd72c3455ac5ec1ed00ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AddressInputGooglePlacesQuery$variables = {
  address: string;
  isToken: boolean;
  placeId: string;
  placeTokenId: string;
  sessionToken: string;
};
export type AddressInputGooglePlacesQuery$data = {
  readonly placeDetailsByPlaceId?: {
    readonly mapUrl: string;
  } | null | undefined;
  readonly placeDetailsByPlaceTokenId?: {
    readonly mapUrl: string;
  } | null | undefined;
};
export type AddressInputGooglePlacesQuery = {
  response: AddressInputGooglePlacesQuery$data;
  variables: AddressInputGooglePlacesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isToken"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "placeId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "placeTokenId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sessionToken"
},
v5 = {
  "kind": "Variable",
  "name": "address",
  "variableName": "address"
},
v6 = {
  "kind": "Variable",
  "name": "sessionToken",
  "variableName": "sessionToken"
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mapUrl",
    "storageKey": null
  }
],
v8 = [
  {
    "condition": "isToken",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          (v5/*: any*/),
          {
            "kind": "Variable",
            "name": "placeId",
            "variableName": "placeId"
          },
          (v6/*: any*/)
        ],
        "concreteType": "PlaceDetailsResult",
        "kind": "LinkedField",
        "name": "placeDetailsByPlaceId",
        "plural": false,
        "selections": (v7/*: any*/),
        "storageKey": null
      }
    ]
  },
  {
    "condition": "isToken",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          (v5/*: any*/),
          {
            "kind": "Variable",
            "name": "placeTokenId",
            "variableName": "placeTokenId"
          },
          (v6/*: any*/)
        ],
        "concreteType": "PlaceDetailsResult",
        "kind": "LinkedField",
        "name": "placeDetailsByPlaceTokenId",
        "plural": false,
        "selections": (v7/*: any*/),
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddressInputGooglePlacesQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddressInputGooglePlacesQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "7ff084ba00a30cfc0457439a96c582d4",
    "id": null,
    "metadata": {},
    "name": "AddressInputGooglePlacesQuery",
    "operationKind": "query",
    "text": "query AddressInputGooglePlacesQuery(\n  $placeId: String!\n  $placeTokenId: ID!\n  $sessionToken: String!\n  $isToken: Boolean!\n  $address: String!\n) {\n  placeDetailsByPlaceId(placeId: $placeId, sessionToken: $sessionToken, address: $address) @skip(if: $isToken) {\n    mapUrl\n  }\n  placeDetailsByPlaceTokenId(placeTokenId: $placeTokenId, sessionToken: $sessionToken, address: $address) @include(if: $isToken) {\n    mapUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "c36a5f8aaf2a516a0e97ac1927acee8a";

export default node;
