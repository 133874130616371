/**
 * @generated SignedSource<<0b0c4d0c29820edabb21d9cc38489832>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoomConfigurationSnapshotLabelResolver$data = {
  readonly boomLength: string;
  readonly counterweight: string;
  readonly jibLength: string;
  readonly sequence: number;
  readonly " $fragmentType": "BoomConfigurationSnapshotLabelResolver";
};
export type BoomConfigurationSnapshotLabelResolver$key = {
  readonly " $data"?: BoomConfigurationSnapshotLabelResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoomConfigurationSnapshotLabelResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BoomConfigurationSnapshotLabelResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boomLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jibLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "counterweight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sequence",
      "storageKey": null
    }
  ],
  "type": "BoomConfigurationSnapshot",
  "abstractKey": null
};

(node as any).hash = "8eb0dba399a20d9dfb187b849f6072f0";

export default node;
