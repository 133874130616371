import {
  Context,
  createContext,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useSyncExternalStore,
} from 'react';
import { PartialFormValues } from './formUtils';
import { merge } from 'ts-deepmerge';
import { _throw } from './_throw';
import { LazyMap } from './lazyMap';
import { createQuickMap, QuickMap } from './quickMap';
import { usePreviousRenderValue } from '../hooks/usePreviousValue';
import { defaultLogger, Logger } from './logging';
import { sequenceEqual } from './arrayUtils';
import { useRefSync } from '../hooks/useRefSync';

const loggerStore = new Logger(defaultLogger, 'Form.Store', () => new Date().toISOString());
const loggerValidation = new Logger(defaultLogger, 'Form.Validation', () => new Date().toISOString());
const loggerMapping = new Logger(defaultLogger, 'Form.Mapping', () => new Date().toISOString());

// Ignore default system log level since the logger is very spammy and can drastically slow down the application.
loggerStore.minLevel = 'info';
loggerValidation.minLevel = 'info';
loggerMapping.minLevel = 'info';

/**
 * An alias for the Symbol() method that can be patched to include custom descriptions for all symbols.
 */
function createETag(description?: string | number): ETag {
  return Symbol(description);
}
export type ETag = symbol;

/**
 * A non-exported unique symbol type used to type-brand {@link FieldKey} objects, which contain no properties to speak
 * of, and as such would be assignable to each other regardless of their type parameter without branding.
 * @internal
 * @see FieldKey
 * @see createFieldKey
 */
declare const FIELD_KEY_BRAND_PROP: unique symbol;

/**
 * An opaque object identifying a form field, specifying its value type.
 * @typeParam FieldValue - The type of the field value.
 * @see createFieldKey
 * @see useField
 */
export interface FieldKey<FieldValue> {
  readonly description?: string | number;
  readonly [FIELD_KEY_BRAND_PROP]?: FieldValue;
}

/**
 * Creates a field key object with a field value of type `FieldValue`.
 * @param description - An optional description for the field. Used for debugging purposes.
 * @template FieldValue - The type of the field value.
 * @returns {FieldKey<FieldValue>} - The created field key object.
 */
export function createFieldKey<FieldValue>(description?: string | number): FieldKey<FieldValue> {
  return Object.freeze({ description });
}

/**
 * Represents the state of a field of type `FieldValue`.
 * @template FieldValue - The type of the field value.
 */
export interface FieldState<FieldValue, FormMappings> {
  readonly value: FieldValue;
  readonly isDirty: boolean;
  readonly eTag: ETag;
  readonly mappers: FieldMappings<FieldValue, FormMappings>;
  readonly validations: FieldValidations<FieldValue>;
  readonly errors: FieldErrors;
}

/**
 * Creates a field state object with the given initial value.
 * @template FieldValue - The type of the field value.
 * @param {FieldInitialValue<FieldValue>} initialValue - The initial value of the field.
 * @return {FieldState<FieldValue>} The newly created field state object.
 */
function createFieldState<FieldValue, FormMappings>(initialValue: FieldInitialValue<FieldValue>): FieldState<FieldValue, FormMappings> {
  return {
    value: typeof initialValue === 'function' ? (initialValue as () => FieldValue)() : initialValue,
    isDirty: false,
    eTag: createETag(),
    mappers: new Map(),
    validations: new Map(),
    errors: {},
  };
}

/** A field mapper function. Transforms a field's value into a partial mapped output type.
 *
 * Due to how TypeScript is designed, you will always be able to provide a superset of Output. This means that
 * for an Output of `{ foo: string }`, you can provide `{ foo: string, bar: string }` but not `{ foo: number }`.
 * Since every field in the Output is optional, `{}` also satisfies the type of Output.
 *
 * As a result, you can expect code completion and type validation, but not detection of superfluous properties.
 * */
export interface FieldMapper<FieldValue, Output> {
  (value: FieldValue, state: { readonly isDirty: boolean }): PartialFormValues<Output>;
}

/**
 * A field mapping rule.
 */
export interface FieldMapping<FieldValue, FormMappings, K extends keyof FormMappings> {
  /**
   * The name of the mapping that will trigger this mapper.
   */
  readonly mapping: K;

  /**
   * A function that converts the FieldValue into a part of the return value for the mapping.
   */
  readonly mapper: FieldMapper<FieldValue, FormMappings[K]>;

  /**
   * Indicates whether the mapping is keptalive even though no field with this mapping is currently mounted.
   */
  readonly keepalive: boolean;
}

export type MappingUniqueId = unknown;

/** A read-only record of field mappers indexed by id. */
export type FieldMappings<FieldValue, FormMappings> = ReadonlyMap<
  MappingUniqueId,
  FieldMapping<FieldValue, FormMappings, keyof FormMappings>
>;

/**
 * The result of a {@link FieldValidator} call. `undefined` or `true` indicates a validation success, anything else
 * indicates a validation error.
 */
export type FieldValidatorResult = undefined | boolean | string | [string, Record<string, unknown>];

/**
 * A field validator function. If the function returns `undefined` or `true`, then the field is considered valid. If it
 * returns anything else, then the field is considered invalid.
 */
export interface FieldValidator<FieldValue> {
  (value: FieldValue, state: { isDirty: boolean }): FieldValidatorResult;
}

/** A field validation rule. */
interface FieldValidation<FieldValue> {
  readonly name: string;
  readonly families: string[];
  readonly validator: FieldValidator<FieldValue>;
}

export type ValidatorUniqueId = unknown;

/** A read-only record of field validations indexed by id. */
export type FieldValidations<FieldValue> = ReadonlyMap<ValidatorUniqueId, FieldValidation<FieldValue>>;

/** A read-only record of field validation errors indexed by validation name. */
export type FieldErrors = Readonly<Record<string, FieldError[]>>;

/** A field validation error. */
export interface FieldError {
  readonly validationId: ValidatorUniqueId;
  readonly families: string[];
  readonly message?: [string, Record<string, unknown>];
}

/**
 * The initial value for a field.
 * It can be either a static value of type FieldValue or a function that returns a value of type FieldValue.
 * @template FieldValue - The type of the field value.
 */
export type FieldInitialValue<FieldValue> = FieldValue | (() => FieldValue);

/** A multidimensional filtering view of form errors. */
export interface FormErrors {
  readonly byFamily: QuickMap<
    string,
    {
      readonly byField: QuickMap<FieldKey<unknown>, { readonly byName: QuickMap<string, FieldError> }>;
      readonly byName: QuickMap<string, { readonly byField: QuickMap<FieldKey<unknown>, FieldError> }>;
    }
  >;
  readonly byField: QuickMap<
    FieldKey<unknown>,
    {
      readonly byFamily: QuickMap<string, { readonly byName: QuickMap<string, FieldError> }>;
      readonly byName: QuickMap<string, { readonly byFamily: QuickMap<string, FieldError> }>;
    }
  >;
  readonly byName: QuickMap<
    string,
    {
      readonly byFamily: QuickMap<string, { readonly byField: QuickMap<FieldKey<unknown>, FieldError> }>;
      readonly byField: QuickMap<FieldKey<unknown>, { readonly byFamily: QuickMap<string, FieldError> }>;
    }
  >;
}

/** Create a {@link FormErrors} object from a map of field states. */
function createFormErrors<FormMappings>(states: ReadonlyMap<FieldKey<unknown>, FieldState<unknown, FormMappings>>): FormErrors {
  interface AnnotatedError {
    readonly error: FieldError;
    readonly name: string;
    readonly fieldKey: FieldKey<unknown>;
  }

  const annotatedErrors = (() => {
    let cachedErrors: AnnotatedError[];
    return () =>
      (cachedErrors ||= [...states]
        .map(([fieldKey, state]) =>
          Object.entries(state.errors).map(([name, errors]) => errors.map((error) => ({ error, name, fieldKey }))),
        )
        .flat(2));
  })();

  const filterErrors = ({ fieldKey, family, name }: { fieldKey?: FieldKey<unknown>; family?: string; name?: string }) =>
    annotatedErrors().filter(
      ({ error, name: errorName, fieldKey: errorFieldKey }: AnnotatedError) =>
        (fieldKey == null || fieldKey === errorFieldKey) &&
        (family == null || error.families.includes(family)) &&
        (name == null || name === errorName),
    );

  const createErrorsMap = <K, V>(
    filter: { fieldKey?: FieldKey<unknown>; family?: string; name?: string },
    getKeys: (error: AnnotatedError) => K[],
    getValue: (key: K) => V,
  ): QuickMap<K, V> => createQuickMap(new LazyMap(() => filterErrors(filter).flatMap(getKeys), getValue));

  const getFamilyKeys = (error: AnnotatedError) => error.error.families;
  const getFieldKeys = (error: AnnotatedError) => [error.fieldKey];
  const getNameKeys = (error: AnnotatedError) => [error.name];

  return {
    byFamily: createErrorsMap({}, getFamilyKeys, (family) => ({
      byField: createErrorsMap({ family }, getFieldKeys, (fieldKey) => ({
        byName: createErrorsMap(
          { family, fieldKey },
          getNameKeys,
          (name) => filterErrors({ family, fieldKey, name }).findLast(() => true)!.error,
        ),
      })),
      byName: createErrorsMap({ family }, getNameKeys, (name) => ({
        byField: createErrorsMap(
          { family, name },
          getFieldKeys,
          (fieldKey) => filterErrors({ family, name, fieldKey }).findLast(() => true)!.error,
        ),
      })),
    })),
    byField: createErrorsMap({}, getFieldKeys, (fieldKey) => ({
      byFamily: createErrorsMap({ fieldKey }, getFamilyKeys, (family) => ({
        byName: createErrorsMap(
          { fieldKey, family },
          getNameKeys,
          (name) => filterErrors({ fieldKey, family, name }).findLast(() => true)!.error,
        ),
      })),
      byName: createErrorsMap({ fieldKey }, getNameKeys, (name) => ({
        byFamily: createErrorsMap(
          { fieldKey, name },
          getFamilyKeys,
          (family) => filterErrors({ fieldKey, name, family }).findLast(() => true)!.error,
        ),
      })),
    })),
    byName: createErrorsMap({}, getNameKeys, (name) => ({
      byFamily: createErrorsMap({ name }, getFamilyKeys, (family) => ({
        byField: createErrorsMap(
          { name, family },
          getFieldKeys,
          (fieldKey) => filterErrors({ name, family, fieldKey }).findLast(() => true)!.error,
        ),
      })),
      byField: createErrorsMap({ name }, getFieldKeys, (fieldKey) => ({
        byFamily: createErrorsMap(
          { name, fieldKey },
          getFamilyKeys,
          (family) => filterErrors({ name, fieldKey, family }).findLast(() => true)!.error,
        ),
      })),
    })),
  };
}

/**
 * A form field store allowing to get and set field states by key, test for key presence, map and validate field values,
 * access errors, subscribe to changes, etc.
 * @internal
 * @see useFormStore
 * @see useField
 */
interface FormStore<FormMappings> {
  /**
   * Register a store-wide change listener.
   * @param onStoreChange - will be called every time the store is changed
   * @returns a cleanup function which should be called to unregister the listener
   */
  readonly subscribe: (onStoreChange: () => void) => () => void;
  /**
   * Get the field state for the provided field key, creating the field entry on-the-fly with the provided initial value
   * if it doesn't exist.
   * @typeParam - FieldValue the type of state value for the provided `key`
   * @param key - a {@link FieldKey}
   * @param initialValue - a {@link FieldInitialValue}
   * @returns the current field state for the provided `key`
   */
  readonly getFieldStateOrCreate: <FieldValue>(
    key: FieldKey<FieldValue>,
    initialValue: FieldInitialValue<FieldValue>,
  ) => FieldState<FieldValue, FormMappings>;
  /**
   * Get the field state for the provided field key, returning `null` if it doesn't exist.
   * @typeParam - FieldValue the type of state value for the provided `key`
   * @param key - a {@link FieldKey}
   * @returns the current field state for the provided `key`, or `null` if it doesn't exist
   */
  readonly getFieldStateOrNull: <FieldValue>(key: FieldKey<FieldValue>) => FieldState<FieldValue, FormMappings> | null;
  /**
   * Update field state for the provided field `key`.
   * @typeParam - FieldValue the type of state value for the provided `key`
   * @param key - a {@link FieldKey}
   * @param state - either a new state value, or an updater function which takes the current state value as argument and
   *   should return a new state value
   */
  readonly setFieldState: <FieldValue>(key: FieldKey<FieldValue>, state: SetStateAction<FieldState<FieldValue, FormMappings>>) => void;
  /**
   * Map all fields according to the provided mapping.
   *
   * @param {string} mappingName - The name of the mapping to be used.
   * @returns {Object} - The output object with mapped fields based on the provided mapping.
   */
  readonly mapAll: <MappingName extends keyof FormMappings>(mappingName: MappingName) => FormMappings[MappingName];
  /**
   * Map dirty fields according to the provided mapping.
   *
   * @param {string} mappingName - The name of the mapping to be used.
   * @returns {Object} - The output object with mapped fields based on the provided mapping.
   */
  readonly mapDirty: <MappingName extends keyof FormMappings>(mappingName: MappingName) => FormMappings[MappingName];
  /**
   * Takes a snapshot of field states, including their validation rules, but <i>excluding pending state updates</i>, and
   * returns a function that takes a validation family as an argument, validates all fields in the snapshot according to
   * the provided family, updating their `errors` object accordingly, and returns a boolean value indicating whether all
   * fields in the snapshot are valid according to the validation rules of that family.
   *
   * @function getValidate
   * @returns {Function} - A function that takes a validation family as an argument, validates all fields in the
   *   snapshot according to the provided family, updating their `errors` object accordingly, and returns a boolean
   *   value indicating whether all fields in the snapshot are valid according to the validation rules of that family.
   */
  readonly getValidate: () => (family: string) => boolean;
  /**
   * Returns a `ReadonlyMap` indexing all field errors present in the store by field key.
   * Fields with no validation errors will be absent from the returned map.
   *
   * @returns {FormErrors} A `ReadonlyMap` of field errors indexed by field key.
   */
  readonly errors: () => FormErrors;

  /**
   * Returns true when any field of the form is considered dirty, false otherwise
   * @see FieldState
   */
  readonly isFormDirty: () => boolean;

  /**
   * Returns a unique eTag that changes when a field value update transaction is done.
   * @see FieldState
   */
  readonly eTag: () => ETag;

  /**
   * Dumps the internal form state into the console for diagnosis purposes.
   */
  readonly logDebug: (marker: string) => void;
}

/**
 * A custom hook which creates and returns a new {@link FormStore}.
 * @internal
 */
function useFormStore<FormMappings>(): FormStore<FormMappings> {
  type StateUpdate<FieldValue> = readonly [FieldKey<FieldValue>, SetStateAction<FieldState<FieldValue, FormMappings>>];
  type StateUpdateChanges = Partial<{
    -readonly [K in keyof FieldState<unknown, FormMappings>]: boolean;
  }>;

  // Immutable map of currently visible field states
  const statesRef = useRef<ReadonlyMap<FieldKey<unknown>, FieldState<unknown, FormMappings>>>(new Map());
  // Immutable global errors object containing errors from all fields in the store
  const errorsRef = useRef<FormErrors>(createFormErrors(statesRef.current));
  // Current form eTag
  const eTagRef = useRef<ETag>(createETag());
  // Current form dirty state
  const dirtyRef = useRef<boolean>(false);
  // Immutable array of subscribe() change listeners
  const listenersRef = useRef<readonly (() => void)[]>([]);
  // Cache of validation function to minimize getValidate instances
  const validationFnRef = useRef<(family: string) => boolean>(() => false);

  const setFieldStates = useCallback(<FieldValue,>(updates: readonly StateUpdate<FieldValue>[]) => {
    if (!updates.length) {
      loggerStore.debug('Skipping field states update. No fields to update.');
      return;
    }

    const changed: StateUpdateChanges = {};
    let callListeners = false; // Call change listeners after updating existing fields

    statesRef.current = updates.reduce((newStates, [key, updater]) => {
      const prevState = newStates.get(key);
      const newStateOrUpdater = updater as SetStateAction<FieldState<unknown, FormMappings>>;

      const newState =
        typeof newStateOrUpdater === 'function'
          ? newStateOrUpdater(
              prevState ??
                _throw(new Error('FormStore: cannot update field state with updater function without creating field state first')),
            )
          : newStateOrUpdater;

      // Aggregate partial field state change list into a change list for the entire update
      changed.value ||= !Object.is(prevState?.value, newState.value); // Handle -0, +0, and NaN correctly
      changed.isDirty ||= prevState?.isDirty !== newState.isDirty;
      changed.eTag ||= prevState?.eTag !== newState.eTag;
      changed.validations ||= !sequenceEqual(prevState?.validations ?? {}, newState.validations);
      changed.mappers ||= !sequenceEqual(prevState?.mappers ?? {}, newState.mappers);
      changed.errors ||= !sequenceEqual(prevState?.errors ?? {}, newState.errors);
      callListeners ||= prevState != null;

      loggerStore.debug(prevState ? 'Updating' : 'Initializing', key, 'to', newState);
      newStates.set(key, newState);

      return newStates;
    }, new Map(statesRef.current));

    if (changed.errors) {
      loggerValidation.debug('Store updates added new errors to the state. Recalculating errors.');
      errorsRef.current = createFormErrors(statesRef.current);
    }

    if (changed.value || changed.isDirty || changed.validations) {
      loggerValidation.debug('Store updates invalidated the validation function. Recreating the validation function.');

      // Capturing the state at time of update into the validation function to ensure callers will get a consistent
      // behavior as they would from any callback (useCallback) in React.
      const states = statesRef.current;
      validationFnRef.current = (family: string): boolean => {
        loggerValidation.log('Validation requested for', family);

        let isValid = true;

        setFieldStates(
          [...states].map(([key, state]) => {
            // TODO handle exceptions to prevent a single field from breaking validation for all fields
            const { success, state: newState } = runFieldValidations({ families: [family] }, state);
            if (!success) isValid = false;
            return [key, (prev: FieldState<unknown, FormMappings>) => ({ ...prev, errors: newState.errors })];
          }),
        );

        return isValid;
      };
    }

    if (changed.eTag) {
      loggerStore.debug('Updating eTag', eTagRef.current);
      eTagRef.current = createETag();
    }

    if (changed.isDirty) {
      loggerStore.debug('Updating dirty state', dirtyRef.current);
      dirtyRef.current = [...statesRef.current.values()].some((f) => f.isDirty);
    }

    if (callListeners) {
      loggerStore.debug('Notifying', listenersRef.current.length, 'listeners');
      for (const listener of listenersRef.current) {
        listener();
      }
    }
  }, []);

  const mapFields = useCallback(
    <MappingName extends keyof FormMappings>(
      mappingName: MappingName,
      predicate: (field: FieldState<unknown, FormMappings>) => unknown,
    ): FormMappings[MappingName] =>
      merge.withOptions(
        { mergeArrays: false },
        ...([...statesRef.current.values()]
          .filter((field) => predicate(field))
          .flatMap((field) =>
            // Run all registered mappers for a specified mappings, including duplicate registrations.
            [...field.mappers.values()].filter((m) => m.mapping === mappingName).map((m) => [field, m.mapper] as const),
          )
          .map(([field, mapper]) => {
            loggerMapping.log('Mapping field', field, 'for mapping', mappingName, 'with mapper', mapper);
            return mapper(field.value, { isDirty: field.isDirty });
          }) as Record<string, unknown>[]),
      ) as FormMappings[MappingName], //< TODO this cast isn't actually provable to be valid, remove it?
    [],
  );

  return useMemo(
    () =>
      Object.freeze<FormStore<FormMappings>>({
        subscribe: (onStoreChange) => {
          const listenerIndex = listenersRef.current.length;
          loggerStore.debug('Registering listener at index', listenerIndex);
          // Wrap the listener to make sure all registered listeners have different identities. This is necessary
          // because the returned unregister callback looks up listeners by identity, which would work incorrectly with
          // duplicate listeners.
          const listener = onStoreChange.bind(undefined);
          listenersRef.current = [...listenersRef.current, listener];
          return () => {
            loggerStore.debug('Unregistering listener', listenerIndex);
            listenersRef.current = listenersRef.current.filter((l) => l !== listener);
          };
        },

        getFieldStateOrCreate: <FieldValue,>(
          key: FieldKey<FieldValue>,
          initialValue: FieldInitialValue<FieldValue>,
        ): FieldState<FieldValue, FormMappings> => {
          if (!statesRef.current.has(key)) {
            const state = createFieldState(initialValue);
            setFieldStates([[key, state]]);
            return state;
          }
          return statesRef.current.get(key) as FieldState<FieldValue, FormMappings>;
        },

        getFieldStateOrNull: <FieldValue,>(key: FieldKey<FieldValue>): FieldState<FieldValue, FormMappings> | null => {
          return (statesRef.current.get(key) ?? null) as FieldState<FieldValue, FormMappings> | null;
        },

        setFieldState: <FieldValue,>(key: FieldKey<FieldValue>, state: SetStateAction<FieldState<FieldValue, FormMappings>>): void => {
          setFieldStates([[key, state]]);
        },

        mapAll: <MappingName extends keyof FormMappings>(mappingName: MappingName): FormMappings[MappingName] => {
          loggerMapping.info('Mapping all fields for', mappingName);
          return mapFields(mappingName, () => true);
        },

        mapDirty: <MappingName extends keyof FormMappings>(mappingName: MappingName): FormMappings[MappingName] => {
          loggerMapping.info('Mapping dirty fields for', mappingName);
          return mapFields(mappingName, (field) => field.isDirty);
        },

        isFormDirty: () => dirtyRef.current,
        eTag: () => eTagRef.current,
        errors: () => errorsRef.current,
        getValidate: () => validationFnRef.current,

        logDebug: (marker) => {
          loggerStore.group(marker);
          loggerStore.info('state', statesRef.current);
          loggerStore.info('errors', errorsRef.current);
          loggerStore.info('listeners', listenersRef.current);
          loggerStore.groupEnd();
        },
      }),
    [setFieldStates, mapFields],
  );
}

/** Represents a single form "instance" containing field states (values, validation rules, errors, mappers, etc.) */
export type FormContext<FormMappings> = Context<FormStore<FormMappings> | null>;

/** Create a new {@link FormContext}. */
export function createFormContext<FormMappings>(): FormContext<FormMappings> {
  return createContext<FormStore<FormMappings> | null>(null);
}

/**
 * A component used to provide a new {@link FormStore} through a {@link FormContext}.
 *
 * @param {PropsWithChildren} props - The props object containing the following properties:
 *   - {FormContext} context - A {@link FormContext} object to which bind a new {@link FormStore}.
 *   - {JSX children} children - The JSX children to render.
 *
 * @see createFormContext
 * @see useField
 */
export function FormProvider<FormMappings>({
  context,
  children,
}: PropsWithChildren<{
  context: FormContext<FormMappings>;
}>) {
  const store = useFormStore<FormMappings>();
  return <context.Provider value={store}>{children}</context.Provider>;
}

/**
 * Retrieves the form store from the provided {@link FormContext}.
 *
 * @internal
 * @param ctx - The FormContext from which to retrieve the form store.
 * @throws Throws an error if the form store is not found.
 * @returns The form store retrieved from the FormContext.
 */
function useFormStoreContext<FormMappings>(ctx: FormContext<FormMappings>): FormStore<FormMappings> {
  const store = useContext(ctx);
  if (!store) {
    throw new Error('useFormStoreContext() can only be called from components with a <FormProvider> parent');
  }
  return store;
}

export function useFormLogDebug<FormMappings>(ctx: FormContext<FormMappings>): (marker: string) => void {
  return useFormStoreContext(ctx).logDebug;
}

/**
 * Checks if any field in a form context is dirty.
 *
 * The component calling this hook will be re-rendered whenever any field's dirtiness changes.
 *
 * @param ctx - The form context.
 * @return Whether the form (any field) is dirty or not.
 */
export function useFormIsDirty<FormMappings>(ctx: FormContext<FormMappings>): boolean {
  const store = useFormStoreContext(ctx);
  return useSyncExternalStore(store.subscribe, () => store.isFormDirty());
}

/**
 * Returns a unique value for each field value change transaction.
 *
 * The component calling this hook will be re-rendered whenever any field's dirtiness changes.
 *
 * @param ctx - The form context.
 * @return Whether the form (any field) is dirty or not.
 */
export function useFormEtag<FormMappings>(ctx: FormContext<FormMappings>): ETag {
  const store = useFormStoreContext(ctx);
  return useSyncExternalStore(store.subscribe, () => store.eTag());
}

/** Return functions allowing to map the whole form at once according to the provided mapping. */
export function useFormMappings<FormMappings>(ctx: FormContext<FormMappings>): {
  readonly mapAll: <MappingName extends keyof FormMappings>(mappingName: MappingName) => FormMappings[MappingName];
  readonly mapDirty: <MappingName extends keyof FormMappings>(mappingName: MappingName) => FormMappings[MappingName];
} {
  const { mapAll, mapDirty } = useFormStoreContext(ctx);
  return useMemo(() => ({ mapAll, mapDirty }), [mapAll, mapDirty]);
}

/** Return a function allowing to validate the whole form at once according to a specific validation family. */
export function useFormValidate<FormMappings>(ctx: FormContext<FormMappings>): (family: string) => boolean {
  const store = useFormStoreContext(ctx);
  return useSyncExternalStore(store.subscribe, () => store.getValidate());
}

/**
 * Retrieves the form errors from the provided form context.
 *
 * The component calling this hook will be re-rendered whenever the form errors change.
 *
 * @param ctx - The form context object.
 *
 * @returns The form errors.
 */
export function useFormErrors<FormMappings>(ctx: FormContext<FormMappings>): FormErrors {
  const store = useFormStoreContext(ctx);
  return useSyncExternalStore(store.subscribe, () => store.errors());
}

/**
 * Represents the result of running field validations.
 *
 * @template FieldValue - The type of the field value being validated.
 * @see runFieldValidations
 */
interface FieldValidationResult<FieldValue, FormMappings> {
  success: boolean;
  state: FieldState<FieldValue, FormMappings>;
}

/**
 * Runs field validations for the given family and field state.
 *
 * @param filter - Only the validations matching the given criteria will be run.
 * @param state - The state of the field.
 * @template FieldValue - The type of the field value.
 * @return The updated field state after running validations, and a success flag.
 */
function runFieldValidations<FieldValue, FormMappings>(
  filter: Partial<FieldValidation<FieldValue>>,
  state: FieldState<FieldValue, FormMappings>,
): FieldValidationResult<FieldValue, FormMappings> {
  const errors = { ...state.errors };
  let success = true;
  let errorsChanged = false;

  loggerValidation.log('Validating', filter);

  for (const [validationId, { name, families, validator }] of state.validations) {
    if (
      (filter.name == null || filter.name === name) &&
      (filter.families == null || filter.families.every((f) => families.includes(f))) &&
      (filter.validator == null || filter.validator === validator)
    ) {
      const validationResult = validator(state.value, state);
      if (validationResult === undefined || validationResult === true) {
        // Validation succeeded
        if (errors[name]?.some((error) => error.validationId === validationId)) {
          // There was an error before, remove it
          if (errors[name].length === 1) {
            // It was the only error, remove the `name` property entirely
            delete errors[name];
          } else {
            // It wasn't the only error, remove it but keep the others
            errors[name] = errors[name].filter((error) => error.validationId !== validationId);
          }
          errorsChanged = true;
        }
      } else {
        // Validation failed
        errors[name] = [
          // Filter out previous error if found
          ...(errors[name] || []).filter((error) => error.validationId !== validationId),
          // Append new error
          {
            validationId,
            families,
            message:
              validationResult === false ? undefined : typeof validationResult === 'string' ? [validationResult, {}] : validationResult,
          },
        ];
        success = false;
        errorsChanged = true;
      }
    }
  }

  return {
    success,
    state: errorsChanged ? { ...state, errors: Object.freeze(errors) } : state,
  };
}

/**
 * Returns the current value for the specified {@link FieldKey} in the provided {@link FormContext}, initializing the
 * field value with `initialValue` if the field doesn't exist in the store yet.
 *
 * The component calling this hook will be re-rendered whenever the field value changes.
 *
 * @param ctx - The form context.
 * @param key - The key of the field.
 * @param initialValue - The initial value of the field.
 * @return The field's current value.
 */
export function useFieldValue<FormMappings, FieldValue, InitialFieldValue extends FieldValue>(
  ctx: FormContext<FormMappings>,
  key: FieldKey<FieldValue>,
  initialValue: FieldInitialValue<InitialFieldValue>,
): FieldValue {
  const store = useFormStoreContext(ctx);
  return useSyncExternalStore(store.subscribe, () => store.getFieldStateOrCreate(key, initialValue).value);
}

/**
 * A function that sets the state of a field to a new value.
 */
export type SetValueFn<T> = (newValue: SetStateAction<T>, dirty?: false) => void;

/**
 * Returns a `[value, setter]` tuple for the provided `FormContext` and `FieldKey`, initializing the field
 * value with `initialValue` if the field doesn't exist in the store yet.
 *
 * The component calling this hook will be re-rendered whenever the field value changes.
 *
 * @param ctx - The form context.
 * @param key - The key of the field.
 * @param initialValue - The initial value of the field.
 * @return A tuple containing the field value and a setter function.
 */
export function useField<FormMappings, FieldValue, InitialFieldValue extends FieldValue>(
  ctx: FormContext<FormMappings>,
  key: FieldKey<FieldValue>,
  initialValue: FieldInitialValue<InitialFieldValue>,
): [FieldValue, SetValueFn<FieldValue>] {
  const store = useFormStoreContext(ctx);
  const value = useSyncExternalStore(store.subscribe, () => store.getFieldStateOrCreate(key, initialValue).value);

  const setValue = useCallback<SetValueFn<FieldValue>>(
    (newValue, dirty) => {
      store.setFieldState(
        key,
        (state) =>
          runFieldValidations(
            { families: ['change'] },
            {
              ...state,
              eTag: createETag(),
              value: typeof newValue === 'function' ? (newValue as (v: FieldValue) => FieldValue)(state.value as FieldValue) : newValue,
              isDirty: dirty === undefined ? true : state.isDirty,
            },
          ).state,
      );
    },
    [key, store],
  );

  return [value, setValue];
}

/**
 * Returns a function that allows to reset a field's state (excluding mappers and validators which are managed by specific hooks and should be reset using these hooks).
 * @param ctx - The form context.
 * @param key - The key of the field to reset.
 */
export function useResetField<FormMappings, FieldValue>(
  ctx: FormContext<FormMappings>,
  key: FieldKey<FieldValue>,
): (newInitialValue: FieldInitialValue<FieldValue>) => void {
  const store = useFormStoreContext(ctx);
  return useCallback(
    (newInitialValue) => {
      store.setFieldState(key, ({ mappers, validations }) => ({
        ...createFieldState(newInitialValue),
        mappers,
        validations,
      }));
    },
    [key, store],
  );
}

/**
 * Checks if a field exists in the form context store.
 *
 * The component calling this hook will be re-rendered once the field is created.
 *
 * @template FieldValue - The type of the field value.
 * @param ctx - The form context.
 * @param key - The key of the field to check.
 * @returns True if the field exists, false otherwise.
 */
export function useFieldExists<FormMappings, FieldValue>(ctx: FormContext<FormMappings>, key: FieldKey<FieldValue>): boolean {
  const store = useFormStoreContext(ctx);
  return useSyncExternalStore(store.subscribe, () => store.getFieldStateOrNull(key) != null);
}

/**
 * Checks if a specific field in a form context is dirty.
 *
 * The component calling this hook will be re-rendered whenever the field's dirtiness changes.
 *
 * @param ctx - The form context.
 * @param key - The key of the field to check.
 * @return Whether the field is dirty or not.
 */
export function useFieldIsDirty<FormMappings>(ctx: FormContext<FormMappings>, key: FieldKey<unknown>): boolean {
  const store = useFormStoreContext(ctx);
  return useSyncExternalStore(store.subscribe, () => store.getFieldStateOrNull(key)?.isDirty || false);
}

/**
 * This hook returns another hook which can be used to add validation rules to a field.
 *
 * You should prefix the name of the variable that holds the resulting hook with
 * useValidation to benefit from additional static code analysis and quick fixes
 * for the list of dependencies.
 *
 * @param ctx - The form context.
 * @param key - The key of the field to validate.
 * @returns A hook to add validation rules to the field.
 */
export function useFieldValidation<FieldValue, FormMappings>(
  ctx: FormContext<FormMappings>,
  key: FieldKey<FieldValue>,
): (validatorFn: FieldValidator<FieldValue>, deps: unknown[], nameSpec: `${string}:${string}`, opts?: { revalidate?: false }) => void {
  const store = useFormStoreContext(ctx);

  return useCallback(
    function useFieldValidationWrapped(validatorFn, deps, nameSpec, opts = {}) {
      const optsRef = useRef(opts);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      const validator = useCallback(validatorFn, deps);

      const previousValidatorRef = useRefSync(usePreviousRenderValue(validator));
      const previousNameSpecRef = useRefSync(usePreviousRenderValue(nameSpec));

      useEffect(() => {
        const revalidate = optsRef.current.revalidate ?? true;

        if (previousValidatorRef.current !== validator || previousNameSpecRef.current !== nameSpec) {
          const nameParts = nameSpec.split(':');
          if (nameParts.length < 2) {
            throw new Error('validator name must be of the form "families:name"');
          }

          loggerValidation.debug('Registering validation rule for', nameSpec, key, optsRef.current);

          const families = nameParts[0].split(',');
          const name = nameParts.slice(1).join(':');

          store.setFieldState(key, (state) => {
            const validations: FieldValidations<FieldValue> = new Map([
              ...state.validations,
              [validator, Object.freeze({ name, families, validator })],
            ]);
            let newState = {
              ...state,
              validations,
            };

            const previous = state.validations.get(validator);
            if (previous && previous.name !== name && previous.families.includes('change') && revalidate) {
              newState = runFieldValidations({ name: previous.name, families: ['change'] }, newState).state;
            }

            if (families.includes('change') && revalidate) {
              newState = runFieldValidations({ name: name, families: ['change'] }, newState).state;
            }

            return newState;
          });
        }

        return () => {
          store.setFieldState(key, (state) => {
            loggerValidation.debug('Unregistering validation rule');

            // HACK: Check if we still have a validation for the current id when the setter triggers.
            //  It is often not the case during hot-reloads or with strict mode which will attempt to remove rules twice.
            const validation = state.validations.get(validator);
            if (!validation) {
              loggerValidation.log('Skipping. Rule is not registered.');
              return state;
            }

            // Extract name and families of the current validation rule, and build a new validations object excluding
            // the current validation rule.
            const { families, name } = validation;
            const validations: FieldValidations<FieldValue> = new Map([...state.validations].filter(([k]) => k !== validator));

            // Return a FieldErrors object without any errors from the current validation rule.
            const clearError = (errors: FieldErrors): FieldErrors => {
              const hasError = errors[name]?.some((error) => error.validationId === validator);
              if (hasError) {
                if (errors[name].length === 1) {
                  // Remove the `name` property entirely
                  const { [name]: _, ...newErrors } = errors;
                  return Object.freeze(newErrors);
                }
                // Remove the error created by the current rule, keep the others
                return Object.freeze({
                  ...errors,
                  [name]: errors[name].filter((error) => error.validationId !== validator),
                });
              }
              // Return the errors object unchanged to avoid unnecessary re-renderings
              return errors;
            };

            let newState = {
              ...state,
              validations: validations,
              errors: clearError(state.errors),
            };

            if (families.includes('change') && revalidate) {
              newState = runFieldValidations({ name: name, families: ['change'] }, newState).state;
            }

            return newState;
          });
        };
      }, [nameSpec, previousNameSpecRef, previousValidatorRef, validator]);
    },
    [key, store],
  );
}

/** An empty field error object. */
const EMPTY_FIELD_ERRORS: FieldErrors = Object.freeze({});

/**
 * Retrieves the field errors for a given key from the form context.
 *
 * The component calling this hook will be re-rendered whenever the field errors change.
 *
 * @template FieldValue - The type of the field value.
 * @param ctx - The form context.
 * @param key - The field key.
 * @return The field errors, or an empty object if the given key doesn't exist in the store.
 */
export function useFieldErrors<FormMappings, FieldValue>(ctx: FormContext<FormMappings>, key: FieldKey<FieldValue>): FieldErrors {
  const store = useFormStoreContext(ctx);
  return useSyncExternalStore(store.subscribe, () => store.getFieldStateOrNull(key)?.errors ?? EMPTY_FIELD_ERRORS);
}

/**
 * Retrieves the error message of the first field error for a given key from the form context.
 *
 * The component calling this hook will be re-rendered whenever the field errors change.
 *
 * Use this hook to provide a simple user experience for users wanting to correct validation errors.
 *
 * @template FieldValue - The type of the field value.
 * @param ctx - The form context.
 * @param key - The field key.
 * @return A tuple containing the error message if present and the error params defaulting to an empty object
 */
export function useFieldErrorsFirstMessage<FormMappings, FieldValue>(
  ctx: FormContext<FormMappings>,
  key: FieldKey<FieldValue>,
): readonly [string | null, Record<string, unknown>] {
  const errors = useFieldErrors(ctx, key);

  return useMemo(() => {
    const firstError = Object.values(errors)[0]?.[0]?.message;
    return firstError ? firstError : [null, {}];
  }, [errors]);
}

/**
 * Retrieves whether an error is present for a given key from the form context.
 *
 * The component calling this hook will be re-rendered whenever the field errors change.
 *
 * @template FieldValue - The type of the field value.
 * @param ctx - The form context.
 * @param key - The field key.
 * @return A boolean indicating if an error is present.
 */
export function useFieldHasErrors<FormMappings>(ctx: FormContext<FormMappings>, key: FieldKey<unknown>): boolean {
  const errors = useFieldErrors(ctx, key);
  return useMemo(() => Object.keys(errors).length > 0, [errors]);
}

/** This hook returns another hook used to define {@link FieldMappings} for the provided field and form context.
 *
 * You should prefix the name of the variable that holds the resulting hook with
 * useMapper to benefit from additional static code analysis and quick fixes for
 * the list of dependencies.
 *
 * @param ctx - The form context.
 * @param key - The key of the field to map.
 * @returns A hook to add mappers to the field.
 * */
export function useFieldMapper<FieldValue, FormMappings>(
  ctx: FormContext<FormMappings>,
  key: FieldKey<FieldValue>,
): <K extends keyof FormMappings>(mapperFn: FieldMapper<FieldValue, FormMappings[K]>, deps: unknown[], mapping: K) => void {
  const store = useFormStoreContext(ctx);

  return useCallback(
    function useFieldMapperWrapped(mapperFn, deps, mapping) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const mapper = useCallback(mapperFn, deps);

      const previousMapperRef = useRefSync(usePreviousRenderValue(mapper));
      const previousMappingRef = useRefSync(usePreviousRenderValue(mapping));

      useEffect(() => {
        if (previousMapperRef.current !== mapper || previousMappingRef.current !== mapping) {
          loggerMapping.debug('Registering mapper for', mapping, 'with', key);

          store.setFieldState(key, (state) => {
            // This code will only execute if a mapper is mounted, so this is a great time to check if we have any
            // mapper kept alive from the previous mount to remove them from the field state as they need to be refreshed.
            const mappers: FieldMappings<FieldValue, FormMappings> = new Map([
              ...[...state.mappers].filter(([_, v]) => {
                if (v.keepalive) {
                  loggerMapping.log('Found existing kept alive mapper', v, 'with', key, 'Unregistering...');
                  return false;
                }
                return true;
              }),
              [mapper, Object.freeze({ mapping, mapper, keepalive: false })],
            ]);

            return {
              ...state,
              mappers,
            };
          });
        }

        return () => {
          store.setFieldState(key, (state) => {
            loggerMapping.debug('Unregistering mapper');

            // HACK: Check if we still have a mapper for the current id when the setter triggers.
            //  It is often not the case during hot-reloads or with strict mode which will attempt to remove mappers twice.
            const popped = state.mappers.get(mapper);
            if (popped == null) {
              loggerMapping.log('Skipping. Mapper is not registered.');
              return state;
            }

            const mappers: FieldMappings<FieldValue, FormMappings> = new Map([...state.mappers].filter(([k]) => k !== mapper));

            // Since the lifetime of mappers is actually tied to the form context, mapperIds are only used to track
            // individual mapper instances getting re-registered. We want to make sure that once a field is registered
            // with a mapper, it can always be mapped, even if the field is unmounted. This is different from
            // validations where unmounting the field will not keep the validation rule alive. To accomplish this,
            // we re-register the last unmounted mapper with the keepalive flag set.
            if (mappers.size === 0) {
              loggerMapping.log('Keeping alive due to last mapper unmounted for field', state);
              const mappersWithKeepAlive: FieldMappings<FieldValue, FormMappings> = new Map([
                ...mappers,
                [mapper, Object.freeze({ ...popped, keepalive: true })],
              ]);
              return {
                ...state,
                mappers: mappersWithKeepAlive,
              };
            }

            return {
              ...state,
              mappers,
            };
          });
        };
      }, [mapper, mapping, previousMapperRef, previousMappingRef]);
    },
    [key, store],
  );
}

// Example
//
// export const formCtx = createFormContext<ServiceCallFormMappings>();
//
// const fieldDepartureDateKey = createFieldKey<DateTime | null>();
//
// function useFieldExistsDepartureDate() {
//   return useFieldExists(formCtx, fieldDepartureDateKey);
// }
//
// function parseDateTime(rawValue: string | null | undefined): DateTime | null {
//   if (rawValue) {
//     const dateTime = DateTime.fromISO(rawValue);
//     if (dateTime.isValid) return dateTime;
//   }
//   return null;
// }
//
// function useFieldDepartureDate(initialValue: formsUseFieldDepartureDate$key | null) {
//   const data = useFragment(
//     graphql`
//       fragment formsUseFieldDepartureDate on ProjectInternalBase {
//         departureDate {
//           rawValue
//         }
//       }
//     `,
//     initialValue,
//   );
//   const [departureDate, setDepartureDate] = useField(formCtx, fieldDepartureDateKey, parseDateTime(data?.departureDate?.rawValue));
//
//   const renderDepartureDate = useCallback(
//     () => <input value={departureDate?.toISO() ?? ''} onChange={(event) => setDepartureDate(parseDateTime(event.target.value))}></input>,
//     [departureDate, setDepartureDate],
//   );
//
//   const useValidationDepartureDate = useFieldValidation(formCtx, fieldArrivalDateKey);
//
//   return {
//     departureDate,
//     setDepartureDate,
//     renderDepartureDate,
//     useValidationDepartureDate,
//   };
// }
//
// const fieldArrivalDateKey = createFieldKey<DateTime | null>();
//
// function useFieldExistsArrivalDate() {
//   return useFieldExists(formCtx, fieldArrivalDateKey);
// }
//
// function useFieldArrivalDate(initialValue: formsUseFieldArrivalDate$key | null) {
//   const data = useFragment(
//     graphql`
//       fragment formsUseFieldArrivalDate on ServiceCallProjectInternal {
//         arrivalDate
//       }
//     `,
//     initialValue,
//   );
//   const [arrivalDate, setArrivalDate] = useField(formCtx, fieldArrivalDateKey, parseDateTime(data?.arrivalDate));
//
//   const useMapperArrivalDate = useFieldMapper(formCtx, fieldArrivalDateKey);
//   useMapperArrivalDate((v) => ({ project: { arrivalDate: v?.toISO() ?? null } }), [], 'save');
//   useMapperArrivalDate((v) => ({ project: { arrivalDate: v?.toLocale(locale) ?? null } }), [locale], 'transfer');
//   useMapperArrivalDate(() => ({}), [], 'cancel');
//
//   const { mapAll, mapDirty } = useFormMappings(formCtx);
//   const validate = useFormValidate(formCtx);
//
//   const handleCopy = () => {
//     const saveInput = mapAll('cancel');
//     const transferInput = mapDirty('transfer');
//   };
//
//   validate('transfer');
//
//   const useValidationArrivalDate = useFieldValidation(formCtx, fieldArrivalDateKey);
//   const foo = useMemo(() => ({}), []);
//
//   useValidationArrivalDate((v) => v != null && foo != null, [foo], 'change:required');
//   useValidationArrivalDate((v) => v != null, [], 'save,copy:required');
//   useValidationArrivalDate((v) => v != null, [], 'change,save,copy:required');
//
//   const renderArrivalDate = useCallback(
//     () => <input value={arrivalDate?.toISO() ?? ''} onChange={(event) => setArrivalDate(parseDateTime(event.target.value))}></input>,
//     [arrivalDate, setArrivalDate],
//   );
//
//   return {
//     arrivalDate,
//     setArrivalDate,
//     renderArrivalDate,
//     useValidationArrivalDate,
//   };
// }
//
// function useRules() {
//   const data = useLazyLoadQuery<formsExampleQuery>(
//     graphql`
//       query formsExampleQuery($id: ID!, $skipArrivalDate: Boolean!, $skipDepartureDate: Boolean!) {
//         node(id: $id) {
//           ... on ServiceCallJobRevision {
//             snapshot {
//               projectBase {
//                 ...formsUseFieldDepartureDate @skip(if: $skipDepartureDate)
//               }
//               project {
//                 ...formsUseFieldArrivalDate @skip(if: $skipArrivalDate)
//               }
//             }
//           }
//         }
//       }
//     `,
//     {
//       id: '42',
//       skipArrivalDate: useFieldExistsArrivalDate(),
//       skipDepartureDate: useFieldExistsDepartureDate(),
//     },
//   );
//
//   const validate = useFormValidate(formCtx);
//   const { departureDate, useValidationDepartureDate } = useFieldDepartureDate(data?.node?.snapshot?.projectBase ?? null);
//   const { arrivalDate, useValidationArrivalDate } = useFieldArrivalDate(data.node?.snapshot?.project ?? null);
//
//   useValidationArrivalDate((v) => departureDate != null && v !== undefined, [departureDate], 'transfer:required');
//   useValidationDepartureDate((v) => arrivalDate != null && v !== undefined, [arrivalDate], 'transfer:required');
//
//   const departureIsDirty = useFieldIsDirty(formCtx, fieldDepartureDateKey);
//
//   useValidationArrivalDate(
//     (arrival, { isDirty }) => isDirty && arrival != null && departureDate != null && +departureDate < +arrival,
//     [departureDate],
//     'change:min',
//     { revalidate: false }, // default à true
//   );
//
//   useValidationArrivalDate(
//     (arrival) => arrival == null || departureDate == null || +departureDate < +arrival,
//     [departureDate],
//     'init,change:min',
//   );
//   useValidationDepartureDate(
//     (departure) => arrivalDate == null || departure == null || +departure < +arrivalDate,
//     [arrivalDate],
//     'init,change:max',
//   );
//
//   useEffect(() => {
//     validate('save');
//   }, [arrivalDate, departureDate, validate]);
// }
//
// const fieldCounterKey = createFieldKey<number>();
//
// function useFieldCounter() {
//   return useField(formCtx, fieldCounterKey, 0);
// }
//
// export function CounterComponent() {
//   const [counter] = useFieldCounter();
//   console.log('counter', counter);
//   return <>Counter: {counter}</>;
// }
//
// export function FormsTestPage() {
//   return (
//     <FormProvider context={formCtx}>
//       <CounterComponent />
//     </FormProvider>
//   );
// }
