/**
 * @generated SignedSource<<728cfbd8f7ec1103d25e6f79e8cac27e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as boomConfigurationSnapshotLabelResolverType } from "../../__resolvers__/BoomConfigurationSnapshotLabelResolver";
import { label as configurationKindLookupLabelResolverType } from "../../__resolvers__/ConfigurationKindLookupLabelResolver";
import { label as craneCapacityLabelResolverType } from "../../__resolvers__/CraneCapacityLabelResolver";
import { label as equipmentKindLookupLabelResolverType } from "../../__resolvers__/EquipmentKindLookupLabelResolver";
export type AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment$data = {
  readonly additionalConfigurations: ReadonlyArray<{
    readonly boomConfiguration: {
      readonly id: string;
      readonly label: string | null | undefined;
    } | null | undefined;
    readonly capacity: {
      readonly capacity: number;
      readonly label: string | null | undefined;
    } | null | undefined;
    readonly configurationKind: {
      readonly code: number;
      readonly id: string;
      readonly label: Record<string, string> | null | undefined;
    } | null | undefined;
    readonly deletedAt: string | null | undefined;
    readonly equipmentKind: {
      readonly code: number;
      readonly id: string;
      readonly label: Record<string, string> | null | undefined;
    } | null | undefined;
    readonly id: string;
  }>;
  readonly " $fragmentType": "AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment";
};
export type AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment$key = {
  readonly " $data"?: AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdditionalConfigurationInfo",
      "kind": "LinkedField",
      "name": "additionalConfigurations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CraneCapacity",
          "kind": "LinkedField",
          "name": "capacity",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "capacity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "fragment": {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CraneCapacityLabelResolver"
              },
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/CraneCapacityLabelResolver').label,
              "path": "additionalConfigurations.capacity.label"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquipmentKindLookup",
          "kind": "LinkedField",
          "name": "equipmentKind",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "fragment": {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EquipmentKindLookupLabelResolver"
              },
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/EquipmentKindLookupLabelResolver').label,
              "path": "additionalConfigurations.equipmentKind.label"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ConfigurationKindLookup",
          "kind": "LinkedField",
          "name": "configurationKind",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "fragment": {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ConfigurationKindLookupLabelResolver"
              },
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/ConfigurationKindLookupLabelResolver').label,
              "path": "additionalConfigurations.configurationKind.label"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BoomConfigurationSnapshot",
          "kind": "LinkedField",
          "name": "boomConfiguration",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "fragment": {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BoomConfigurationSnapshotLabelResolver"
              },
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/BoomConfigurationSnapshotLabelResolver').label,
              "path": "additionalConfigurations.boomConfiguration.label"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deletedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AutomaticConfigurationInternal",
  "abstractKey": null
};
})();

(node as any).hash = "008756db4483a0591ae12c4c7b26e103";

export default node;
