import { Tab, Tabs } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  tab: 'cost-line-rules' | 'accessory-rules';
}

export function BillingCodeRulesTabs({ tab }: Props) {
  const { t } = useTranslation('billingCodeRule');
  const navigate = useNavigate();

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    navigate(`../${newValue}`);
  };

  return (
    <Tabs
      variant='scrollable'
      value={tab}
      onChange={handleChange}
      textColor='secondary'
      indicatorColor='secondary'
      scrollButtons='auto'
      sx={(theme) => ({ backgroundColor: theme.palette.background.paper, mb: '1rem' })}>
      <Tab value='cost-line-rules' label={t('section.billingCodeRules')} />
      <Tab value='accessory-rules' label={t('section.accessoryRules')} />
    </Tabs>
  );
}
