import { Route } from 'react-router-dom';
import { TranslationNamespaceContext } from '../common/hooks/useAmbientTranslation';
import { WorkPlanningListPage } from './WorkPlanningListPage';

export const workPlanningRoutes = (
  <>
    <Route path='work-plannings/:id' lazy={() => import('./WorkPlanningDetails.Page')}>
      <Route index lazy={() => import('./WorkPlanningDetails.Mobile.Index')} />
      <Route path='documents' lazy={() => import('../jobs/JobDetails.Mobile.JobDocumentsProgression')} />
      <Route path='client' lazy={() => import('./WorkPlanningDetails.Mobile.Client')} />
      <Route path='client/pick-client-f' lazy={() => import('./WorkPlanningDetails.Mobile.ClientPicker')} />
      <Route path='project' lazy={() => import('./WorkPlanningDetails.Mobile.Project')} />
      <Route path='project/pick-worksite-f' lazy={() => import('./WorkPlanningDetails.Mobile.WorksitePicker')} />
      <Route path='equipment' lazy={() => import('./WorkPlanningDetails.Mobile.Equipment')} />
      <Route path='equipment/lift-f/:liftId' lazy={() => import('../jobs/JobDetails.Mobile.Lift.Edit')} />
      <Route path='equipment/configurations' lazy={() => import('../jobs/JobDetails.Mobile.Equipment.Configuration')} />
      <Route path='equipment/configurations/:eTag' lazy={() => import('../jobs/JobDetails.Mobile.Equipment.Configuration.Item')} />
      <Route path='info' lazy={() => import('./WorkPlanningDetails.Desktop.Index')}>
        <Route path='pick-client' lazy={() => import('./WorkPlanningDetails.Desktop.ClientPicker')} />
        <Route path='pick-worksite' lazy={() => import('./WorkPlanningDetails.Desktop.WorksitePicker')} />
        <Route path='lift/:liftId' lazy={() => import('../jobs/JobDetails.Desktop.Lift.Edit')} />
      </Route>
      <Route path='attachment/:attachmentId' lazy={() => import('../jobs/JobDetails.Mobile.Attachment.Edit')} />
    </Route>
    <Route
      path='work-plannings'
      element={
        <TranslationNamespaceContext.Provider value='workPlanning'>
          <WorkPlanningListPage />
        </TranslationNamespaceContext.Provider>
      }
    />
  </>
);
