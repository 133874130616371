/**
 * @generated SignedSource<<86c2cccb3324966c3e67f6fc713300b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryRuleDetailsPage_FormFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleDetailsPage_ConditionsFragment" | "AccessoryRuleFields_AutomaticGridFragment" | "AccessoryRuleFields_DescriptionFragment">;
  readonly " $fragmentType": "AccessoryRuleDetailsPage_FormFragment";
};
export type AccessoryRuleDetailsPage_FormFragment$key = {
  readonly " $data"?: AccessoryRuleDetailsPage_FormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleDetailsPage_FormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryRuleDetailsPage_FormFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_DescriptionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleDetailsPage_ConditionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccessoryRuleFields_AutomaticGridFragment"
    }
  ],
  "type": "AccessoryRule",
  "abstractKey": null
};

(node as any).hash = "26a0056a8118aa756703b131a02bc979";

export default node;
