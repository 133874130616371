import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export function ItemDeleteButton({ onClick: handleClick, disabled }: { onClick: () => void; disabled: boolean }) {
  return (
    <IconButton
      className='delete-button'
      onClick={handleClick}
      disabled={disabled}
      sx={(theme) => ({
        mx: '0.2rem',
        '@media (pointer: fine)': {
          opacity: 0,
          transition: theme.transitions.create(['transform', 'opacity'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shortest,
          }),
        },
      })}>
      <DeleteIcon color={disabled ? 'disabled' : 'error'} />
    </IconButton>
  );
}
