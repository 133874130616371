import { useTranslation } from 'react-i18next';
import { InputAdornment, Typography } from '@mui/material';

export function InactiveStartAdornment() {
  const { t } = useTranslation('common');
  return (
    <InputAdornment position='start'>
      <Typography variant='overline' sx={(theme) => ({ color: theme.palette.error.main, ml: 1 })}>
        {t('inactive')}
      </Typography>
    </InputAdornment>
  );
}
