import { useFieldAssignedClient, useFieldClientOverridesRequirements } from './fields/ClientBaseFields';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { SuggestionPromptInput } from '../common/components/__generated__/SuggestionsFakeQuery.graphql';
import { useSuggestionsFragment$key } from './__generated__/useSuggestionsFragment.graphql';
import {
  useFieldDispatchBranch,
  useFieldIsSecondServingValue,
  useFieldNatureOfWork,
  useFieldNatureOfWorkSubCategory,
  useFieldSalesBranch,
  useFieldWorkDescription,
} from './fields/SaleProjectFields';
import {
  useFieldAssignedWorksite,
  useFieldDurationHoursPerDay,
  useFieldDurationLengthInDays,
  useFieldWorksiteOverridesRequirements,
} from './fields/ProjectBaseFields';
import { useFieldInTheCareOf, useFieldRepresentative } from './fields/SaleClientFields';
import { ServiceCallKind } from '../__enums__/ServiceCallKind';
import { useFieldAccessoryLinesCollection } from './accessoryLines/AccessoryLinesFields';
import { useMemo } from 'react';
import { useCraneSelectorFavorite } from './JobEquipment.CraneSelector.Favorite';
import { useEquipmentSectionIsOptional } from './fields/SaleCraneSelectorFields';

export function useSuggestions(
  $key: useSuggestionsFragment$key | null | undefined,
  saleKind: ServiceCallKind,
  disabled: boolean,
): SuggestionPromptInput {
  const $data = useFragment(
    graphql`
      fragment useSuggestionsFragment on ISale @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        clientBase {
          ...ClientBaseFields_AssignedClientFragment
          assignedClient {
            requirements {
              ...ClientBaseFields_RequirementsFragment
            }
          }
          assignedClientInfo {
            requirements {
              ...ClientBaseFields_RequirementsFragment
            }
          }
        }
        client {
          ...SaleClientFields_InTheCareOfFragment
          ...SaleClientFields_RepresentativeFragment
        }
        projectBase {
          ...ProjectBaseFields_DurationHoursPerDayFragment
          ...ProjectBaseFields_DurationLengthInDaysFragment
          ...ProjectBaseFields_AssignedWorksiteFragment
          assignedWorksite {
            requirements {
              ...ProjectBaseFields_RequirementsFragment
            }
          }
          assignedWorksiteInfo {
            requirements {
              ...ProjectBaseFields_RequirementsFragment
            }
          }
        }
        project {
          ...SaleProjectFields_DispatchBranchFragment
          ...SaleProjectFields_IsSecondServingFragment
          ...SaleProjectFields_NatureOfWorkFragment
          ...SaleProjectFields_NatureOfWorkSubCategoryFragment
          ...SaleProjectFields_SalesBranchFragment
          ...SaleProjectFields_WorkDescriptionFragment
        }
        equipmentBase {
          craneSelector {
            ...JobEquipment_useCraneSelectorFavoriteFragment
          }
        }
        costsBase {
          ...AccessoryLinesFields_AccessoryLineCollectionFragment @arguments(skipAccessories: $skipAccessories)
        }
      }
    `,
    $key,
  );

  const { accessoryLines } = useFieldAccessoryLinesCollection($data?.costsBase, disabled);
  const accessoryTypeCodes = useMemo(
    () => accessoryLines.flatMap((a) => (a.accessoryType?.code ? [a.accessoryType.code.toString()] : [])),
    [accessoryLines],
  );
  const { assignedClient } = useFieldAssignedClient($data?.clientBase);
  const { clientOverridesRequirements } = useFieldClientOverridesRequirements(
    $data?.clientBase.assignedClientInfo.requirements,
    $data?.clientBase.assignedClient?.requirements,
    disabled,
  );
  const { dispatchBranch } = useFieldDispatchBranch($data?.project, disabled);
  const { durationHoursPerDay } = useFieldDurationHoursPerDay($data?.projectBase, disabled);
  const { inTheCareOf } = useFieldInTheCareOf($data?.client, disabled);
  const { isSecondServing } = useFieldIsSecondServingValue($data?.project);
  const { durationLengthInDays } = useFieldDurationLengthInDays($data?.projectBase, disabled);
  const { natureOfWork } = useFieldNatureOfWork($data?.project, disabled);
  const { natureOfWorkSubCategory } = useFieldNatureOfWorkSubCategory($data?.project, null, disabled);
  const { representative } = useFieldRepresentative($data?.client, disabled);
  const { salesBranch } = useFieldSalesBranch($data?.project, disabled);
  const { workDescription } = useFieldWorkDescription($data?.project, disabled);
  const { assignedWorksite } = useFieldAssignedWorksite($data?.projectBase);
  const { worksiteOverridesRequirements } = useFieldWorksiteOverridesRequirements(
    $data?.projectBase.assignedWorksiteInfo.requirements,
    $data?.projectBase.assignedWorksite?.requirements,
    disabled,
  );
  const isEquipmentSectionRequired = !useEquipmentSectionIsOptional(saleKind);
  const { favorite } = useCraneSelectorFavorite($data?.equipmentBase.craneSelector, isEquipmentSectionRequired);

  return {
    accessoryTypes: accessoryTypeCodes,
    capacity: favorite?.capacity.capacity ?? null,
    clientId: assignedClient?.id ?? null,
    clientRequirements: clientOverridesRequirements?.map((r) => r.id) ?? [],
    dispatchBranchId: dispatchBranch?.id ?? null,
    equipmentKindCode: favorite?.equipmentKind.code.toString() ?? null,
    hoursPerDay: durationHoursPerDay,
    inTheCareOf,
    isSecondServing,
    lengthInDays: durationLengthInDays,
    natureOfWorkCode: natureOfWork?.code.toString() ?? null,
    natureOfWorkSubCategory: natureOfWorkSubCategory,
    representativeId: representative?.id ?? null,
    salesBranchId: salesBranch?.id ?? null,
    serviceCallKind: saleKind,
    vehicleIds: favorite?.vehicleId?.label ? [favorite?.vehicleId.label] : [],
    workDescription,
    worksiteId: assignedWorksite?.id == null || assignedWorksite.id === 'new' ? null : assignedWorksite.id,
    worksiteRequirements: worksiteOverridesRequirements?.map((r) => r.id) ?? [],
  };
}
