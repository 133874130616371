/**
 * @generated SignedSource<<60a3a6b9f78e52789b5a64584c93af6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationRow_BaseFragment$data = {
  readonly createdAt: string;
  readonly id: string;
  readonly isRead: boolean;
  readonly " $fragmentType": "NotificationRow_BaseFragment";
};
export type NotificationRow_BaseFragment$key = {
  readonly " $data"?: NotificationRow_BaseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationRow_BaseFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationRow_BaseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRead",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "Notification",
  "abstractKey": "__isNotification"
};

(node as any).hash = "7f3fbedb081374a5c1f1a65eef7469a9";

export default node;
