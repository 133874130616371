/**
 * @generated SignedSource<<8617481079455d3169ae9f332148e389>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationRowFragment$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationRow_ModificationRequestedFragment" | "NotificationRow_QuoteStatusNotificationFragment">;
  readonly " $fragmentType": "NotificationRowFragment";
};
export type NotificationRowFragment$key = {
  readonly " $data"?: NotificationRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationRowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationRowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationRow_ModificationRequestedFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationRow_QuoteStatusNotificationFragment"
    }
  ],
  "type": "Notification",
  "abstractKey": "__isNotification"
};

(node as any).hash = "e142501d1b5ab237d644da20fcc34798";

export default node;
