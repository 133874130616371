import { Suspense, useCallback } from 'react';
import { DetailsLayout, EmptyLayout, SidebarContentProps } from '../../layout/Layouts';
import { DataID, useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Navigate, useParams } from 'react-router-dom';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { NavigationMenu } from '../../layout/SidebarDrawer';
import { FormProvider } from '../../common/utils/forms';
import { FormSectionContent, FormSectionHeader, FormSectionHeader_Title } from '../../layout/FormLayout';
import { BillingCodeRuleSaveButton } from './BillingCodeRuleSaveButton';
import { indexedPageTitle, usePageTitle } from '../../common/hooks/usePageTitle';
import { RequireAdmin } from '../../auth/Authorization';
import { ErrorBanner, ErrorStateProvider } from '../../common/components/ErrorBanner';
import { BillingCodeRuleDeleteButton } from './BillingCodeRuleDeleteButton';
import { usePreventNavigationOnFormDirty } from '../../common/hooks/usePreventNavigationOnFormDirty';
import { DetailsPageErrorBoundary } from '../../layout/DetailsPageErrorBoundary';
import { DetailsPageRootErrorBoundary } from '../../layout/DetailsPageRootErrorBoundary';
import { ElementNotFoundError } from '../../common/exceptions/ElementNotFoundError';
import { ElementNotFoundErrorBoundary } from '../../layout/ElementNotFoundErrorBoundary';
import { Typography } from '@mui/material';
import {
  billingCodeRuleDetailsFormContext,
  BillingCodesAutomaticGrid,
  useFieldBaseQuestions,
  useFieldBillingCodesVisible,
  useFieldCapacities,
  useFieldClients,
  useFieldDescription,
  useFieldEquipmentKinds,
  useFieldNatureOfWorks,
  useFieldNatureOfWorkSubCategories,
  useFieldSaleKinds,
} from './BillingCodeRuleFields';
import { BillingCodeRuleDetailsPageRootQuery } from './__generated__/BillingCodeRuleDetailsPageRootQuery.graphql';
import { BillingCodeRuleDetailsPage_FormFragment$key } from './__generated__/BillingCodeRuleDetailsPage_FormFragment.graphql';
import { BillingCodeRuleDetailsPage_InformationFragment$key } from './__generated__/BillingCodeRuleDetailsPage_InformationFragment.graphql';
import { BillingCodeRuleDetailsPage_ConditionsFragment$key } from './__generated__/BillingCodeRuleDetailsPage_ConditionsFragment.graphql';
import { BillingCodeRuleDetailsPage_EffectsFragment$key } from './__generated__/BillingCodeRuleDetailsPage_EffectsFragment.graphql';
import { EmployeeNotFoundErrorBoundary } from '../../auth/EmployeeNotFoundErrorBoundary';

export function BillingCodeRuleDetailsPage() {
  const { t } = useAmbientTranslation();
  const params = useParams<{ id: DataID }>();
  const id = params.id ?? 'new';
  const heading = id === 'new' ? t('newTitle') : t('editTitle', { id });

  return (
    <DetailsPageErrorBoundary heading={heading}>
      <Suspense fallback={<EmptyLayout />}>
        <ErrorStateProvider>
          <EmployeeNotFoundErrorBoundary>
            <ElementNotFoundErrorBoundary sidebar$key={null} detailsLayout$key={null} heading={heading}>
              <BillingCodeRulesDetailsPageRoot />
            </ElementNotFoundErrorBoundary>
          </EmployeeNotFoundErrorBoundary>
        </ErrorStateProvider>
      </Suspense>
    </DetailsPageErrorBoundary>
  );
}

function BillingCodeRulesDetailsPageRoot() {
  const { t } = useAmbientTranslation();
  usePageTitle(indexedPageTitle('sidebar.billingCodeRules'));
  const params = useParams<{ id: DataID }>();
  const id = params.id ?? 'new';

  const $data = useLazyLoadQuery<BillingCodeRuleDetailsPageRootQuery>(
    graphql`
      query BillingCodeRuleDetailsPageRootQuery($id: ID!, $isNew: Boolean!) {
        ...AuthorizationAdminFragment
        ...SidebarDrawerFragment
        ...LayoutsDetailsLayoutFragment
        ...DetailsPageRootErrorBoundaryFragment
        billingCodeRule(id: $id) @skip(if: $isNew) {
          ...BillingCodeRuleDetailsPage_FormFragment
          eTag
        }
      }
    `,
    { id, isNew: id === 'new' },
    { fetchPolicy: 'store-and-network' },
  );

  if (id !== 'new' && $data.billingCodeRule == null) {
    throw new ElementNotFoundError('BillingCodeRule', id, '/billing-code-rules/cost-line-rules');
  }

  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  const heading = id === 'new' ? t('newTitle') : t('editTitle', { id });

  return (
    <DetailsPageRootErrorBoundary heading={heading} $key={$data}>
      <RequireAdmin $key={$data} fallback={<Navigate to='..' replace />}>
        <FormProvider key={$data.billingCodeRule?.eTag ?? id} context={billingCodeRuleDetailsFormContext}>
          <DetailsLayout
            heading={heading}
            sidebarProvider={sidebar}
            $key={$data}
            actions={
              <>
                <BillingCodeRuleDeleteButton id={id} />
                <BillingCodeRuleSaveButton id={id} />
              </>
            }>
            <ErrorBanner />
            <BillingCodeRule_DetailsPageForm $key={$data.billingCodeRule} />
          </DetailsLayout>
        </FormProvider>
      </RequireAdmin>
    </DetailsPageRootErrorBoundary>
  );
}

function BillingCodeRule_DetailsPageForm({ $key }: { $key: BillingCodeRuleDetailsPage_FormFragment$key | null | undefined }) {
  const { t } = useAmbientTranslation();
  usePreventNavigationOnFormDirty([billingCodeRuleDetailsFormContext]);

  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleDetailsPage_FormFragment on BillingCodeRule {
        ...BillingCodeRuleDetailsPage_InformationFragment
        ...BillingCodeRuleDetailsPage_ConditionsFragment
        ...BillingCodeRuleDetailsPage_EffectsFragment
      }
    `,
    $key,
  );

  return (
    <>
      <FormSectionHeader label={<FormSectionHeader_Title label={t('fields.label.information')} />} />
      <FormSectionContent>
        <BillingCodeRule_Information $key={$data} />
      </FormSectionContent>
      <FormSectionHeader label={<FormSectionHeader_Title label={t('fields.label.conditions')} />} />
      <FormSectionContent>
        <BillingCodeRule_Conditions $key={$data} />
      </FormSectionContent>
      <FormSectionHeader label={<FormSectionHeader_Title label={t('fields.label.billingCodes')} />} />
      <BillingCodeRule_Effects $key={$data} />
    </>
  );
}

function BillingCodeRule_Information({ $key }: { $key: BillingCodeRuleDetailsPage_InformationFragment$key | null | undefined }) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleDetailsPage_InformationFragment on BillingCodeRule {
        ...BillingCodeRuleFields_DescriptionFragment
      }
    `,
    $key,
  );

  const { renderDescription } = useFieldDescription($data);
  return renderDescription();
}

function BillingCodeRule_Conditions({ $key }: { $key: BillingCodeRuleDetailsPage_ConditionsFragment$key | null | undefined }) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleDetailsPage_ConditionsFragment on BillingCodeRule {
        ...BillingCodeRuleFields_SaleKindsFragment
        ...BillingCodeRuleFields_CapacitiesFragment
        ...BillingCodeRuleFields_EquipmentKindsFragment
        ...BillingCodeRuleFields_ClientsFragment
        ...BillingCodeRuleFields_NatureOfWorksFragment
        ...BillingCodeRuleFields_NatureOfWorkSubCategoriesFragment
        ...BillingCodeRuleFields_BaseQuestions
      }
    `,
    $key,
  );

  const { renderSaleKinds } = useFieldSaleKinds($data);
  const { renderCapacities } = useFieldCapacities($data);
  const { renderEquipmentKinds } = useFieldEquipmentKinds($data);
  const { renderClients } = useFieldClients($data);
  const { renderNatureOfWorks } = useFieldNatureOfWorks($data);
  const { renderNatureOfWorkSubCategories } = useFieldNatureOfWorkSubCategories($data);
  const { renderBaseQuestions } = useFieldBaseQuestions($data);

  return (
    <>
      {renderSaleKinds()}
      {renderCapacities()}
      {renderEquipmentKinds()}
      {renderClients()}
      {renderNatureOfWorks()}
      {renderNatureOfWorkSubCategories()}
      {renderBaseQuestions()}
    </>
  );
}

function BillingCodeRule_Effects({ $key }: { $key: BillingCodeRuleDetailsPage_EffectsFragment$key | null | undefined }) {
  const { t } = useAmbientTranslation();
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleDetailsPage_EffectsFragment on BillingCodeRule {
        ...BillingCodeRuleFields_BillingCodesVisibleFragment
        ...BillingCodeRuleFields_AutomaticGridFragment
      }
    `,
    $key,
  );

  const { renderBillingCodesVisible } = useFieldBillingCodesVisible($data);

  return (
    <>
      <FormSectionContent>
        {renderBillingCodesVisible()}
        <Typography>{t('fields.label.billingCodesAutomatic')}</Typography>
      </FormSectionContent>
      <BillingCodesAutomaticGrid $key={$data} />
    </>
  );
}
