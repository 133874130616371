import { createFieldKey, createFormContext, SetValueFn, useField, useFieldErrors, useFieldMapper } from '../../common/utils/forms';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { useTranslation } from 'react-i18next';
import { AccessoryTypeAutocomplete, ForwardAccessoryTypeAutocompleteProps } from '../../common/components/AccessoryTypeAutocomplete';
import {
  AccessoryBillingCodeAutocomplete,
  ForwardAccessoryBillingCodeAutocompleteProps,
} from '../../common/components/AccessoryBillingCodeAutocomplete';
import { AccessoryRulesAutomaticRow } from './AccessoryRuleFields';
import { useCallback } from 'react';
import { _throw } from '../../common/utils/_throw';

export const accessoryRuleAutomaticSubFormContext = createFormContext<{ sync: AccessoryRulesAutomaticRow }>();

type AutomaticAccessoryId = string;
const fieldAutomaticAccessoryIdKey = createFieldKey<AutomaticAccessoryId>();

export function useFieldAutomaticAccessoryId(initialValue: AutomaticAccessoryId) {
  const [automaticAccessoryId, setAutomaticAccessoryId] = useField(
    accessoryRuleAutomaticSubFormContext,
    fieldAutomaticAccessoryIdKey,
    initialValue,
  );

  const useMapper = useFieldMapper(accessoryRuleAutomaticSubFormContext, fieldAutomaticAccessoryIdKey);
  useMapper((v) => ({ key: v }), [], 'sync');

  return { automaticAccessoryId, setAutomaticAccessoryId };
}

export type AutomaticAccessoryAccessoryType = ForwardAccessoryTypeAutocompleteProps<false, boolean>['value'];
const fieldAutomaticAccessoryAccessoryTypeKey = createFieldKey<AutomaticAccessoryAccessoryType>();

export function useFieldAutomaticAccessoryAccessoryType(initialValue: AutomaticAccessoryAccessoryType | undefined) {
  const [automaticAccessoryAccessoryType, setAutomaticAccessoryAccessoryType] = useField(
    accessoryRuleAutomaticSubFormContext,
    fieldAutomaticAccessoryAccessoryTypeKey,
    initialValue ?? null,
  );

  const useMapper = useFieldMapper(accessoryRuleAutomaticSubFormContext, fieldAutomaticAccessoryAccessoryTypeKey);
  useMapper((v) => ({ accessoryType: v ?? _throw(new Error('AccessoryType should not be null')) }), [], 'sync');

  const renderAutomaticAccessoryType = useCallback(
    () => <AutomaticAccessoryAccessoryTypeInput value={automaticAccessoryAccessoryType} setValue={setAutomaticAccessoryAccessoryType} />,
    [automaticAccessoryAccessoryType, setAutomaticAccessoryAccessoryType],
  );

  return {
    automaticAccessoryAccessoryType,
    setAutomaticAccessoryAccessoryType,
    renderAutomaticAccessoryType,
  };
}

export function AutomaticAccessoryAccessoryTypeInput({
  value,
  setValue,
}: {
  value: AutomaticAccessoryAccessoryType;
  setValue: SetValueFn<AutomaticAccessoryAccessoryType>;
}) {
  const { t } = useTranslation('common');

  const handleChange = useCallback<NonNullable<ForwardAccessoryTypeAutocompleteProps<false, boolean>['onChange']>>(
    (v) => setValue(v),
    [setValue],
  );

  return (
    <AccessoryTypeAutocomplete
      disableClearable={!!value}
      value={value}
      onChange={handleChange}
      textFieldProps={() => ({ className: 'borderless', placeholder: t('button.select') })}
    />
  );
}

export type AutomaticAccessoryBillingCode = ForwardAccessoryBillingCodeAutocompleteProps<false, boolean>['value'];
const fieldAutomaticAccessoryBillingCodeKey = createFieldKey<AutomaticAccessoryBillingCode>();

export function useFieldAutomaticAccessoryBillingCode(initialValue: AutomaticAccessoryBillingCode | undefined, disabled: boolean) {
  const [automaticAccessoryBillingCode, setAutomaticAccessoryBillingCode] = useField(
    accessoryRuleAutomaticSubFormContext,
    fieldAutomaticAccessoryBillingCodeKey,
    initialValue ?? null,
  );

  const useMapper = useFieldMapper(accessoryRuleAutomaticSubFormContext, fieldAutomaticAccessoryBillingCodeKey);
  useMapper((v) => ({ billingCode: v ?? _throw(new Error('BillingCode should not be null')) }), [], 'sync');

  const renderAutomaticAccessoryBillingCode = useCallback(
    () => (
      <AutomaticAccessoryBillingCodeInput
        value={automaticAccessoryBillingCode}
        setValue={setAutomaticAccessoryBillingCode}
        disabled={disabled}
      />
    ),
    [automaticAccessoryBillingCode, disabled, setAutomaticAccessoryBillingCode],
  );

  return { automaticAccessoryBillingCode, setAutomaticAccessoryBillingCode, renderAutomaticAccessoryBillingCode };
}

export function AutomaticAccessoryBillingCodeInput({
  value,
  setValue,
  disabled,
}: {
  value: AutomaticAccessoryBillingCode;
  setValue: SetValueFn<AutomaticAccessoryBillingCode>;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(accessoryRuleAutomaticSubFormContext, fieldAutomaticAccessoryBillingCodeKey);

  const handleChange = useCallback<NonNullable<ForwardAccessoryBillingCodeAutocompleteProps['onChange']>>((v) => setValue(v), [setValue]);

  return (
    <AccessoryBillingCodeAutocomplete
      disableClearable={!!value}
      value={value}
      disabled={disabled}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      getOptionKey={(o) => o.id}
      onChange={handleChange}
      textFieldProps={() => ({
        placeholder: t('button.select', { ns: 'common' }),
        className: 'borderless',
        error: !!errors['required'] && !disabled,
      })}
    />
  );
}
