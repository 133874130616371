/**
 * @generated SignedSource<<3a6f1197b1f1dc4bd4090759d08cf5f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TabBillingCodeRulesQuery$variables = Record<PropertyKey, never>;
export type TabBillingCodeRulesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleListFragment">;
};
export type TabBillingCodeRulesQuery = {
  response: TabBillingCodeRulesQuery$data;
  variables: TabBillingCodeRulesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TabBillingCodeRulesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "BillingCodeRuleListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TabBillingCodeRulesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingCodeRulesConnection",
        "kind": "LinkedField",
        "name": "billingCodeRules",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingCodeRulesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingCodeRule",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConditionsInternal",
                    "kind": "LinkedField",
                    "name": "conditions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kinds",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Connection",
            "abstractKey": "__isConnection"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9aba7ebd6d4bb66724803933a1f5f6d6",
    "id": null,
    "metadata": {},
    "name": "TabBillingCodeRulesQuery",
    "operationKind": "query",
    "text": "query TabBillingCodeRulesQuery {\n  ...BillingCodeRuleListFragment\n}\n\nfragment BillingCodeRuleListFragment on Query {\n  billingCodeRules {\n    ...ResponsiveGridFragment\n    edges {\n      node {\n        id\n        ...BillingCodeRuleListItemFragment\n      }\n    }\n  }\n}\n\nfragment BillingCodeRuleListItemFragment on BillingCodeRule {\n  description\n  id\n  conditions {\n    kinds\n  }\n}\n\nfragment ResponsiveGridFragment on Connection {\n  __isConnection: __typename\n  totalCount\n  pageInfo {\n    startCursor\n    endCursor\n    hasPreviousPage\n    hasNextPage\n  }\n  edges {\n    __typename\n    node {\n      __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "50f961b0f8fded68adfa557cca0ffc99";

export default node;
