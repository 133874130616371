import { SelectChangeEvent } from '@mui/material';
import Qty from 'js-quantities';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnitInput } from './UnitInput';
import { isDecimalNumber } from '../utils/numberUtils';
import { useEffectEvent } from '../utils/effectUtils';
import { defaultLogger, Logger } from '../utils/logging';

const logger = new Logger(defaultLogger, 'AngleInput', () => new Date().toISOString());

interface Props {
  value: string | null;
  enabledUnits: readonly AngleUnit[];
  label: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  min?: number;
  max?: number;
  step?: number;
  onChange: (val: string | null) => void;
}
export const angleUnits = ['deg'] as const;
export type AngleUnit = (typeof angleUnits)[number];

function isAngleUnit(val: unknown): val is AngleUnit {
  return angleUnits.includes(val as AngleUnit);
}

const makeCurrentValue = (scalar: number | undefined, unit: AngleUnit, min: number | undefined, max: number | undefined) => {
  if (scalar === undefined) {
    return null;
  }
  const adjustedScalar = Math.max(Math.min(scalar, max ?? Infinity), min ?? -Infinity);
  return `${adjustedScalar} ${unit}`;
};

export function AngleInput({ value, enabledUnits, label, min, max, step, required, disabled, onChange: handleChange, error }: Props) {
  if (enabledUnits.length === 0) {
    throw new Error('Empty enabledUnits. At least one unit must be enabled.');
  }

  const [scalar, setScalar] = useState<number | undefined>(undefined);
  const [unit, setUnit] = useState<AngleUnit>(enabledUnits[0]);
  const { t } = useTranslation('common');

  const setState = useCallback((sclr: typeof scalar, u: typeof unit) => {
    setScalar(sclr);
    setUnit(u);
  }, []);

  const syncState = useEffectEvent((val: string | null, enbldUnts: readonly AngleUnit[]) => {
    if (!val) {
      // Since makeCurrentValue()'s return value is empty whenever the scalar is empty, when we get an empty value,
      // default to the current unit to avoid resetting the unit needlessly every time the scalar is cleared, unless it
      // isn't included in the enabled units anymore, in which case default to the first enabled unit.
      const defaultUnit = enbldUnts.includes(unit) ? unit : enbldUnts[0];
      return setState(undefined, defaultUnit);
    }

    const quantity = Qty.parse(val);
    if (!quantity) {
      logger.error("Couldn't parse value", val);
      return setState(undefined, enbldUnts[0]);
    }

    const qtyUnit = quantity.units();
    if (!isAngleUnit(qtyUnit)) {
      logger.error('Detected unit is not a valid Angle unit', qtyUnit);
      return setState(undefined, enbldUnts[0]);
    }

    if (!enbldUnts.includes(qtyUnit)) {
      logger.error('Detected unit is not enabled', qtyUnit, enbldUnts);
      return setState(undefined, enbldUnts[0]);
    }

    return setState(quantity.scalar, qtyUnit);
  });
  useEffect(() => {
    syncState(value, enabledUnits);
  }, [syncState, value, enabledUnits]);

  const handleDropDownChange = useCallback(
    (event: SelectChangeEvent) => {
      const newUnit = isAngleUnit(event.target.value) ? event.target.value : enabledUnits[0];
      setUnit(newUnit);
      handleChange(makeCurrentValue(scalar, newUnit, min, max));
    },
    [enabledUnits, handleChange, max, min, scalar],
  );

  const handleScalarChange = useCallback(
    (newValue: number | null, _: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (newValue != null && !Number.isNaN(newValue)) {
        setScalar(newValue);
        handleChange(makeCurrentValue(newValue, unit, min, max));
      } else {
        setScalar(undefined);
        handleChange(makeCurrentValue(undefined, unit, min, max));
      }
    },
    [handleChange, max, min, unit],
  );

  const handleBlur = useCallback(() => {
    if (scalar) {
      const { intValue } = isDecimalNumber(scalar);
      setScalar(intValue);
      handleChange(makeCurrentValue(intValue, unit, min, max));

      if (scalar < 0) {
        setScalar(0);
        handleChange(makeCurrentValue(0, unit, min, max));
      }
    }
  }, [handleChange, max, min, scalar, unit]);

  return (
    <UnitInput<AngleUnit>
      supportedUnits={enabledUnits}
      onScalarChange={handleScalarChange}
      onUnitChange={handleDropDownChange}
      fieldLabel={label}
      scalar={scalar}
      unit={unit}
      onBlur={handleBlur}
      required={required}
      disabled={disabled}
      error={error}
      renderMenuValue={(v) => t(`unit.angle.short.${v}`)}
      renderAdornmentValue={(v) => t(`unit.angle.short.${v}`)}
      min={min}
      max={max}
      step={step}
    />
  );
}
