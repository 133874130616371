import graphql from 'babel-plugin-relay/macro';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useOperations, useOperationsRelay } from '../AppSharedState';
import { useCancellableSubscription } from '../common/hooks/useCancellableSubscription';
import { useFragment } from 'react-relay';
import { SetValueFn } from '../common/utils/forms';
import { CostLine, useFieldCostLinesCollection } from './costLines/CostLinesFields';
import { createSharedStateKey, useSharedState } from '../common/utils/sharedState';
import { jobSharedStateContext } from './JobSharedState';
import { useEffectEvent } from '../common/utils/effectUtils';
import { flagNew, flagRemoved } from '../common/utils/patchable';
import { asBillingCodeCategory } from '../__enums__/BillingCodeCategory';
import { castCostLineKind } from '../__enums__/CostLineKind';
import { LoadingButton } from '@mui/lab';
import {
  SaleApplyInstantCalculatorButton_useSaleApplyInstantCalculatorFragment$data,
  SaleApplyInstantCalculatorButton_useSaleApplyInstantCalculatorFragment$key,
} from './__generated__/SaleApplyInstantCalculatorButton_useSaleApplyInstantCalculatorFragment.graphql';
import { SaleApplyInstantCalculatorButtonFragment$key } from './__generated__/SaleApplyInstantCalculatorButtonFragment.graphql';
import { SaleApplyInstantCalculatorButtonQuery } from './__generated__/SaleApplyInstantCalculatorButtonQuery.graphql';
import { useCraneSelectorFavorite } from './JobEquipment.CraneSelector.Favorite';
import {
  useFieldArrivalDateValue,
  useFieldAssignedWorksite,
  useFieldWorksiteOverridesLocationValue,
  useFieldWorksiteOverridesNameValue,
} from './fields/ProjectBaseFields';
import { useFieldAssignedClient } from './fields/ClientBaseFields';
import { useFieldDispatchBranch, useFieldNatureOfWork } from './fields/SaleProjectFields';
import { AutomaticCostLineEquipmentInput } from './__generated__/useSalesRate_useCostLineSalesRateQuery.graphql';
import { SaleApplyInstantCalculatorButton_useSalesRateInputFragment$key } from './__generated__/SaleApplyInstantCalculatorButton_useSalesRateInputFragment.graphql';
import { useFieldBranchToWorksiteDistance } from './fields/SaleEquipmentFields';
import { Price } from '../common/utils/price';
import { nanoid } from 'nanoid';
import { Snackbar } from '../common/components/Snackbar';

export const SALE_APPLY_INSTANT_CALCULATOR_OPERATION_KEY = 'Apply_InstantCalculator';

export function SaleApplyInstantCalculatorButton({
  $key,
  disabled,
}: {
  $key: SaleApplyInstantCalculatorButtonFragment$key | null | undefined;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const { hasOperationInFlightForKey, shouldNotifyForKey } = useOperations(SALE_APPLY_INSTANT_CALCULATOR_OPERATION_KEY);
  const [_, setSubscription] = useCancellableSubscription();
  const fetchQuery = useOperationsRelay(SALE_APPLY_INSTANT_CALCULATOR_OPERATION_KEY);

  const [, setSaleInstantCalculator$key] = useSharedState(jobSharedStateContext, saleCalculatorApplyInstantKey);
  const [numberOfCostLines, setNumberOfCostLines] = useState<number>(0);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const $data = useFragment(
    graphql`
      fragment SaleApplyInstantCalculatorButtonFragment on ISale {
        ...SaleApplyInstantCalculatorButton_useSalesRateInputFragment
        costsBase {
          ...CostLinesFields_CostLineCollectionFragment
        }
        equipment {
          ...SaleEquipmentFields_BranchToWorksiteDistanceFragment
        }
      }
    `,
    $key,
  );

  const { setCostLines } = useFieldCostLinesCollection($data?.costsBase, false);
  const { branchToWorksiteDistance } = useFieldBranchToWorksiteDistance($data?.equipment, true);

  const salesRateInput = useSalesRateInput($data);

  const isValid = !disabled && branchToWorksiteDistance && salesRateInput;

  const fetchInstantCalculator = useCallback(() => {
    const query = graphql`
      query SaleApplyInstantCalculatorButtonQuery($salesRatesInput: CalculatorSalesRatesInput!, $branchToWorksiteDistance: Length!) {
        ...SaleApplyInstantCalculatorButton_useSaleApplyInstantCalculatorFragment
          @arguments(salesRatesInput: $salesRatesInput, branchToWorksiteDistance: $branchToWorksiteDistance)
        createInstantCalculatorCostLines(salesRatesInput: $salesRatesInput, branchToWorksiteDistance: $branchToWorksiteDistance) {
          __typename
        }
      }
    `;
    if (!isValid) {
      return;
    }
    const variables = {
      salesRatesInput: salesRateInput,
      branchToWorksiteDistance: branchToWorksiteDistance,
    };

    setSubscription(
      fetchQuery<SaleApplyInstantCalculatorButtonQuery>(query, variables, [
        (result) => {
          setNumberOfCostLines(result?.createInstantCalculatorCostLines.length ?? 0);
          setSnackbarOpen(true);
          setSaleInstantCalculator$key(result);
        },
      ]).subscribe({}),
    );
  }, [branchToWorksiteDistance, fetchQuery, isValid, salesRateInput, setSaleInstantCalculator$key, setSubscription]);

  useSaleApplyInstantCalculator(setCostLines);

  const handleInstantCalculator = useCallback(() => {
    if (hasOperationInFlightForKey || !isValid) return;
    setSnackbarOpen(false);
    fetchInstantCalculator();
  }, [isValid, fetchInstantCalculator, hasOperationInFlightForKey]);

  const handleOnClose = useCallback(() => setSnackbarOpen(false), [setSnackbarOpen]);

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        onClose={handleOnClose}
        message={t('alert.costLinesAdded', {
          ns: 'quote',
          count: numberOfCostLines,
        })}
      />
      <LoadingButton variant='text' loading={shouldNotifyForKey} color='error' onClick={handleInstantCalculator} disabled={!isValid}>
        {t('section.calculator.action.apply')}
      </LoadingButton>
    </>
  );
}

const saleCalculatorApplyInstantKey = createSharedStateKey<
  SaleApplyInstantCalculatorButton_useSaleApplyInstantCalculatorFragment$key | null | undefined
>(() => null);

function useSaleApplyInstantCalculator(setCostLines: SetValueFn<CostLine[]>) {
  const { endOperation } = useOperations(SALE_APPLY_INSTANT_CALCULATOR_OPERATION_KEY);
  const [$key] = useSharedState(jobSharedStateContext, saleCalculatorApplyInstantKey);

  const $data = useFragment(
    graphql`
      fragment SaleApplyInstantCalculatorButton_useSaleApplyInstantCalculatorFragment on Query
      @argumentDefinitions(salesRatesInput: { type: "CalculatorSalesRatesInput!" }, branchToWorksiteDistance: { type: "Length!" }) {
        createInstantCalculatorCostLines(salesRatesInput: $salesRatesInput, branchToWorksiteDistance: $branchToWorksiteDistance)
          @required(action: THROW) {
          id
          kind
          craneIndex
          billingCode @required(action: THROW) {
            id
            code
            subCode
            label
            billingSection {
              shortDescription
            }
          }
          billingCodeCategory
          isFractionAllowed
          isFixedQuantity
          defaultQuantity
          requireWorkForceType
          salesRateResult {
            canEditRate
            value {
              isAnnualContract
              isFlexiblePrice
              minimumQuantity
              price
              createdAt
            }
            error {
              code
              description
            }
          }
          workForceType {
            id
            label
            code
          }
          quantity
          rate
          billable
        }
      }
    `,
    $key,
  );

  const updateInstantCalculator = useEffectEvent(
    (
      saleInstantCalculatorCostLines: SaleApplyInstantCalculatorButton_useSaleApplyInstantCalculatorFragment$data['createInstantCalculatorCostLines'],
    ) => {
      const newCostLines = saleInstantCalculatorCostLines.map(
        ({ quantity, rate, billable, billingCodeCategory, defaultQuantity, kind, salesRateResult, ...rest }) => {
          const costLine: CostLine = {
            ...rest,
            kind: castCostLineKind(kind),
            billingCodeCategory: asBillingCodeCategory(billingCodeCategory),
            defaultQuantity: defaultQuantity ?? null,
            quantity: quantity ?? null,
            rate: Price.fromApi(rate),
            billable: billable ?? null,
            salesRateResult: salesRateResult
              ? {
                  etag: nanoid(),
                  canEditRate: salesRateResult.canEditRate,
                  value: salesRateResult.value ?? null,
                  error: salesRateResult.error ?? null,
                }
              : null,
          };

          return flagNew(costLine);
        },
      );

      setCostLines((prevState) => [
        ...prevState.map((costLine) => {
          if (costLine.kind !== 'instantCalculator') {
            return costLine;
          }

          return flagRemoved(costLine);
        }),
        ...newCostLines,
      ]);
      endOperation();
    },
  );

  useEffect(() => {
    if (!$data || !$key) {
      return;
    }
    updateInstantCalculator($data.createInstantCalculatorCostLines);
  }, [$data, $key, endOperation, updateInstantCalculator]);
}

function useSalesRateInput($key: SaleApplyInstantCalculatorButton_useSalesRateInputFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleApplyInstantCalculatorButton_useSalesRateInputFragment on ISale {
        clientBase {
          ...ClientBaseFields_AssignedClientFragment
        }
        projectBase {
          ...ProjectBaseFields_ArrivalDateFragment @arguments(isCopy: false)
          ...ProjectBaseFields_AssignedWorksiteFragment
          assignedWorksiteInfo {
            ...ProjectBaseFields_WorksiteOverrides_NameFragment
            ...ProjectBaseFields_WorksiteOverrides_LocationFragment
          }
        }
        project {
          ...SaleProjectFields_NatureOfWorkFragment
          ...SaleProjectFields_DispatchBranchFragment
        }
        equipmentBase {
          craneSelector {
            ...JobEquipment_useCraneSelectorFavoriteFragment
          }
        }
      }
    `,
    $key,
  );

  const { favorite } = useCraneSelectorFavorite($data?.equipmentBase.craneSelector, false);
  const { arrivalDate } = useFieldArrivalDateValue($data?.projectBase);
  const { assignedClient } = useFieldAssignedClient($data?.clientBase);
  const { dispatchBranch } = useFieldDispatchBranch($data?.project, false);
  const { assignedWorksite } = useFieldAssignedWorksite($data?.projectBase);
  const { worksiteOverridesName } = useFieldWorksiteOverridesNameValue($data?.projectBase.assignedWorksiteInfo);
  const { worksiteOverridesLocation } = useFieldWorksiteOverridesLocationValue($data?.projectBase.assignedWorksiteInfo);
  const { natureOfWork } = useFieldNatureOfWork($data?.project, false);

  return useMemo(() => {
    if (
      !(
        favorite?.boomConfiguration.id &&
        assignedClient &&
        (assignedWorksite || (!!worksiteOverridesName && !!worksiteOverridesLocation.address)) &&
        natureOfWork &&
        arrivalDate &&
        arrivalDate.isValid &&
        dispatchBranch
      )
    ) {
      return null;
    }

    const parsedArrivalDate = arrivalDate.toJSON();
    if (!parsedArrivalDate) return null;

    const equipments: Array<AutomaticCostLineEquipmentInput | null> = [
      {
        boomConfigurationId: favorite.boomConfiguration.id,
        vehicleId: favorite.vehicleId?.key ?? null,
      },
      ...(favorite.additionalCranes.map((ac) =>
        ac.deletedAt || !ac.boomConfiguration?.id
          ? null
          : {
              boomConfigurationId: ac.boomConfiguration.id,
              vehicleId: null,
            },
      ) ?? []),
    ];

    return {
      equipments: equipments,
      arrivalDate: parsedArrivalDate,
      clientId: assignedClient.id,
      dispatchBranchId: dispatchBranch.id,
      worksiteId: assignedWorksite?.id && assignedWorksite.id !== 'new' ? assignedWorksite.id : null,
      natureOfWorkCode: natureOfWork.code,
    };
  }, [
    arrivalDate,
    assignedClient,
    assignedWorksite,
    dispatchBranch,
    favorite?.additionalCranes,
    favorite?.boomConfiguration.id,
    favorite?.vehicleId?.key,
    natureOfWork,
    worksiteOverridesLocation.address,
    worksiteOverridesName,
  ]);
}
