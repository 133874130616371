/**
 * @generated SignedSource<<44d3bddf39b05a23695b5b0c813cda48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BillingCodeCategory = "accessories" | "boomAndCounterWeightTransport" | "equipment" | "escort" | "fixedFees" | "flatbedTransport" | "labor" | "movingFees" | "otherFees" | "permit" | "rateKind" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CostLinesFields_useSetBillingCodeAdditionalDataFragment$data = {
  readonly billingCodeCategory: BillingCodeCategory;
  readonly defaultQuantity: number | null | undefined;
  readonly isFixedQuantity: boolean;
  readonly isFractionAllowed: boolean;
  readonly " $fragmentType": "CostLinesFields_useSetBillingCodeAdditionalDataFragment";
};
export type CostLinesFields_useSetBillingCodeAdditionalDataFragment$key = {
  readonly " $data"?: CostLinesFields_useSetBillingCodeAdditionalDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CostLinesFields_useSetBillingCodeAdditionalDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CostLinesFields_useSetBillingCodeAdditionalDataFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingCodeCategory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFractionAllowed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFixedQuantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultQuantity",
      "storageKey": null
    }
  ],
  "type": "BillingCode",
  "abstractKey": null
};

(node as any).hash = "611e0dfcd3f01a4edf507d970216fcbf";

export default node;
