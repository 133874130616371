/**
 * @generated SignedSource<<b6cb8cb89b7a390b896251004ce936f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BillingCodeCategory = "accessories" | "boomAndCounterWeightTransport" | "equipment" | "escort" | "fixedFees" | "flatbedTransport" | "labor" | "movingFees" | "otherFees" | "permit" | "rateKind" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CostLinesFields_useResetWorkForceTypeFragment$data = {
  readonly billingCodeCategory: BillingCodeCategory;
  readonly " $fragmentType": "CostLinesFields_useResetWorkForceTypeFragment";
};
export type CostLinesFields_useResetWorkForceTypeFragment$key = {
  readonly " $data"?: CostLinesFields_useResetWorkForceTypeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CostLinesFields_useResetWorkForceTypeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CostLinesFields_useResetWorkForceTypeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingCodeCategory",
      "storageKey": null
    }
  ],
  "type": "BillingCode",
  "abstractKey": null
};

(node as any).hash = "d5d356af9218bc61ffd76e63fa319441";

export default node;
