/**
 * @generated SignedSource<<f6494750c32e9236b1eac7b4ded48b75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as natureOfWorkLookupLabelResolverType } from "../../../__resolvers__/NatureOfWorkLabelResolver";
export type AccessoryRuleFields_NatureOfWorkFragment$data = {
  readonly conditions: {
    readonly natureOfWorkLookups: ReadonlyArray<{
      readonly code: number;
      readonly id: string;
      readonly label: Record<string, string> | null | undefined;
    }>;
  };
  readonly " $fragmentType": "AccessoryRuleFields_NatureOfWorkFragment";
};
export type AccessoryRuleFields_NatureOfWorkFragment$key = {
  readonly " $data"?: AccessoryRuleFields_NatureOfWorkFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryRuleFields_NatureOfWorkFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryRuleFields_NatureOfWorkFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessoryConditionsInternal",
      "kind": "LinkedField",
      "name": "conditions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NatureOfWorkLookup",
          "kind": "LinkedField",
          "name": "natureOfWorkLookups",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "fragment": {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NatureOfWorkLabelResolver"
              },
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../../__resolvers__/NatureOfWorkLabelResolver').label,
              "path": "conditions.natureOfWorkLookups.label"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccessoryRule",
  "abstractKey": null
};

(node as any).hash = "2b923fe1af1768ec324e2b1abad06d02";

export default node;
