import { TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent, ChangeEventHandler, FocusEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useEffectEvent } from '../utils/effectUtils';
import { Price, PriceFormat } from '../utils/price';
import { useTranslation } from 'react-i18next';

export type PriceInputProps = {
  value: Price | null;
  onChange?: (value: Price | null, event: ChangeEvent<HTMLInputElement>) => void;
  min?: number | Price | null;
  max?: number | Price | null;
} & Omit<TextFieldProps, 'value' | 'onChange'>;

/**
 * Displays and manipulates a Price value as a TextField with automatic cleanup and type conversion.
 */
export function PriceInput({ value: inputValue, onChange, onFocus, onBlur, min = 0, max, inputProps, ...rest }: PriceInputProps) {
  const { t } = useTranslation();

  const [value, setValue] = useState<Price | null>(inputValue);
  const [text, setText] = useState<string>(() => inputValue?.format(t, PriceFormat.EDIT) ?? '');
  const [focused, setFocused] = useState<boolean>(false);

  const formattedValue = useMemo(() => (focused ? text : (value?.format(t) ?? '')), [focused, t, text, value]);

  const syncInputValue = useEffectEvent((newValue: PriceInputProps['value']) => {
    if (newValue !== value) {
      setValue(newValue);
      setText(newValue?.format(t, PriceFormat.EDIT) ?? '');
    }
  });

  useEffect(() => {
    syncInputValue(inputValue);
  }, [inputValue, syncInputValue]);

  // TODO the following lines can throw if min/max are NaN/-Infinity/+Infinity - fix?
  const minPrice = useMemo(() => (min == null || min === -Infinity ? null : new Price(min)), [min]);
  const maxPrice = useMemo(() => (max == null || max === +Infinity ? null : new Price(max)), [max]);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setText(event.target.value);
      const newValue = Price.fromUi(event.target.value, t)?.clamp(minPrice, maxPrice) ?? null;

      if (newValue !== value) {
        setValue(newValue);
        onChange?.(newValue, event);
      }
    },
    [maxPrice, minPrice, onChange, t, value],
  );

  const handleFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
    (event) => {
      setFocused(true);
      onFocus?.(event);
    },
    [onFocus],
  );

  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    (event) => {
      if (value) {
        setText(value.format(t, PriceFormat.EDIT));
      }
      setFocused(false);
      onBlur?.(event);
    },
    [onBlur, t, value],
  );

  const actualInputProps = useMemo<TextFieldProps['inputProps']>(
    () => ({
      ...inputProps,
      style: {
        textAlign: 'right',
        ...inputProps?.style,
      },
    }),
    [inputProps],
  );

  return (
    <TextField
      {...rest}
      value={formattedValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      inputProps={actualInputProps}
    />
  );
}
