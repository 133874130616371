import { WorkPlanningStatus } from '../__enums__/WorkPlanningStatus';
import { Chip, ChipProps, SxProps, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';

interface Props {
  statuses: readonly WorkPlanningStatus[];
  compact?: boolean;
}

type ChipIconAndColor = Pick<ChipProps, 'icon' | 'color'>;

const getChipIconAndColor = (status: WorkPlanningStatus): ChipIconAndColor => {
  switch (status) {
    case 'inWriting':
      return {
        icon: <EditIcon fontSize='inherit' />,
        color: 'draft',
      };
    case 'worksiteSelected':
      return {
        icon: <CheckIcon fontSize='inherit' />,
        color: 'advanced',
      };
    case 'completed':
      return {
        icon: <SendIcon fontSize='inherit' />,
        color: 'archived',
      };
    case 'canceled':
      return {
        icon: <CancelIcon fontSize='inherit' />,
        color: 'cancel',
      };
    default:
      return {
        icon: undefined,
        color: 'default',
      };
  }
};

const statusPriorities: Record<WorkPlanningStatus, number> = {
  canceled: 1,
  completed: 2,
  worksiteSelected: 3,
  inWriting: 4,
  locked: 5,
};

function orderVisibleStatusesByPriority(statuses: readonly WorkPlanningStatus[]): WorkPlanningStatus[] {
  return [...statuses].filter((s) => s !== 'locked').sort((a, b) => statusPriorities[a] - statusPriorities[b]);
}

function getMainStatus(statuses: readonly WorkPlanningStatus[]): WorkPlanningStatus | undefined {
  return orderVisibleStatusesByPriority(statuses)[0];
}

export function WorkPlanningStatusChip({ statuses, compact, ...rest }: Props & Omit<ChipProps, 'icon' | 'color' | 'label'>) {
  const { t } = useTranslation('workPlanning');
  const status = getMainStatus(statuses);

  if (!status) return null;

  if (compact) return <WorkPlanningStatusIcon status={status} />;

  return <Chip {...getChipIconAndColor(status)} label={t(`status.${status}`)} size='small' {...rest} />;
}

function WorkPlanningStatusIcon({ status }: { status: WorkPlanningStatus }) {
  const theme = useTheme();

  const sx = (bgColor: string, color?: string): SxProps => ({
    backgroundColor: bgColor,
    borderRadius: '1rem',
    border: `0.25rem solid ${bgColor}`,
    color: color,
  });

  switch (status) {
    case 'inWriting':
      return <EditIcon sx={sx(theme.palette.draft.main, theme.palette.draft.contrastText)} />;
    case 'worksiteSelected':
      return <CheckIcon sx={sx(theme.palette.advanced.main, theme.palette.advanced.contrastText)} />;
    case 'completed':
      return <SendIcon sx={sx(theme.palette.done.main, theme.palette.done.contrastText)} />;
    case 'canceled':
      return <CancelIcon sx={sx(theme.palette.cancel.main, theme.palette.cancel.contrastText)} />;
    default:
      return null;
  }
}
