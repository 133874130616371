import { RateStrategyCategory } from '../../__enums__/RateStrategyCategory';

const addenda = {
  delete: 'Supprimer l’addenda',
  deleteMessage: 'Voulez-vous vraiment supprimer cet addenda\u00a0?',
  edit: 'Modifier l’addenda',
  field: {
    templateEn: 'Description EN',
    templateFr: 'Description FR',
    kinds: 'Types',
    natureOfWorks: 'Natures de travail',
    natureOfWorkSubCategory: 'Catégories de nature du travail',
    specialSituation: 'Situations particulières',
    specialProjects: 'Projets spéciaux',
    kind: 'Type',
    title: 'Titre',
    titleEn: 'Titre EN',
    titleFr: 'Titre FR',
  },
  information: 'Information',
  conditions: 'Conditions',
  new: 'Nouvel addenda',
};

const section = {
  addenda: 'Addendas',
  craneCharts: 'Chartes de grues',
  competitors: 'Compétiteurs',
  dataSync: 'Sync. des données',
  enums: 'Énums.',
  rateStrategies: 'Stratégie de taux',
};

const dialog = {
  title: 'Désactiver la charte',
  body: 'Toutes les données de cette charte de grue seront effacées',
  confirmButton: 'Désactiver',
};

const craneChartList = {
  column: {
    name: 'Nom',
    make: 'Marque',
    model: 'Modèle',
    equipmentKind: 'Genre',
    capacity: 'Capacité',
    updatedAt: 'Mis à jour le',
    isActive: 'Actif',
    status: 'Statut',
  },
};

const competitors = {
  title: 'Competitors',
  name: 'Nom du compétiteur',
  placeholder: 'Entrez le nom du compétiteur',
};

const seeding = {
  button: 'Toutes les données',
  buttonGenerated: 'Données générées',
  buttonImported: 'Données importées',
  fail: 'Impossible d’injecter les données, vérifier la console pour une erreur',
  success: 'Les données ont été injectées avec succès',
  confirmationText: 'Voulez-vous injecter les données\u00a0?',
  confirmationButton: 'Injecter',
  title: 'Confirmation',
};

const deleting = {
  title: 'Confirmation',
  confirmationText: 'Voulez-vous supprimer toutes les données\u00a0?',
  confirmButton: 'Supprimer',
  button: 'Toutes les données',
  success: 'Données supprimées avec succès',
  fail: 'Une erreur est survenue, voir la console pour plus de détails',
};

const synchronization = {
  title: 'Confirmation',
  confirmationText: 'Voulez-vous resynchroniser {{entity}}\u00a0?',
  confirmationButton: 'Synchronizer',
  success: 'Demande de synchronization réussie',
  fail: 'Demande de synchronization échoué pour {{entity}}',
};

const dataSyncSection = {
  deleteSectionTitle: 'Suppression de données',
  seedSectionTitle: 'Injection de données',
  syncSectionTitle: 'Synchronization des données',
  deleting,
  seeding,
  synchronization,
};

const enums = {
  billingCode: {
    code: 'Code',
    description: 'Description',
    labelEn: 'Étiquette anglais',
    labelFr: 'Étiquette français',
    title: 'Codes de facturation (accessoires)',
  },
  natureOfWork: {
    code: 'Code',
    defaultWorkSchedule: 'Horaire de travail par défaut',
    description: 'Description',
    title: 'Natures du travail',
    workSchedules: 'Horaires de travail',
    natureOfWorkSubCategories: 'Catégories',
  },
};

const rateStrategies = {
  labelEn: 'Étiquette anglais',
  labelFr: 'Étiquette français',
  edit: 'Modifier la stratégie de taux',
  new: 'Nouvelle stratégie de taux',
  label: 'Étiquette',
  categories: 'Catégories',
  delete: 'Supprimer la stratégie de taux',
  deleteMessage: 'Voulez-vous vraiment supprimer cette stratégie de taux\u00a0?',
  transportCategoryKinds: 'Catégories de transport',
  rateStrategyCategory: {
    mainCrane: 'Grue principale',
    additionalCrane: 'Grue additionnelle',
    boomCounterweightTransport: 'Transport de flèche et contrepoids',
    flatbedTransport: 'Transport par fardier',
  } satisfies Record<RateStrategyCategory, string>,
};

export const configuration = {
  addenda,
  configuration: 'Configuration',
  craneCharts: {
    list: craneChartList,
    importSuccessMessage_one: '{{count}} fichier transféré avec succès.',
    importSuccessMessage_other: '{{count}} fichiers transférés avec succès.',
    importErrorMessage: 'Une erreur est survenue lors du transfert des fichiers.',
    unexpectedError: 'Une erreur inattendue est survenue. Veuillez réessayer.',
  },
  competitors,
  dataSyncSection,
  enums,
  error: {
    duringCraneChartDeactivation: 'Une erreur est survenue lors de la désactivation de la charte de grue',
    errorDuringDelete: 'Une erreur est survenue lors de la suppression de l’élément',
    errorDuringSaveCompetitor: 'Une erreur est survenue lors de la sauvegarde des compétiteurs',
    errorDuringSaveBillingCodes: 'Une erreur est survenue lors de la sauvegarde des codes de facturation',
    errorDuringSaveNatureOfWorks: 'Une erreur est survenue lors de la sauvegarde de la nature du travail',
    errorDuringUpload: 'Une erreur est survenue lors du transfert des fichiers',
  },
  rateStrategies,
  section,
  dialog,
};
