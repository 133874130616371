import { Suspense, useCallback } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { ListLayout, SidebarContentProps } from '../../layout/Layouts';
import { RequireAdmin, UnauthorizedFallback } from '../../auth/Authorization';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { namedPageTitle, usePageTitle } from '../../common/hooks/usePageTitle';
import { NavigationMenu } from '../../layout/SidebarDrawer';
import { Button, Skeleton } from '@mui/material';
import { BillingCodeRuleList } from './BillingCodeRuleList';
import { ErrorBanner } from '../../common/components/ErrorBanner';
import { BillingCodeRulesTabs } from '../BillingCodeRulesTabs';
import { TabBillingCodeRulesQuery } from './__generated__/TabBillingCodeRulesQuery.graphql';
import { BillingCodeRulesPageOutletContext } from '../BillingCodeRulesPage';

export function TabBillingCodeRules() {
  const { t } = useAmbientTranslation();
  usePageTitle(namedPageTitle('sidebar.billingCodeRules'));
  const navigate = useNavigate();
  const $key = useOutletContext<BillingCodeRulesPageOutletContext>().tabBillingCodeRules$key;

  const $data = useFragment(
    graphql`
      fragment TabBillingCodeRulesFragment on Query {
        ...AuthorizationAdminFragment
        ...SidebarDrawerFragment
        ...LayoutsListLayoutFragment
      }
    `,
    $key,
  );

  const handleCreateClick = useCallback(() => navigate('/billing-code-rules/cost-line-rules/new'), [navigate]);
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  return (
    <RequireAdmin
      $key={$data}
      fallback={
        <ListLayout heading={t('billingCodeRule')} sidebarProvider={sidebar} $key={$data}>
          <UnauthorizedFallback />
        </ListLayout>
      }>
      <ListLayout
        heading={t('title')}
        sidebarProvider={sidebar}
        $key={$data}
        actions={<Button onClick={handleCreateClick}>{t('button.create', { ns: 'common' })}</Button>}>
        <ErrorBanner />
        <BillingCodeRulesTabs tab='cost-line-rules'></BillingCodeRulesTabs>

        <Suspense fallback={<ContentSkeleton />}>
          <BillingCodeRulesListContent />
        </Suspense>
      </ListLayout>
    </RequireAdmin>
  );
}

function BillingCodeRulesListContent() {
  const $data = useLazyLoadQuery<TabBillingCodeRulesQuery>(
    graphql`
      query TabBillingCodeRulesQuery {
        ...BillingCodeRuleListFragment
      }
    `,
    {},
    { fetchPolicy: 'store-and-network' },
  );

  return <BillingCodeRuleList $key={$data} />;
}

function ContentSkeleton() {
  return <Skeleton variant='rounded' height='30rem' />;
}
