import { SetValueFn } from '../common/utils/forms';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { useCallback } from 'react';
import { ForwardSaleKindAutocompleteProps, SaleKindAutocomplete } from '../common/components/SaleKindAutocomplete';
import { Chip } from '@mui/material';
import { CraneCapacityAutocomplete, ForwardCraneCapacityAutocompleteProps } from '../common/components/CraneCapacityAutocomplete';
import { EquipmentKindAutocomplete, ForwardEquipmentKindAutocompleteProps } from '../common/components/EquipmentKindAutocomplete';
import { ClientAutocomplete, ForwardClientAutocompleteProps } from '../common/components/ClientAutocomplete';
import { ForwardNatureOfWorkAutocompleteProps, NatureOfWorkAutocomplete } from '../common/components/NatureOfWorkAutocomplete';
import {
  ForwardNatureOfWorkSubCategoryAutocompleteProps,
  NatureOfWorkSubCategoryAutocomplete,
} from '../common/components/NatureOfWorkSubCategoryAutocomplete';
import { ForwardQuestionKindAutocompleteProps, QuestionKindAutocomplete } from '../common/components/QuestionKindAutocomplete';
import { specialProjects } from '../jobs/fields/QuestionsBaseFields';

export type SaleKinds = NonNullable<ForwardSaleKindAutocompleteProps<true>['value']>;
export function SaleKindsInput({ value, setValue }: { value: SaleKinds; setValue: SetValueFn<SaleKinds> }) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback<NonNullable<ForwardSaleKindAutocompleteProps<true>['onChange']>>(
    (_, v) => {
      setValue([...v]);
    },
    [setValue],
  );

  return (
    <SaleKindAutocomplete multiple value={value} onChange={handleChange} textFieldProps={() => ({ label: t('fields.label.kinds') })} />
  );
}

export const EMPTY_VEHICLES_ID: string[] = [];

export function CapacitiesInput({
  value,
  setValue,
}: {
  value: ForwardCraneCapacityAutocompleteProps<true>['value'];
  setValue: SetValueFn<ForwardCraneCapacityAutocompleteProps<true>['value']>;
}) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback<NonNullable<ForwardCraneCapacityAutocompleteProps<true>['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  return (
    <CraneCapacityAutocomplete
      multiple
      vehicleIds={EMPTY_VEHICLES_ID}
      equipmentKindCode={null}
      configurationKindCode={null}
      value={value}
      onChange={handleChange}
      textFieldProps={() => ({ label: t('fields.label.capacities') })}
    />
  );
}

export function EquipmentKindsInput({
  value,
  setValue,
}: {
  value: ForwardEquipmentKindAutocompleteProps<true>['value'];
  setValue: SetValueFn<ForwardEquipmentKindAutocompleteProps<true>['value']>;
}) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback<NonNullable<ForwardEquipmentKindAutocompleteProps<true>['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  return (
    <EquipmentKindAutocomplete
      multiple
      capacity={null}
      vehicleIds={EMPTY_VEHICLES_ID}
      configurationKindCode={null}
      value={value}
      onChange={handleChange}
      textFieldProps={() => ({ label: t('fields.label.equipmentKinds') })}
    />
  );
}

export type Clients = NonNullable<ForwardClientAutocompleteProps<true>['value']>;
export function ClientsInput({ value, setValue }: { value: Clients; setValue: SetValueFn<Clients> }) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback<NonNullable<ForwardClientAutocompleteProps<true>['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );
  return (
    <ClientAutocomplete
      multiple
      value={value}
      onChange={handleChange}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const handleDelete = getTagProps({ index }).onDelete;
          return <Chip key={option.id} onDelete={handleDelete} label={`${option.number}-${option.name}`} size='small' />;
        })
      }
      textFieldProps={() => ({ label: t('fields.label.clients') })}
    />
  );
}

export type NatureOfWork = NonNullable<ForwardNatureOfWorkAutocompleteProps<true>['value']>;
export function NatureOfWorksAutocompleteInput({ value, setValue }: { value: NatureOfWork; setValue: SetValueFn<NatureOfWork> }) {
  const { t } = useAmbientTranslation();
  const handleChange = useCallback<NonNullable<ForwardNatureOfWorkAutocompleteProps<true>['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  return (
    <NatureOfWorkAutocomplete
      multiple
      value={value}
      onChange={handleChange}
      textFieldProps={() => ({ label: t('fields.label.natureOfWorks') })}
    />
  );
}

export type NatureOfWorkSubCategories = NonNullable<ForwardNatureOfWorkSubCategoryAutocompleteProps<true>['value']>;
export function NatureOfWorkSubCategoriesInput({
  value,
  setValue,
}: {
  value: NatureOfWorkSubCategories;
  setValue: SetValueFn<NatureOfWorkSubCategories>;
}) {
  const { t } = useAmbientTranslation();
  const handleChange = useCallback<NonNullable<ForwardNatureOfWorkSubCategoryAutocompleteProps<true>['onChange']>>(
    (v) => setValue(v),
    [setValue],
  );
  return (
    <NatureOfWorkSubCategoryAutocomplete
      multiple
      value={value}
      onChange={handleChange}
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`natureOfWorkSubCategories.${o}`, { ns: 'common' })}
      textFieldProps={() => ({
        label: t('fields.label.natureOfWorkSubCategory'),
      })}
    />
  );
}

export type BaseQuestions = NonNullable<ForwardQuestionKindAutocompleteProps<true>['value']>;
export function BaseQuestionsInput({ value, setValue }: { value: BaseQuestions; setValue: SetValueFn<BaseQuestions> }) {
  const { t } = useAmbientTranslation();
  const handleChange = useCallback<NonNullable<ForwardQuestionKindAutocompleteProps<true>['onChange']>>((_, v) => setValue(v), [setValue]);

  return (
    <QuestionKindAutocomplete
      multiple
      value={value}
      onChange={handleChange}
      groupBy={(o) => (specialProjects.includes(o) ? t('fields.specialProjects') : '')}
      textFieldProps={() => ({
        label: t('fields.label.specialSituation'),
      })}
    />
  );
}
