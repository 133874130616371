/**
 * @generated SignedSource<<cc94eb805293818749276926a898f304>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NatureOfWorkSubCategoryEnum = "accessory" | "airports" | "antennas" | "aqueductsSewer" | "blade" | "bridges" | "cages" | "chambers" | "dams" | "demolition" | "distribution" | "docks" | "excavation" | "gearboxes" | "generator" | "gondola" | "hand" | "handling" | "landscaping" | "lifting" | "lines" | "modules" | "newConstruction" | "nonOperated" | "powerPlants" | "pruning" | "pylons" | "refurbishment" | "retentionBasins" | "roadsRails" | "rotor" | "shelters" | "substations" | "towers" | "transformers" | "transport" | "ventilationPlumbingElectrical" | "%future added value";
export type ServiceCallKind = "accessoriesRental" | "bare" | "bareWithOperators" | "laborRental" | "laborRentalOnBare" | "laborRentalOnOperated" | "liftingPlan" | "liftingTest" | "operatedHourly" | "operatedMonthly" | "rollingEquipment" | "storage" | "%future added value";
export type SuggestionPromptInput = {
  accessoryTypes: ReadonlyArray<string>;
  capacity?: number | null | undefined;
  clientId?: string | null | undefined;
  clientRequirements: ReadonlyArray<string>;
  dispatchBranchId?: string | null | undefined;
  equipmentKindCode?: string | null | undefined;
  hoursPerDay?: number | null | undefined;
  inTheCareOf?: string | null | undefined;
  isSecondServing?: boolean | null | undefined;
  lengthInDays?: number | null | undefined;
  natureOfWorkCode?: string | null | undefined;
  natureOfWorkSubCategory?: NatureOfWorkSubCategoryEnum | null | undefined;
  representativeId?: string | null | undefined;
  salesBranchId?: string | null | undefined;
  serviceCallKind?: ServiceCallKind | null | undefined;
  vehicleIds: ReadonlyArray<string>;
  workDescription?: string | null | undefined;
  worksiteId?: string | null | undefined;
  worksiteRequirements: ReadonlyArray<string>;
};
export type SaleProjectFields_WorkDescription_SuggestionsQuery$variables = {
  scoreThreshold: number;
  suggestionCount: number;
  suggestionPrompt: SuggestionPromptInput;
};
export type SaleProjectFields_WorkDescription_SuggestionsQuery$data = {
  readonly suggestedWorkDescriptions: ReadonlyArray<{
    readonly score: number;
    readonly value: string;
  }>;
};
export type SaleProjectFields_WorkDescription_SuggestionsQuery = {
  response: SaleProjectFields_WorkDescription_SuggestionsQuery$data;
  variables: SaleProjectFields_WorkDescription_SuggestionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scoreThreshold"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suggestionCount"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suggestionPrompt"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "count",
        "variableName": "suggestionCount"
      },
      {
        "kind": "Variable",
        "name": "prompt",
        "variableName": "suggestionPrompt"
      },
      {
        "kind": "Variable",
        "name": "scoreThreshold",
        "variableName": "scoreThreshold"
      }
    ],
    "concreteType": "SuggestionOfString",
    "kind": "LinkedField",
    "name": "suggestedWorkDescriptions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "score",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SaleProjectFields_WorkDescription_SuggestionsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SaleProjectFields_WorkDescription_SuggestionsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "fb3652ca1cdc644801fdf7594dcfc082",
    "id": null,
    "metadata": {},
    "name": "SaleProjectFields_WorkDescription_SuggestionsQuery",
    "operationKind": "query",
    "text": "query SaleProjectFields_WorkDescription_SuggestionsQuery(\n  $scoreThreshold: Float!\n  $suggestionPrompt: SuggestionPromptInput!\n  $suggestionCount: Int!\n) {\n  suggestedWorkDescriptions(scoreThreshold: $scoreThreshold, prompt: $suggestionPrompt, count: $suggestionCount) {\n    value\n    score\n  }\n}\n"
  }
};
})();

(node as any).hash = "841fc07a01d2b26b5c71f2c28dfe58db";

export default node;
