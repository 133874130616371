/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const costLineKinds = ["automatic", "instantCalculator", "manual", "quoteCalculator"] as const;
export type CostLineKind = typeof costLineKinds[number];

export function isCostLineKind(value: string): value is CostLineKind {
  return costLineKinds.includes(value)
}

export function asCostLineKind(value: string): CostLineKind | null {
  return isCostLineKind(value) ? value : null;
}

export function castCostLineKind(value: string): CostLineKind {
  if(!isCostLineKind(value)) {
    throw new Error(`Invalid Enum value for type "CostLineKind": "${value}"`);
  }
  
  return value;
}
