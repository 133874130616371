/**
 * @generated SignedSource<<f4c98eb0a8093558f664ccf7c7261bd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BillingCodeCategory = "accessories" | "boomAndCounterWeightTransport" | "equipment" | "escort" | "fixedFees" | "flatbedTransport" | "labor" | "movingFees" | "otherFees" | "permit" | "rateKind" | "%future added value";
export type CostLineKind = "automatic" | "instantCalculator" | "manual" | "quoteCalculator" | "%future added value";
import { FragmentRefs } from "relay-runtime";
import { label as billingCodeLabelResolverType } from "../../__resolvers__/BillingCodeLabelResolver";
import { label as workForceTypeLookupLabelResolverType } from "../../__resolvers__/WorkForceTypeLabelResolver";
export type SaleApplyInstantCalculatorButton_useSaleApplyInstantCalculatorFragment$data = {
  readonly createInstantCalculatorCostLines: ReadonlyArray<{
    readonly billable: boolean | null | undefined;
    readonly billingCode: {
      readonly billingSection: {
        readonly shortDescription: Record<string, string>;
      } | null | undefined;
      readonly code: number;
      readonly id: string;
      readonly label: Record<string, string> | null | undefined;
      readonly subCode: number;
    };
    readonly billingCodeCategory: BillingCodeCategory;
    readonly craneIndex: number;
    readonly defaultQuantity: number | null | undefined;
    readonly id: string;
    readonly isFixedQuantity: boolean;
    readonly isFractionAllowed: boolean;
    readonly kind: CostLineKind;
    readonly quantity: number | null | undefined;
    readonly rate: string | null | undefined;
    readonly requireWorkForceType: boolean;
    readonly salesRateResult: {
      readonly canEditRate: boolean;
      readonly error: {
        readonly code: string;
        readonly description: string;
      } | null | undefined;
      readonly value: {
        readonly createdAt: string;
        readonly isAnnualContract: boolean;
        readonly isFlexiblePrice: boolean;
        readonly minimumQuantity: number;
        readonly price: string;
      } | null | undefined;
    } | null | undefined;
    readonly workForceType: {
      readonly code: number;
      readonly id: string;
      readonly label: Record<string, string> | null | undefined;
    } | null | undefined;
  }>;
  readonly " $fragmentType": "SaleApplyInstantCalculatorButton_useSaleApplyInstantCalculatorFragment";
};
export type SaleApplyInstantCalculatorButton_useSaleApplyInstantCalculatorFragment$key = {
  readonly " $data"?: SaleApplyInstantCalculatorButton_useSaleApplyInstantCalculatorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleApplyInstantCalculatorButton_useSaleApplyInstantCalculatorFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "branchToWorksiteDistance"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "salesRatesInput"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleApplyInstantCalculatorButton_useSaleApplyInstantCalculatorFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "branchToWorksiteDistance",
            "variableName": "branchToWorksiteDistance"
          },
          {
            "kind": "Variable",
            "name": "salesRatesInput",
            "variableName": "salesRatesInput"
          }
        ],
        "concreteType": "CostLine",
        "kind": "LinkedField",
        "name": "createInstantCalculatorCostLines",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kind",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "craneIndex",
            "storageKey": null
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "BillingCode",
              "kind": "LinkedField",
              "name": "billingCode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "fragment": {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "BillingCodeLabelResolver"
                  },
                  "kind": "RelayResolver",
                  "name": "label",
                  "resolverModule": require('./../../__resolvers__/BillingCodeLabelResolver').label,
                  "path": "createInstantCalculatorCostLines.billingCode.label"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BillingSection",
                  "kind": "LinkedField",
                  "name": "billingSection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "shortDescription",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "createInstantCalculatorCostLines.billingCode"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "billingCodeCategory",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isFractionAllowed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isFixedQuantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultQuantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "requireWorkForceType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SalesRateResult",
            "kind": "LinkedField",
            "name": "salesRateResult",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canEditRate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SalesRate",
                "kind": "LinkedField",
                "name": "value",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isAnnualContract",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isFlexiblePrice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minimumQuantity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SalesRateError",
                "kind": "LinkedField",
                "name": "error",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkForceTypeLookup",
            "kind": "LinkedField",
            "name": "workForceType",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "fragment": {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "WorkForceTypeLabelResolver"
                },
                "kind": "RelayResolver",
                "name": "label",
                "resolverModule": require('./../../__resolvers__/WorkForceTypeLabelResolver').label,
                "path": "createInstantCalculatorCostLines.workForceType.label"
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "billable",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "createInstantCalculatorCostLines"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "ded67e4fe45ba83e35f380b479b9b3ef";

export default node;
