import graphql from 'babel-plugin-relay/macro';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { usePromiseMutation } from '../../common/hooks/usePromiseMutation';
import { useFormMappings, useFormValidate } from '../../common/utils/forms';
import { useCallback } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { DataID } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import { useErrorBanner } from '../../common/components/ErrorBanner';
import { _throw } from '../../common/utils/_throw';
import { NAVIGATE_PREVENT_BLOCK_STATE } from '../../common/hooks/usePreventNavigationOnFormDirty';
import { AccessoryRuleSaveButtonMutation, UpsertAccessoryRuleInput } from './__generated__/AccessoryRuleSaveButtonMutation.graphql';
import { accessoryRuleDetailsFormContext } from './AccessoryRuleFields';

declare module './AccessoryRuleFields' {
  interface AccessoryRuleFieldsMappings {
    save: UpsertAccessoryRuleInput['request'];
  }
}

export function AccessoryRuleSaveButton({ id }: { id: DataID | 'new' }) {
  const { t } = useAmbientTranslation();
  const navigate = useNavigate();
  const { dismissError, reportUnexpectedError, reportHandledError } = useErrorBanner();

  const [commit, isInFlight] = usePromiseMutation<AccessoryRuleSaveButtonMutation>(graphql`
    mutation AccessoryRuleSaveButtonMutation($input: UpsertAccessoryRuleInput!) {
      upsertAccessoryRule(input: $input) {
        accessoryRule {
          id
          ...AccessoryRuleDetailsPage_FormFragment
          ...AccessoryRuleListItemFragment
        }
        errors {
          __typename
          ... on SalesApiValidationError {
            ...ErrorBannerValidationErrorFragment
          }
        }
      }
    }
  `);

  const { mapAll } = useFormMappings(accessoryRuleDetailsFormContext);
  const validate = useFormValidate(accessoryRuleDetailsFormContext);

  const handleClick = useCallback(async () => {
    if (!validate('save')) return;

    dismissError(true);

    const saveRequest = mapAll('save');

    try {
      const { response } = await commit({
        variables: { input: { id: id === 'new' ? null : id, request: saveRequest } },
      });

      if (response?.upsertAccessoryRule.errors?.length) {
        reportHandledError(response.upsertAccessoryRule.errors, () => t('errorMessages.save', { ns: 'common' }));
        return;
      }

      if (id === 'new' && response) {
        const newId = response?.upsertAccessoryRule.accessoryRule?.id ?? _throw(new Error(t('error.errorDuringSaveAccessory')));
        navigate(`/billing-code-rules/accessory-rules/${newId}`, { replace: true, state: NAVIGATE_PREVENT_BLOCK_STATE });
      }
    } catch {
      reportUnexpectedError(() => t('errorMessages.save', { ns: 'common' }));
    }
  }, [commit, dismissError, id, mapAll, navigate, reportHandledError, reportUnexpectedError, t, validate]);
  return (
    <LoadingButton loadingPosition='start' startIcon={<SaveIcon />} onClick={handleClick} loading={isInFlight}>
      <span>{t('button.save', { ns: 'common' })}</span>
    </LoadingButton>
  );
}
