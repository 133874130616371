/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const billingCodeCategories = ["accessories", "boomAndCounterWeightTransport", "equipment", "escort", "fixedFees", "flatbedTransport", "labor", "movingFees", "otherFees", "permit", "rateKind"] as const;
export type BillingCodeCategory = typeof billingCodeCategories[number];

export function isBillingCodeCategory(value: string): value is BillingCodeCategory {
  return billingCodeCategories.includes(value)
}

export function asBillingCodeCategory(value: string): BillingCodeCategory | null {
  return isBillingCodeCategory(value) ? value : null;
}

export function castBillingCodeCategory(value: string): BillingCodeCategory {
  if(!isBillingCodeCategory(value)) {
    throw new Error(`Invalid Enum value for type "BillingCodeCategory": "${value}"`);
  }
  
  return value;
}
