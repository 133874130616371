import { SelectChangeEvent } from '@mui/material';
import Qty from 'js-quantities';
import { ForwardedRef, forwardRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnitInput } from './UnitInput';
import { isDecimalNumber } from '../utils/numberUtils';
import { defaultLogger, Logger } from '../utils/logging';
import { useEffectEvent } from '../utils/effectUtils';

const logger = new Logger(defaultLogger, 'MassInput', () => new Date().toISOString());

interface Props {
  value: string | null;
  enabledUnits: readonly MassUnit[];
  label: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  onChange: (val: string | null) => void;
}

export const massUnits = ['lbs', 'kg'] as const;
export type MassUnit = (typeof massUnits)[number];

function isMassUnit(val: unknown): val is MassUnit {
  return massUnits.includes(val as MassUnit);
}

const makeCurrentValue = (scalar: number | undefined, unit: MassUnit) => {
  if (scalar === undefined) {
    return null;
  }
  return `${scalar} ${unit}`;
};

export const MassInput = forwardRef(function MassInput(
  { value, enabledUnits, label, required, disabled, onChange: handleChange, error }: Props,
  ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>,
) {
  if (enabledUnits.length === 0) {
    throw new Error('Empty enabledUnits. At least one unit must be enabled.');
  }

  const [scalar, setScalar] = useState<number | undefined>(undefined);
  const [unit, setUnit] = useState<MassUnit>(enabledUnits[0]);
  const { t } = useTranslation('common');

  const setState = useCallback((sclr: typeof scalar, u: typeof unit) => {
    setScalar(sclr);
    setUnit(u);
  }, []);

  const syncState = useEffectEvent((val: string | null, enbldUnts: readonly MassUnit[]) => {
    if (!val) {
      // Since makeCurrentValue()'s return value is empty whenever the scalar is empty, when we get an empty value,
      // default to the current unit to avoid resetting the unit needlessly every time the scalar is cleared, unless it
      // isn't included in the enabled units anymore, in which case default to the first enabled unit.
      const defaultUnit = enbldUnts.includes(unit) ? unit : enbldUnts[0];
      return setState(undefined, defaultUnit);
    }

    const quantity = Qty.parse(val);
    if (!quantity) {
      logger.error("Couldn't parse value", val);
      return setState(undefined, enbldUnts[0]);
    }

    const qtyUnit = quantity.units();
    if (!isMassUnit(qtyUnit)) {
      logger.error('Detected unit is not a valid Mass unit', qtyUnit);
      return setState(undefined, enbldUnts[0]);
    }

    if (!enbldUnts.includes(qtyUnit)) {
      logger.error('Detected unit is not enabled', qtyUnit, enbldUnts);
      return setState(undefined, enbldUnts[0]);
    }

    return setState(quantity.scalar, qtyUnit);
  });
  useEffect(() => {
    syncState(value, enabledUnits);
  }, [syncState, value, enabledUnits]);

  const handleDropDownChange = useCallback(
    (event: SelectChangeEvent) => {
      const newUnit = isMassUnit(event.target.value) ? event.target.value : enabledUnits[0];
      setUnit(newUnit);
      handleChange(makeCurrentValue(scalar, newUnit));
    },
    [enabledUnits, handleChange, scalar],
  );

  const handleScalarChange = useCallback(
    (newValue: number | null, _: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (newValue != null && !Number.isNaN(newValue)) {
        setScalar(newValue);
        handleChange(makeCurrentValue(newValue, unit));
      } else {
        setScalar(undefined);
        handleChange(makeCurrentValue(undefined, unit));
      }
    },
    [handleChange, unit],
  );

  const handleBlur = useCallback(() => {
    if (scalar) {
      const { intValue } = isDecimalNumber(scalar);
      setScalar(intValue);
      handleChange(makeCurrentValue(intValue, unit));

      if (scalar < 0) {
        setScalar(0);
        handleChange(makeCurrentValue(0, unit));
      }
    }
  }, [handleChange, scalar, unit]);

  return (
    <UnitInput<MassUnit>
      ref={ref}
      supportedUnits={enabledUnits}
      onScalarChange={handleScalarChange}
      onUnitChange={handleDropDownChange}
      fieldLabel={label}
      scalar={scalar}
      unit={unit}
      onBlur={handleBlur}
      required={required}
      disabled={disabled}
      error={error}
      renderMenuValue={(v) => t(`unit.mass.short.${v}`)}
      renderAdornmentValue={(v) => t(`unit.mass.short.${v}`)}
    />
  );
});
