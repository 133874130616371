/**
 * A function that throws exceptions, meant to be used as a way to throw exceptions from an expression.
 *
 * The argument can either be:
 * - a `string`: a new {@link Error} object is created using the string as message, then thrown
 * - an {@link Error} instance: the error is thrown as-is
 * - an {@link Error} factory function: the function is called, then its return value is thrown as-is
 *
 * @example
 * ```ts
 * const parseDate = (dateString: string): Date | null { ... };
 * // The following lines are all equivalent:
 * const date = parseDate(str) ?? _throw('invalid date string');
 * const date = parseDate(str) ?? _throw(new Error('invalid date string'));
 * const date = parseDate(str) ?? _throw(() => new Error('invalid date string'));
 * // But not these, which throw a RangeError instead:
 * const date = parseDate(str) ?? _throw(new RangeError('invalid date string'));
 * const date = parseDate(str) ?? _throw(() => new RangeError('invalid date string'));
 * ```
 */
export function _throw(error: string | Error | (() => Error)): never {
  throw typeof error === 'function' ? error() : error instanceof Error ? error : new Error(error);
}
