/**
 * @generated SignedSource<<640d49af27fdd2f64dd9e5c276093dd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleApplyInstantCalculatorButtonFragment$data = {
  readonly costsBase: {
    readonly " $fragmentSpreads": FragmentRefs<"CostLinesFields_CostLineCollectionFragment">;
  };
  readonly equipment: {
    readonly " $fragmentSpreads": FragmentRefs<"SaleEquipmentFields_BranchToWorksiteDistanceFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SaleApplyInstantCalculatorButton_useSalesRateInputFragment">;
  readonly " $fragmentType": "SaleApplyInstantCalculatorButtonFragment";
};
export type SaleApplyInstantCalculatorButtonFragment$key = {
  readonly " $data"?: SaleApplyInstantCalculatorButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleApplyInstantCalculatorButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleApplyInstantCalculatorButtonFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleApplyInstantCalculatorButton_useSalesRateInputFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CostsInternalBase",
      "kind": "LinkedField",
      "name": "costsBase",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CostLinesFields_CostLineCollectionFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "equipment",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleEquipmentFields_BranchToWorksiteDistanceFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "521f2b0fc880d31634379719b825340b";

export default node;
