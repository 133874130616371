import { Component, ReactNode } from 'react';
import ErrorFallback from '../layout/ErrorFallback';
import { useTranslation } from 'react-i18next';
import { EmptyLayout } from '../layout/Layouts';
import * as Sentry from '@sentry/react';

export class EmployeeNotFoundError extends Error {
  constructor() {
    super('Employee not found');
  }
}

export class EmployeeNotFoundErrorBoundary extends Component<{ children?: ReactNode }, { hasError: boolean; error?: Error }> {
  constructor(props: { children?: JSX.Element }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error) {
    if (error instanceof EmployeeNotFoundError) {
      Sentry.captureException(error);
    } else {
      throw error;
    }
  }

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      if (error instanceof EmployeeNotFoundError) {
        return (
          <EmptyLayout>
            <EmployeeNotFoundErrorFallback error={error} />
          </EmptyLayout>
        );
      }

      throw error;
    }
    const { children } = this.props;
    return children;
  }
}

function EmployeeNotFoundErrorFallback({ error }: { error: EmployeeNotFoundError }) {
  const { t } = useTranslation('auth');

  return <ErrorFallback error={error} titleMessage={t('unauthorized')} subtitleMessage={t('contactYourAdmin')} />;
}
