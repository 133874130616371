/**
 * @generated SignedSource<<538ce816e1f4b01b60f00e22f8cca05c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NatureOfWorkSubCategoryEnum = "accessory" | "airports" | "antennas" | "aqueductsSewer" | "blade" | "bridges" | "cages" | "chambers" | "dams" | "demolition" | "distribution" | "docks" | "excavation" | "gearboxes" | "generator" | "gondola" | "hand" | "handling" | "landscaping" | "lifting" | "lines" | "modules" | "newConstruction" | "nonOperated" | "powerPlants" | "pruning" | "pylons" | "refurbishment" | "retentionBasins" | "roadsRails" | "rotor" | "shelters" | "substations" | "towers" | "transformers" | "transport" | "ventilationPlumbingElectrical" | "%future added value";
export type ServiceCallKind = "accessoriesRental" | "bare" | "bareWithOperators" | "laborRental" | "laborRentalOnBare" | "laborRentalOnOperated" | "liftingPlan" | "liftingTest" | "operatedHourly" | "operatedMonthly" | "rollingEquipment" | "storage" | "%future added value";
export type SuggestionPromptInput = {
  accessoryTypes: ReadonlyArray<string>;
  capacity?: number | null | undefined;
  clientId?: string | null | undefined;
  clientRequirements: ReadonlyArray<string>;
  dispatchBranchId?: string | null | undefined;
  equipmentKindCode?: string | null | undefined;
  hoursPerDay?: number | null | undefined;
  inTheCareOf?: string | null | undefined;
  isSecondServing?: boolean | null | undefined;
  lengthInDays?: number | null | undefined;
  natureOfWorkCode?: string | null | undefined;
  natureOfWorkSubCategory?: NatureOfWorkSubCategoryEnum | null | undefined;
  representativeId?: string | null | undefined;
  salesBranchId?: string | null | undefined;
  serviceCallKind?: ServiceCallKind | null | undefined;
  vehicleIds: ReadonlyArray<string>;
  workDescription?: string | null | undefined;
  worksiteId?: string | null | undefined;
  worksiteRequirements: ReadonlyArray<string>;
};
export type NatureOfWorkSubCategoryAutocompleteQuery$variables = {
  skipSuggestion: boolean;
  suggestionCount: number;
  suggestionPrompt: SuggestionPromptInput;
  suggestionScoreThreshold: number;
};
export type NatureOfWorkSubCategoryAutocompleteQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NatureOfWorkSubCategoryAutocompleteFragment">;
};
export type NatureOfWorkSubCategoryAutocompleteQuery = {
  response: NatureOfWorkSubCategoryAutocompleteQuery$data;
  variables: NatureOfWorkSubCategoryAutocompleteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipSuggestion"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suggestionCount"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suggestionPrompt"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suggestionScoreThreshold"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NatureOfWorkSubCategoryAutocompleteQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "skipSuggestion",
            "variableName": "skipSuggestion"
          },
          {
            "kind": "Variable",
            "name": "suggestionCount",
            "variableName": "suggestionCount"
          },
          {
            "kind": "Variable",
            "name": "suggestionPrompt",
            "variableName": "suggestionPrompt"
          },
          {
            "kind": "Variable",
            "name": "suggestionScoreThreshold",
            "variableName": "suggestionScoreThreshold"
          }
        ],
        "kind": "FragmentSpread",
        "name": "NatureOfWorkSubCategoryAutocompleteFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NatureOfWorkSubCategoryAutocompleteQuery",
    "selections": [
      {
        "condition": "skipSuggestion",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "suggestions",
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "suggestionCount"
              },
              {
                "kind": "Variable",
                "name": "prompt",
                "variableName": "suggestionPrompt"
              },
              {
                "kind": "Variable",
                "name": "scoreThreshold",
                "variableName": "suggestionScoreThreshold"
              }
            ],
            "concreteType": "SuggestionOfNatureOfWorkSubCategoryEnum",
            "kind": "LinkedField",
            "name": "suggestedNatureOfWorkSubCategories",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "score",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "c27c72b22185d1ca8192b5e9b234cc36",
    "id": null,
    "metadata": {},
    "name": "NatureOfWorkSubCategoryAutocompleteQuery",
    "operationKind": "query",
    "text": "query NatureOfWorkSubCategoryAutocompleteQuery(\n  $suggestionPrompt: SuggestionPromptInput!\n  $suggestionCount: Int!\n  $suggestionScoreThreshold: Float!\n  $skipSuggestion: Boolean!\n) {\n  ...NatureOfWorkSubCategoryAutocompleteFragment_1zYZ2K\n}\n\nfragment NatureOfWorkSubCategoryAutocompleteFragment_1zYZ2K on Query {\n  suggestions: suggestedNatureOfWorkSubCategories(prompt: $suggestionPrompt, count: $suggestionCount, scoreThreshold: $suggestionScoreThreshold) @skip(if: $skipSuggestion) {\n    score\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "8629885ab19b23889177e8dc70d5093c";

export default node;
