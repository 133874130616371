import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import i18next from 'i18next';
import { BoomConfigurationSnapshotLabelResolver$key } from './__generated__/BoomConfigurationSnapshotLabelResolver.graphql';

/**
 * @RelayResolver BoomConfigurationSnapshot.label: String
 * @rootFragment BoomConfigurationSnapshotLabelResolver
 *
 * A label for BoomConfigurations. Used dynamically by Autocomplete component to resolve the option label.
 */
export function label(boomConfiguration$key: BoomConfigurationSnapshotLabelResolver$key): string {
  const t = (key: string) => i18next.t(`boomConfiguration.${key}`, { ns: 'resolvers' });
  const boomConfiguration$data = readFragment(
    graphql`
      fragment BoomConfigurationSnapshotLabelResolver on BoomConfigurationSnapshot {
        boomLength
        jibLength
        counterweight
        sequence
      }
    `,
    boomConfiguration$key,
  );
  return `${t('boom')}: ${boomConfiguration$data.boomLength} ; ${t('jib')}: ${boomConfiguration$data.jibLength} ; ${t('counterweight')}: ${boomConfiguration$data.counterweight} ; ${t('sequence')}: ${boomConfiguration$data.sequence}`;
}
